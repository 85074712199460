import IconGeneric from "../Icon/IconGeneric";
import React, {useCallback, useMemo, useState} from "react";
import {DateRange, DateRangeOptions} from "../../../domain/DateRange";
import OptionList from "./OptionList";
import {useTranslation} from "react-i18next";
import {CustomDate} from "./CustomDate";
import useDates from "../../../hooks/use-dates";
import useFiltersContext from "../../../hooks/context/use-filters-context";
import useOutsideClick from "../../../hooks/ui/use-outside-click";

const DateSelector = () => {
    const {i18n, t} = useTranslation();
    const {dateRange, setDateRange} = useFiltersContext();
    const {getLastDayTime, getFirstDayTime, formatDate} = useDates()
    const [selectedOption, setSelectedOption] = useState<DateRangeOptions>(dateRange.label);
    const [startDate, setStartDate] = useState<Date|null>(dateRange.start);
    const [endDate, setEndDate] = useState<Date|null>(dateRange.end);
    const [dateDropdown, setDateDropdown] = useState<boolean>(false);
    const [datePresetDropdown, setDatePresetDropdown] = useState<boolean>(true);
    const [dateIntervalDropdown, setDateIntervalDropdown] = useState<boolean>(false);
    const ref = useOutsideClick(useCallback(() => {
        setDateDropdown(false);
    }, [setDateDropdown]))

    const submitData = (dateRange: DateRange) => {
        setDateDropdown(false);
        setDateRange(dateRange);
    }

    const label = useMemo(() =>{
        if('custom' === dateRange.label.toString()){
            return `${formatDate(dateRange.start, i18n.language)} - ${formatDate(dateRange.end, i18n.language)}`
        }

        return t(dateRange.label);
    }, [formatDate, dateRange, t, i18n.language])

    return (
        <div className='cc-filter__container' ref={ref}>
            <div className={`cc-filter__button cc-filter__button--date ${dateDropdown ? 'is-open' : ''}`}
                onClick={() => { setDateDropdown(!dateDropdown) }}
            >
                <IconGeneric iconName="date-ico" className="mc-icon__generic--size-16" />
                {label}
            </div>
            { dateDropdown &&
                    <div className="cc-filter__dropdown has-fade-in">
                        <div className="cc-filter__tabs">
                            <span className={`cc-filter__tabs-item ${datePresetDropdown ? 'is-open': ''}`}
                                onClick={() => {
                                    setDatePresetDropdown(true);
                                    setDateIntervalDropdown(false);
                                }}
                            >
                                {t('predefined')}
                            </span>
                            <span className={`cc-filter__tabs-item ${dateIntervalDropdown ? 'is-open': ''}`}
                                onClick={() => {
                                    setDatePresetDropdown(false);
                                    setDateIntervalDropdown(true);
                                }}
                            >
                                {t('custom')}
                            </span>
                        </div>

                        { datePresetDropdown && <OptionList
                            onSelectOption={(option) => {
                                setSelectedOption(option);
                                setStartDate(null);
                                setEndDate(null);
                                submitData({
                                    label: option,
                                    start: null,
                                    end: null,
                                });
                            }}
                            selectedOption={selectedOption}
                        />}
                        { dateIntervalDropdown && <CustomDate
                            setSelectedOption={setSelectedOption}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            allowFutureDates={false}
                        />}

                        { dateIntervalDropdown &&
                            <>
                                <div className='mc-divider'></div>
                                <div className='oc-group oc-group--center'>
                                    <button className='mc-button mc-button--secondary mc-button--small' onClick={() => { setDateDropdown(false) }}>
                                        {t('cancel')}
                                    </button>
                                    <button className='mc-button mc-button--primary mc-button--small' onClick={() => {
                                        const end = endDate ?? (startDate ? getLastDayTime(startDate) : null);
                                        const start = startDate ? getFirstDayTime(startDate) : null;

                                        submitData({
                                            label: selectedOption,
                                            start,
                                            end
                                        })

                                        setEndDate(end);
                                    }}>
                                        {t('apply')}
                                    </button>
                                </div>
                            </>
                        }
                    </div>
            }
        </div>
    )
}

export default DateSelector