interface Props {
    title: string;
    subtitle?: string;
    cta?: {
        title: string;
        href: string;
    }
}
export const ErrorComponent = ({title, subtitle, cta}: Props) => {
    return (
        <div className="cc-notice cc-notice--error">
            <div className="cc-notice__ico">(≥o≤)</div>
            <div className="cc-notice__message">
                <div className="cc-notice__message-text">
                    {title}
                </div>
                {subtitle &&
                    <div className="cc-notice__message-description">
                        {subtitle}
                    </div>
                }
            </div>
            {cta &&
                <a className="mc-button mc-button--primary mc-button--auto" href={cta.href}>
                    {cta.title}
                </a>
            }
        </div>
    )
}