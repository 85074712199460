import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import useNumbers from "../../../hooks/use-numbers";

interface Props {
    label: string
    value: string
    color: string
}

const GeneralChartTooltipItem = ({label, value, color}: Props) => {
    const {i18n, t} = useTranslation();
    const val = useMemo(() => parseFloat(value), [value])
    const {formatCompactNumber, formatCompactCurrency, formatCurrency, formatPercent} = useNumbers();

    let resolvedValue = 'N/A';
    if('impressions' === label){
        resolvedValue = formatCompactNumber(val, i18n.language, 2);
    }else if('income' === label){
        resolvedValue = formatCompactCurrency(val, i18n.language, 2);
    }else if('ecpm' === label){
        resolvedValue = formatCurrency(val, i18n.language, 2);
    }else{
        resolvedValue = formatPercent(val, i18n.language,2 );
    }

    return (
        <div className="cc-chart__tooltip-wrapper">
            <div className="cc-chart__tooltip-item" style={{color: color}}>
                <span className="cc-chart__tooltip-item-name">{t(label)}</span>
                <div className="cc-chart__tooltip-item-bar"></div>

                <div className="cc-chart__tooltip-item-value-wrapper">
                    <div className="cc-chart__tooltip-item-value">
                        <div className="cc-chart__tooltip-item-value--new">{resolvedValue}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralChartTooltipItem