import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {useTranslation} from "react-i18next";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import "dayjs/locale/ca";
import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/eu";
import { ReactComponent as CustomCalendarIcon } from '../../../assets/calendar-ico.svg';

interface Props{
    label: string;
    defaultValue: null|Date;
    minDate?: null|Date;
    maxDate?: null|Date;
    onChange: (date: Date | null) => void;
}

export default function DatePickerYearMonth({label, defaultValue, minDate, maxDate, onChange}: Props) {
    const {i18n} = useTranslation()
    
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n?.language}>
            <DatePicker 
                className='cc-form__mui cc-form__mui--date-picker'
                label={label}
                views={['month', 'year']}
                defaultValue={defaultValue ? dayjs(defaultValue) : dayjs(new Date())}
                onChange={(value) => onChange(value ? value.toDate() : null)}
                minDate={minDate ? dayjs(minDate) : null}
                maxDate={maxDate ? dayjs(maxDate) : null}
                components={{
                    OpenPickerIcon: CustomCalendarIcon,
                }}
            />
        </LocalizationProvider>
    );
}