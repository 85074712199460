import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean
    title: string
    message: string
    onConfirm: (confirm: boolean) => void
}

const ConfirmDialog = ({open, title, message, onConfirm}: Props) => {
    const {t} = useTranslation();

    return <Dialog
        open={open}
        onClose={() => onConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className='mc-button mc-button--secondary' onClick={() => onConfirm(false)}>
                {t('cancel')}
            </Button>
            <Button className='mc-button mc-button--primary' onClick={() => onConfirm(true)} autoFocus>
                {t('confirm')}
            </Button>
        </DialogActions>
    </Dialog>
}

export default ConfirmDialog;