export const utFormatNumber = (number: number|undefined, locale = 'es-ES', numberDecimals: number): string => {
    if (number === 0) {
        return '0';
    } else if (!number) {
        return '-';
    }

    return number.toLocaleString(locale, { style: "decimal", maximumFractionDigits : numberDecimals, useGrouping: true});
}

export const utFormatCompactNumber = (number: number, locale = 'es-ES', numberDecimals: number): string => {
    const formatter = Intl.NumberFormat(locale, { style: "decimal", maximumFractionDigits : numberDecimals, notation: "compact" });
    return formatter.format(number);
}

export const utFormatCompactCurrency = (number: number, locale = 'es-ES', numberDecimals: number): string => {
    const formatter = Intl.NumberFormat(locale, { style: "currency",  currency: "EUR", maximumFractionDigits : numberDecimals, notation: "compact" });
    return formatter.format(number);
}

export const utFormatCurrency = (number: number|undefined, locale = 'es-ES', numberDecimals: number): string => {
    if (undefined === number || null === number) {
        return '-';
    }

    const localeMap: { [key: string]: string } = {
        ca: 'ca-ES',
        es: 'ca-ES', // Fixed on purpose in order to display thousands separator with a dot
        eu: 'eu-ES',
        en: 'en-EN',
    };

    const loc = localeMap[locale] || locale;

    const formatter = new Intl.NumberFormat(loc, {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: numberDecimals,
        minimumFractionDigits: numberDecimals,
    });

    return formatter.format(number);
}
export const utFormatPercent = (number: number|undefined, locale = 'es-ES', numberDecimals: number): string => {
    if (undefined === number || null === number) {
        return '-';
    }

    if (Math.abs(number) > 1) {
        number = number / 100;
    }

    // Percentage is too big to be displayed: >= '500%'
    if (Math.abs(number) >= 5) {
        return '∞';
    }

    return number.toLocaleString(locale, { style: "percent", maximumFractionDigits : numberDecimals});
}

export const utRoundNumber = (number: number, numberDecimals: number): number => {
    const factor = Math.pow(10, numberDecimals);
    return Math.round(number * factor) / factor;
}
