import { useTranslation } from "react-i18next";
import FiltersInvoices from "../../../components/BackOffice/Filters/FiltersInvoices";
import InvoicesTableDataBackOffice from "../../../components/BackOffice/Invoices/InvoicesTableDataBackOffice";
import Heading from "../../../components/Common/Heading";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import useBackOfficeInvoicesData from "../../../hooks/use-backoffice-invoices-data";
import useFiltersContext from "../../../hooks/context/use-filters-context";

const ListInvoicesBackOffice = () => {
    const {t} = useTranslation()
    const {
        dateRangeInvoices,
        filteredInvoiceGroups,
    } =  useFiltersContext();
    const {invoices, loading} = useBackOfficeInvoicesData(dateRangeInvoices, filteredInvoiceGroups);

    if (loading) {
        return <LoadingFull/>
    }

    return (
        <>
            <Heading title={t('invoices')} />
            <FiltersInvoices/>
            <InvoicesTableDataBackOffice data={invoices} />
        </>
    )
}
export default ListInvoicesBackOffice