import React, {useCallback, useMemo, useState} from "react";
import {DateRange, DateRangeOptions} from "../../../domain/DateRange";
import {useTranslation} from "react-i18next";
import useDates from "../../../hooks/use-dates";
import useOutsideClick from "../../../hooks/ui/use-outside-click";
import {CustomDate} from "./CustomDate";
import IconGeneric from "../Icon/IconGeneric";

interface Props {
    setDateRange: (dateRange: DateRange) => void;
    defaultStartDate?: Date;
    defaultEndDate?: Date;
}

export const CalendarIntervalSelector = ({setDateRange, defaultStartDate, defaultEndDate}: Props) => {
    const {i18n, t} = useTranslation();
    const {getLastDayTime, getFirstDayTime, formatDate} = useDates()
    const [selectedOption, setSelectedOption] = useState<DateRangeOptions>('custom');
    const [startDate, setStartDate] = useState<Date|null>(defaultStartDate ?? null);
    const [endDate, setEndDate] = useState<Date|null>(defaultEndDate ?? null);
    const [dateDropdown, setDateDropdown] = useState<boolean>(false);
    const ref = useOutsideClick(useCallback(() => {
        setDateDropdown(false);
    }, [setDateDropdown]))

    const submitData = (dateRange: DateRange) => {
        setDateDropdown(false);
        setDateRange(dateRange)
    }

    const label = useMemo(() =>{
        if(startDate && endDate){
            return `${formatDate(startDate, i18n.language)} - ${formatDate(endDate, i18n.language)}`
        }

        return t('period');
    }, [formatDate, startDate, endDate, t, i18n.language])

    return (
        <div className='cc-filter__container' ref={ref}>
            <div className={`cc-filter__button cc-filter__button--date ${dateDropdown ? 'is-open' : ''}`}
                onClick={() => { setDateDropdown(!dateDropdown) }}
            >
                <IconGeneric iconName="date-ico" className="mc-icon__generic--size-16" />
                {label}
            </div>
            { dateDropdown &&
                <div className="cc-filter__dropdown has-fade-in">
                    <div className="cc-filter__tabs">
                        <span className={'cc-filter__tabs-item is-open'}>
                            {t('custom')}
                        </span>
                    </div>

                    <CustomDate
                        setSelectedOption={setSelectedOption}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        allowFutureDates={true}
                    />


                    <>
                        <div className='mc-divider'></div>
                        <div className='oc-group oc-group--center'>
                            <button className='mc-button mc-button--secondary mc-button--small' onClick={() => { setDateDropdown(false) }}>
                                {t('cancel')}
                            </button>
                            <button className='mc-button mc-button--primary mc-button--small' onClick={() => {
                                const end = endDate ?? (startDate ? getLastDayTime(startDate) : null);
                                const start = startDate ? getFirstDayTime(startDate) : null;

                                submitData({
                                    label: selectedOption,
                                    start,
                                    end
                                })

                                setEndDate(end);
                            }}>
                                {t('apply')}
                            </button>
                        </div>
                    </>

                </div>
            }
        </div>
    )
}