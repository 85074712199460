import React, {useCallback, useMemo} from 'react';
import AddInvoiceGroupForm from "../../../components/BackOffice/InvoiceGroup/AddInvoiceGroupForm";
import ApiClient from "../../../client/ApiClient";
import {useSnackbar} from "../../../hooks/ui/use-snackbar";
import {useTranslation} from "react-i18next";
import {Alert, Snackbar} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {InvoiceGroupFormData} from "../../../components/BackOffice/InvoiceGroup/InvoiceGroupForm";

const AddInvoiceGroupBackOffice = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const client = useMemo(() => new ApiClient(), []);
    const {snackbar, showSnackbar, closeSnackbar} = useSnackbar();


    const handleCreate = useCallback(async (formData: InvoiceGroupFormData) => {
        try {
            const response = await client.createInvoiceGroup(formData);
            navigate('/backoffice/invoice-groups')

            return response.data;
        } catch (error) {
            showSnackbar(t('save invoice-group error'), 'error');
        }
    },[client, navigate,showSnackbar, t]);

    const handleCancel = () => {
        navigate('/backoffice/invoice-groups')
    }

    return (
        <>
            <AddInvoiceGroupForm
                onSave={handleCreate}
                onCancel={handleCancel}
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={closeSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={closeSnackbar} />
                </Snackbar>
            )}
        </>
    );
};

export default AddInvoiceGroupBackOffice;
