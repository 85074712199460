import React, {useCallback, useEffect, useState} from "react";
import useInvoiceStates from "../../../hooks/use-invoice-states";
import {InvoiceState} from "../../../domain/State";
import {useTranslation} from "react-i18next";
import IconGeneric from "../Icon/IconGeneric";
import useOutsideClick from "../../../hooks/ui/use-outside-click";
import ConfirmDialog from "../Modal/ConfirmDialog";
import useInvoiceContext from "../../../hooks/context/use-invoice-context";

const INVOICE_STATE_ID_PENDING_RECEPTION = 3;

const StateSelector = ({invoice}: any) => {
    const {t} = useTranslation();
    const {reachableInvoiceStates, getReachableInvoiceState, changeInvoiceState, loading} = useInvoiceStates();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedState, setSelectedState] = useState<InvoiceState>(invoice.state);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [transitionState, setTransitionState] = useState<InvoiceState | null>(null);

    const {setInvoice} = useInvoiceContext()

    useEffect(() => {
        getReachableInvoiceState(selectedState.id).then();
    }, [getReachableInvoiceState, selectedState])

    const handleSelect = (state: InvoiceState) => {
        if (INVOICE_STATE_ID_PENDING_RECEPTION === state.id) {
            setTransitionState(state);
            setIsDialogOpen(true);
        } else {
            const sendEmail = false
            changeInvoiceState(invoice.id, state.id, sendEmail).then(() => {
                setSelectedState(state);
                setIsOpen(false);
            });
        }
        setInvoice(invoice);
    };

    const handleConfirm = async (confirm: boolean) => {
        setIsDialogOpen(false);
        if (confirm && transitionState) {
            const sendEmail = true
            await changeInvoiceState(invoice.id, transitionState.id, sendEmail);
            setSelectedState(transitionState);
            setIsOpen(false);
        }else{
            setTransitionState(null);
        }
    };


    const ref = useOutsideClick(useCallback(() => {
        setIsOpen(false);
    }, []));

    return (
        <>
            <div ref={ref} className="cc-filter__container cc-fra-heading__states">
                <div  className={`cc-filter__button cc-filter__button--state ${isOpen ? 'is-open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                    <IconGeneric iconName="flag-ico" className="mc-icon__generic--size-16" />
                    {t(selectedState.label)}
                </div>

                {isOpen && (
                    <div className="cc-filter__dropdown cc-filter__dropdown--fra has-fade-in">
                        <ul className="cc-filter__group cc-filter__group--preset">
                            {reachableInvoiceStates.map((reachableState: InvoiceState) => (
                                <li
                                    key={reachableState.id}
                                    className={`cc-filter__group-item cc-filter__group-item--preset ${loading ? 'is-disabled' : ''}`}
                                    onClick={loading ? undefined : () => handleSelect(reachableState)}
                                >
                                    {t(reachableState.label)}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            <ConfirmDialog
                open={isDialogOpen}
                title={t('status change confirm')}
                message={t('status change confirm message')}
                onConfirm={handleConfirm}
            />
        </>
    );
}

export default StateSelector;