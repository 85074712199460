import {
    DataGrid
} from '@mui/x-data-grid';
import {useTranslation} from "react-i18next";
import {OrphanMetricData} from "../../../domain/OrphanMetricData";
import {useOrphanMetricsGrid} from "../../../hooks/ui/use-orphan-metrics-grid";
import {Partner} from "../../../domain/Partner";
import useFiltersOrphanMetricsContext from '../../../hooks/context/use-filters-orphan-metrics-context';
import IconGeneric from '../../Common/Icon/IconGeneric';

interface Props {
    partners: Partner[],
    metrics: OrphanMetricData[];
}

const rowsPerPage = 10;

export default function OrphanMetricsGrid({partners, metrics}: Props) {
    const {t} = useTranslation();
    const {
        rows,
        columns,
        rowModesModel,
    } = useOrphanMetricsGrid(partners, metrics);

    const {page, setPage} = useFiltersOrphanMetricsContext();

    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage(prevPage => Math.max(prevPage - 1, 0));
    };

    return (<>
        <div className="cc-fra__table cc-card">
            <DataGrid
                sx={{ border: 0 }}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                localeText={{
                    noRowsLabel: t('no-results'),
                    MuiTablePagination: {
                        labelRowsPerPage: t('rows-per-page'),
                    },
                }}
                initialState={{
                    pagination: { paginationModel: { pageSize: rowsPerPage ?? 10 } },
                }}
                sortingOrder={['desc', 'asc']}
                hideFooterPagination={true}
            />
            <div className='cc-pagination'>
                <span>{t('page')}: {page}</span>
                <div className='cc-pagination__actions'>
                    <button className={`cc-pagination__button ${page <= 1 ? 'is-disabled' : '' }`} onClick={handlePrevPage}>
                        <IconGeneric iconName="pagination-left-ico" className="mc-icon__generic--size-20" />
                    </button>
                    <button className={`cc-pagination__button ${metrics.length < (rowsPerPage ?? 10) ? 'is-disabled' : '' }`} onClick={handleNextPage}>
                        <IconGeneric iconName="pagination-right-ico" className="mc-icon__generic--size-20" />
                    </button>
                </div>
            </div>
        </div>
    </>);
}