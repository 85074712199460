import InvoicesTableData from "../../../components/App/Invoices/InvoicesTableData";
import useAppInvoicesData from "../../../hooks/use-app-invoices-data";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";

const ListInvoices = () => {
    const {invoices, loading} = useAppInvoicesData();

    if (loading) {
        return <LoadingFull/>
    }

    return <InvoicesTableData data={invoices} />;
}
export default ListInvoices