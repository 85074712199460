import {useTranslation} from "react-i18next";
import * as React from "react";

interface LegendPayload {
    value: string;
    type: string;
    color: string;
    inactive: boolean;
    payload: undefined;
}

interface Props {
    payload?: LegendPayload[];
}

const CustomizedLegend = ({payload}: Props) => {
    const {t} = useTranslation();

    return (
        <ul className="cc-chart__legend">
            {
                payload && payload.map((entry, index) => (
                    <li className="cc-chart__legend-item" key={`item-${index}`} style={{color: entry.color}}>
                        <span className="cc-chart__legend-bullet" style={{background: entry.color}}></span>{t(entry.value)}
                    </li>
                ))
            }
        </ul>
    );
}

export default CustomizedLegend;