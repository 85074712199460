import Header from "../../components/Common/Header/Header";
import {useRouteError} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ErrorComponent} from "../../components/Common/ErrorComponent";

const Error = () => {
    const error = useRouteError() as any;
    const {t } = useTranslation();

    return (
        <div className="comitium-analytics">
            <Header />
            <div className="oc-flex oc-flex--no-wrap cc-main__wrapper">
                <div className="cc-main cc-main--error">
                    <div className="cc-notice__message">
                        {error && 404 === error.status &&
                            <ErrorComponent
                                title={t('this page does not exist')}
                                subtitle={t('please check the url or return to the homepage')}
                                cta={{
                                    title: t('go to homepage'),  // Corrected syntax
                                    href: "/"
                                }}
                            />
                        }
                        {error && 404 !== error.status &&
                            <ErrorComponent title={`${error.status}: ${error.statusText}`} subtitle={error.error.message}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Error;