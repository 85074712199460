import {TextField} from "@mui/material";
import useFiltersContext from "../../../hooks/context/use-filters-context";
import {useTranslation} from "react-i18next";

const ReferenceSearchFilter = () => {
    const {t} = useTranslation();
    const {invoiceReference, setInvoiceReference} = useFiltersContext();

    return <TextField
        className="cc-form__mui cc-form__mui--text-field"
        size="small"
        label={t('reference')}
        value={invoiceReference}
        onChange={(event) => setInvoiceReference(event.target.value)}
    />
}

export default ReferenceSearchFilter;