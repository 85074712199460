import React from 'react';
import { Grid, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProjectForm, {ProjectFormData} from './ProjectForm';
import BackButton from "../../Common/BackButton";

interface Props{
    onSave: (formData: ProjectFormData) => void;
    onCancel: () => void;
}

const AddProjectForm = ({ onSave, onCancel }: Props) => {
    const { t } = useTranslation();
    const projectFormData: ProjectFormData = {
        public_key: '',
        domain: '',
        ad_unit: '',
        revenue_share: 0,
        adunit_names: '',
        invoice_group_id: null,
        date_registration: null,
        date_unregistration: null
    };

    const handleSave = (formData: ProjectFormData) => {
        onSave(formData);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <BackButton to=".." />
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <ProjectForm
                        initialValues={projectFormData}
                        onSave={handleSave}
                        onCancel={onCancel}
                        btnSaveText={t('add')}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
};

export default AddProjectForm;