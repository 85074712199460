import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface Props{
    partnerNames: string[];
}

function PerformanceEnhancedTableHeadBackOffice({partnerNames}: Props) {

    const getRandomKey = () => {
        return Math.random().toString(36).slice(2, 9);
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell key={getRandomKey() as string}></TableCell>
                {partnerNames.map((partnerName) => (
                    <TableCell key={getRandomKey() as string}>
                        {partnerName}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default PerformanceEnhancedTableHeadBackOffice;
