import {useEffect, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import {Project} from "../domain/Subsite";

interface Response {
    project: Project | null
}

const useProjectData = (
    projectId: string | undefined,
): Response => {
    const client = useMemo(() => new ApiClient(), [])
    const [projectData, setProjectData] = useState<Project | null>(null)

    useEffect(() => {
        client.abort()
        const pid = projectId ?? ''
        client.getProject(pid).then((response) => {
            const data: Project = response.data;

            setProjectData(data)
        }).catch((error) => {console.error(error)})
    }, [client, projectId])

    return {
        project: projectData
    };
}

export default useProjectData