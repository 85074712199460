import {useEffect, useRef} from "react";

const useOutsideClick = (action: () => void) => {
    const ref = useRef<any>()

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target)) {
                action()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, action])

    return ref;
}

export default useOutsideClick