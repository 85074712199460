import {createContext, Dispatch, SetStateAction,  useContext, useState} from "react";
import {InvoiceData} from "../../domain/InvoiceData";

export interface InvoiceContextInterface {
    invoice: InvoiceData
    setInvoice: Dispatch<SetStateAction<InvoiceData>>

}

export const InvoiceContext = createContext<InvoiceContextInterface>({} as InvoiceContextInterface)

const useInvoiceContext = () => useContext(InvoiceContext)

interface Props{
    invoiceData: InvoiceData
}
export const useInvoiceContextData = ({invoiceData}: Props): InvoiceContextInterface => {
    const [invoice, setInvoice] = useState<InvoiceData>(invoiceData);

    return {
        invoice,
        setInvoice
    }
}

export default useInvoiceContext