import {
    DataGrid,
} from '@mui/x-data-grid';
import {useTranslation} from "react-i18next";
import {InvoiceDetailsData} from '../../../domain/InvoiceData';
import AddItemToolbar from '../Grid/Toolbar/AddItemToolbar';
import { Alert, Snackbar } from '@mui/material';
import { useInvoiceDetailsGrid } from '../../../hooks/ui/use-invoice-details-grid';
import ConfirmDialog from "../../Common/Modal/ConfirmDialog";
import {usePaginationGrid} from "../../../hooks/ui/use-pagination-grid";
import useInvoiceContext from "../../../hooks/context/use-invoice-context";

interface Props {
    invoiceDetails: InvoiceDetailsData[];
    customTypes?: {id: number, label: string }[]
    allowEditingActions?: boolean
    paginationSettings?: { key: string, rowsPerPage: number, rowsPerPageOptions: any }
}

export default function InvoiceDetailsGrid({ invoiceDetails, customTypes, allowEditingActions, paginationSettings}: Props) {
    const {invoice} = useInvoiceContext()
    const {t} = useTranslation();
    const {
        getTypeRowClassName,
        handleRowEditStop,
        handleRowModesModelChange,
        handleProcessRowUpdateError,
        processRowUpdate,
        onConfirmDelete,
        rows,
        columns,
        setRows,
        rowModesModel,
        setRowModesModel,
        deleteDialog,
        snackbar,
        closeSnackbar
    } = useInvoiceDetailsGrid(invoice, invoiceDetails, allowEditingActions, customTypes);

    const {handlePageSizeChange, rowsPerPage} = usePaginationGrid(paginationSettings)

    return (<>

        <div className="cc-fra__table">
        
            <DataGrid
                sx={{ border: 0 }}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                isCellEditable={() => allowEditingActions ?? false}
                localeText={{
                    noRowsLabel: t('no-results'),
                    MuiTablePagination: {
                        labelRowsPerPage: t('rows-per-page'),
                    },
                }}
                slots={{
                    toolbar: AddItemToolbar,
                }}
                slotProps={{
                    toolbar: {rows, setRows, setRowModesModel, allowEditingActionsToolbar: allowEditingActions},
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'concept', sort: 'asc' }],
                    },
                    pagination: { paginationModel: { pageSize: rowsPerPage ?? 10 } },
                }}
                pageSizeOptions={paginationSettings?.rowsPerPageOptions ?? [10, 20, 30, 40, 50]}
                onPaginationModelChange={(params) => {
                    handlePageSizeChange(params);
                }}
                sortingOrder={['desc', 'asc']}
                getRowClassName={getTypeRowClassName}
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={closeSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={closeSnackbar} />
                </Snackbar>
            )}

            <ConfirmDialog
                open={deleteDialog}
                title={t('delete invoice detail')}
                message={t('delete invoice detail message')}
                onConfirm={onConfirmDelete}
            />
        </div>
    </>);
}