import {createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {DateRange} from "../../domain/DateRange";
import {FilterOption} from "../../domain/FilterOption";
import useDates from "../use-dates";

export interface FiltersOrphanMetricsContextInterface {
    dateRange: DateRange
    setDateRange: Dispatch<SetStateAction<DateRange>>,
    filteredPartners: FilterOption[]
    setFilteredPartners: Dispatch<SetStateAction<FilterOption[]>>
    projectMatch: string,
    setProjectMatch: Dispatch<SetStateAction<string>>
    page: number,
    setPage: Dispatch<SetStateAction<number>>
}

export const FiltersOrphanMetricsContext = createContext<FiltersOrphanMetricsContextInterface>({} as FiltersOrphanMetricsContextInterface)

const useFiltersOrphanMetricsContext = () => useContext(FiltersOrphanMetricsContext)

export const useFiltersOrphanMetricsContextData = (): FiltersOrphanMetricsContextInterface => {
    const {getFirstDayOfMonth, getLastDayOfMonth} = useDates()
    const [dateRange, setDateRange] = useState<DateRange>({
        label: 'custom',
        start: getFirstDayOfMonth(new Date()),
        end: getLastDayOfMonth((new Date()))
    })

    const [filteredPartners, setFilteredPartners] = useState<FilterOption[]>([]);
    const [projectMatch, setProjectMatch] = useState<string>('');
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        setPage(1)
    }, [filteredPartners, projectMatch])

    return {
        dateRange,
        setDateRange,
        filteredPartners,
        setFilteredPartners,
        projectMatch,
        setProjectMatch,
        page,
        setPage
    }
}

export default useFiltersOrphanMetricsContext