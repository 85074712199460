import {CalendarIntervalSelector} from "../../../Common/DateFilter/CalendarIntervalSelector";
import MultiSelectFilter from "../../../Common/Filters/MultiSelectFilter/MultiSelectFilter";
import React, {useEffect, useState} from "react";
import useDates from "../../../../hooks/use-dates";
import {DateRange} from "../../../../domain/DateRange";
import {Partner} from "../../../../domain/Partner";
import useFiltersOrphanMetricsContext from "../../../../hooks/context/use-filters-orphan-metrics-context";
import {TextField} from "@mui/material";

interface Props{
    dateRange: DateRange,
    partners: Partner[]
    projectMatch: string
}
const FiltersOrphanMetrics = ({dateRange, partners}: Props) => {
    const { getFirstDayOfMonth, getLastDayOfMonth } = useDates();
    const {
        setDateRange,
        filteredPartners,
        setFilteredPartners,
        projectMatch,
        setProjectMatch
    } = useFiltersOrphanMetricsContext();

    const [searchTextProjectMatch, setSearchTextProjectMatch] = useState(projectMatch);

    useEffect(() => {
        const handler = setTimeout(() => {
            setProjectMatch(searchTextProjectMatch);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [setProjectMatch, searchTextProjectMatch]);

    return (
        <div className="cc-filter">
            <div className="cc-filter__wrapper">
                <CalendarIntervalSelector
                    defaultStartDate={dateRange.start ?? getFirstDayOfMonth(new Date())}
                    defaultEndDate={dateRange.end ?? getLastDayOfMonth(new Date())}
                    setDateRange={setDateRange}
                />
                <MultiSelectFilter
                    name={'partners'}
                    values={partners.map((p: Partner) => {
                        return {
                            id: p.id,
                            name: p.name
                        }
                    })}
                    filteredValues={filteredPartners}
                    setFilteredValues={setFilteredPartners}
                />
                <TextField
                    className="cc-form__input--search cc-form__mui--search"
                    label={'Project match'}
                    value={searchTextProjectMatch}
                    size="small"
                    onChange={(e) => setSearchTextProjectMatch(e.target.value)}
                />
            </div>
        </div>
    )
}

export default FiltersOrphanMetrics