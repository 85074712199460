import {ChartColors} from "../../../domain/ChartColors";
import css from '../../../sass/01.settings/_settings.module.scss';

export const chartColors: ChartColors = {
    devices : {
        desktop:    css.coStats1,
        mobile:     css.coStats2,
        tablet:     css.coStats3,
        other:      css.coStats4,
        na:         css.coStats5,
    },
    formats : {
        native:     css.coStats1,
        display:    css.coStats2,
        video:      css.coStats3,
        inimage:    css.coStats4,
        skin:       css.coStats5,
        other:      css.coStats6,
        na:         css.coStats7,
    },
    environments : {
        web:    css.coStats1,
        amp:    css.coStats2,
        other:  css.coStats3,
        na:     css.coStats4,
    },
    general : {
        impressions:    css.coStats1,
        income:         css.coStats2,
        ecpm:           css.coStats3,
        viewability:    css.coStats4,
    },
}