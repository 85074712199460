import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {TableDataProjects} from "../../../domain/TableData";
import IconGeneric from "../../Common/Icon/IconGeneric";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const ProjectsEnhancedTableRowBackOffice = (props: { id: string, row: TableDataProjects }) => {
    const {t} = useTranslation();
    const { row } = props;

    return (
        <>
            <TableRow
                id={row.id.toString()}
                hover
                tabIndex={-1}
                key={row.id}
            >
                <TableCell scope="row">{row.id}</TableCell>
                <TableCell><Link to={{pathname: `/backoffice/projects/${row.id}/edit`,}}>{row.domain}</Link></TableCell>
                <TableCell>{row.revenue_share}</TableCell>
                <TableCell>{row.invoice_group}</TableCell>
                <TableCell>{row.date_registration}</TableCell>
                <TableCell>{row.date_unregistration}</TableCell>
                <TableCell>
                    <div className="oc-group oc-group--half-gap" style={{minWidth: '80px'}}>
                        <div className='mc-button mc-button--secondary is-small'>
                            <IconGeneric iconName="edit-ico" className="mc-icon__generic mc-icon__generic--size-12" />
                            <Link to={{
                                pathname: `/backoffice/projects/${row.id}/edit`,
                            }}>{t('edit')}</Link>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
        </>
    );
}

export default ProjectsEnhancedTableRowBackOffice;