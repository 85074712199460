import {useTranslation} from "react-i18next";
import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {HistoricalDataLine} from "../../../domain/TableData";
import useNumbers from "../../../hooks/use-numbers";
import useDates from "../../../hooks/use-dates";

const HistoricalEnhancedTableRowBackOffice = (props: { id: string, row: HistoricalDataLine }) => {
    const { i18n} = useTranslation();
    const {formatCurrency, formatPercent } = useNumbers();
    const {formatDatePeriod} = useDates();
    const { row } = props;

    return (
        <>
            <TableRow
                id={row.period.toString()}
                hover
                tabIndex={-1}
                key={row.period.toString()}
            >
                <TableCell scope="row">{formatDatePeriod(new Date(row.period), i18n.language)}</TableCell>
                <TableCell>{row.domain}</TableCell>
                <TableCell>{formatCurrency(row.income, i18n.language)}</TableCell>
                <TableCell>{formatCurrency(row.income_dashboard, i18n.language)}</TableCell>
                <TableCell>{formatCurrency(row.differential,  i18n.language)}</TableCell>
                <TableCell>{formatPercent(row.differential_percentage,  i18n.language, 2)}</TableCell>
            </TableRow>
        </>
    );
}

export default HistoricalEnhancedTableRowBackOffice;