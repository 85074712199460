import {useTranslation} from "react-i18next";
import useInvoiceDetailsPendingPayment from "../../../hooks/use-invoice-details-pending-payment";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import Heading from "../../../components/Common/Heading";
import usePaginationContext from "../../../hooks/context/use-pagination-context";
import * as React from "react";
import InvoiceDetailsPendingPaymentGrid
    from "../../../components/BackOffice/PendingInvoices/InvoiceDetailsPendingPaymentGrid";

const ListPendingInvoicesBackOffice = () => {
    const {t} = useTranslation();
    const paginationContextData = usePaginationContext();
    const {invoicesDetails, loading} = useInvoiceDetailsPendingPayment();

    if (loading) {
        return <LoadingFull/>
    }

    return <>
        <Heading title={t('pending invoices')} />
        <div className="cc-card cc-card--invoice">
            <InvoiceDetailsPendingPaymentGrid
                invoiceDetails={invoicesDetails}
                allowEditingActions={false}
                paginationSettings={
                    {
                        key: 'invoice-details-pending-payment',
                        rowsPerPage: paginationContextData.getRowsPerPage('invoice-details-pending-payment') ?? 10,
                        rowsPerPageOptions: [10, 20, 30, 40, 50]
                    }
                }
            />
        </div>
    </>
}

export default ListPendingInvoicesBackOffice