export const utGetFirstDayOfMonth = (date: Date|null) => {
    if(!date){
        return new Date();
    }
    return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const utGetLastDayOfMonth = (date: Date|null) => {
    if(!date){
        return new Date();
    }
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};


export const utGetDayName = (date: Date|null, locale = 'es-ES'): string => {
    if (!date){
        return ''
    }

    const dayName = date.toLocaleDateString(locale, { weekday: 'long' })
    return dayName.charAt(0).toUpperCase() + dayName.slice(1);
}

export const utGetMonthName = (date: Date|null, locale = 'es-ES'): string => {
    if (!date){
        return ''
    }

    const monthName = date.toLocaleDateString(locale, { month: 'long' })
    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
}

export const utGetYear = (date: Date|null, locale = 'es-ES'): string => {
    if (!date){
        return ''
    }

    return date.toLocaleDateString(locale, { year: 'numeric' })
}

export const utIsValidDate = (text: string) : boolean => {
    const regex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

    if (!regex.test(text)) {
        return false;
    }

    const [day, month, year] = text.split('/');
    const date = new Date(Number(year), Number(month) - 1, Number(day));

    return (
        date.getDate() === Number(day) &&
        date.getMonth() === Number(month) - 1 &&
        date.getFullYear() === Number(year)
    );
}

export const utFormatDate = (date: Date|null,  locale = 'es-ES', options?: Intl.DateTimeFormatOptions | undefined): string => {
    if (!date || isNaN(date.getTime())) {
        return '--/--/--'
    }

    return date.toLocaleString(locale, options ?? {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
    });
}

export const utFormatDatePeriod = (date: Date|null,  locale = 'es-ES', options?: Intl.DateTimeFormatOptions | undefined): string => {
    if (!date || isNaN(date.getTime())) {
        return '--/--/--'
    }

    return date.toLocaleString(locale, options ?? {
        month: 'long',
        year: 'numeric'
    });
}

export const utFormatDateCalendarPicker = (date: Date|null): string => {
    if (!date || isNaN(date.getTime())) {
        return '-'
    }

    return date.getFullYear() + '-'  + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +  date.getDate().toString().padStart(2, '0');
}

export const isValidDate = (text: string) => {
    const regex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    if (!regex.test(text)) {
        return false;
    }

    const [day, month, year] = text.split('/');
    const date = new Date(Number(year), Number(month) - 1, Number(day));

    return (
        date.getDate() === Number(day) &&
        date.getMonth() === Number(month) - 1 &&
        date.getFullYear() === Number(year)
    );
}

export const convertStringToDate = (text: string) => {
    if (isValidDate(text)) {
        const [day, month, year] = text.split('/');

        return new Date(Number(year), Number(month) - 1, Number(day));
    }

    return null
}