import { useTranslation } from "react-i18next";
import useDates from "../../../hooks/use-dates";
import useNumbers from "../../../hooks/use-numbers";
import StateSelector from "../../Common/Invoices/StateSelector";
import useInvoiceContext from "../../../hooks/context/use-invoice-context";
import IconGeneric from "../../Common/Icon/IconGeneric";
import useInvoiceDownloadPDF from "../../../hooks/use-invoice-download-pdf";
import * as React from "react";
import {useMemo} from "react";

const HeaderInvoice = () => {
    const {invoice} = useInvoiceContext()
    const {t,i18n} = useTranslation();
    const {formatDate} = useDates();
    const {formatCurrency} = useNumbers();
    const {download: downloadPDF} = useInvoiceDownloadPDF(invoice.id);
    const invoiceDetailsCustomerSum = useMemo(() => {
        return invoice.invoice_details.reduce((accumulator, current) => {
            return accumulator + current.revenue;
        }, 0);
    }, [invoice.invoice_details]);

    return (
        <div className="cc-fra-heading">
            <div className="cc-fra-heading__top">
                <div className="cc-fra-heading__label">{t('invoice')}: <span className="cc-fra-heading__reference">{invoice.reference}</span></div>
                <a className='mc-button mc-button--secondary is-small'>
                    <IconGeneric iconName="download-ico" className="mc-icon__generic mc-icon__generic--size-12"/>
                    <a onClick={downloadPDF}><span className="mc-button--label">{t('download')}</span></a>
                </a>
            </div>
            <div className="cc-fra-heading__group cc-fra-heading__group--column">

                <div className="cc-fra-heading__subgroup">
                    <div className="cc-fra-heading__customer">{invoice.company_name}</div>
                    <div className="cc-fra-heading__data cc-fra-heading__data--small">{invoice.tax_identifier}</div>
                </div>

                <div className="cc-fra-heading__group">
                    <div className="cc-fra-heading__subgroup">
                        <div className="cc-fra-heading__label">{t('creation_date')}</div>
                        <div className="cc-fra-heading__data">{formatDate(new Date(invoice.date), i18n.language)}</div>
                    </div>
                    <div className="cc-fra-heading__subgroup">
                        <div className="cc-fra-heading__label">{t('period')}</div>
                        <div className="cc-fra-heading__data">{formatDate(new Date(invoice.start_period), i18n.language)} - {formatDate(new Date(invoice.end_period), i18n.language)}</div>
                    </div>
                </div>
            </div>

            <div className="cc-fra-heading__subgroup cc-fra-heading__subgroup--actions">
                <div className="cc-fra-heading__subgroup">
                    <div className="cc-fra-heading__label">{t('income')}</div>
                    {2 !== invoice.state.id &&
                        <div
                            className="cc-fra-heading__data cc-fra-heading__data--big">{formatCurrency(invoice.revenue, i18n.language)}</div>
                    }
                    {2 === invoice.state.id &&
                    <div
                        className="cc-fra-heading__data cc-fra-heading__data--big">
                        {formatCurrency(invoice.revenue, i18n.language)} + {formatCurrency(invoiceDetailsCustomerSum, i18n.language)} = {formatCurrency(invoice.revenue + invoiceDetailsCustomerSum, i18n.language)}
                    </div>
                    }
                </div>
                <StateSelector invoice={invoice}/>
            </div>

        </div>
    );
};
  
export default HeaderInvoice;