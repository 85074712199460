import { InvoiceLogsData } from '../../../domain/InvoiceData';
import InvoiceLogLine from "./InvoiceLogLine";
interface Props{
    logs: InvoiceLogsData[];
}
const InvoiceLogs = ({logs}: Props) => {

    return (
        <div className="cc-log__list">
            {logs.map((log) => (
                <InvoiceLogLine key={log.id} log={log} />
            ))}
        </div>
    );
};
export default InvoiceLogs