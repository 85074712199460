import { useMemo, useState} from "react";
import {TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import Heading from "../../../components/Common/Heading";
import * as React from "react";
import {Link} from "react-router-dom";
import useBackofficeProjectsData from "../../../hooks/use-backoffice-projects-data";
import IconGeneric from "../../../components/Common/Icon/IconGeneric";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import ProjectsTableDataBackOffice from "../../../components/BackOffice/Project/ProjectsTableDataBackOffice";

const ListProjectsBackOffice = () => {
    const {t} = useTranslation();
    const [filterText, setFilterText] = useState("");
    const { projects, loading } = useBackofficeProjectsData();

    const filteredProjects = useMemo(() => {
        return projects
            .filter(project =>
                project.domain.toLowerCase().includes(filterText.toLowerCase()) ||
                project.invoice_group?.name.toLowerCase().includes(filterText.toLowerCase())
            ).map((project) => {
                return {
                    ...project,
                    invoice_group: project.invoice_group?.name
                }
            })
        ;
    }, [projects, filterText]);

    if (loading) {
        return <LoadingFull/>
    }

    return (
        <>
            <Heading title={t('projects')}/>
            <div className="cc-card cc-card--invoice">
                <div className="oc-group oc-group--justify-between">
                    <TextField
                        className="cc-form__input--search cc-form__mui--search"
                        label={t('search')}
                        value={filterText}
                        size="small"
                        onChange={(e) => setFilterText(e.target.value)}
                    />
                    <Link className="mc-button mc-button--primary mc-button--fit is-right" to={'create'}>
                        <IconGeneric iconName="add-ico" className="mc-icon__generic mc-icon__generic--size-12 is-white is-white--stroke" />
                        <span>{t('add')}</span>
                    </Link>
                </div>
                <ProjectsTableDataBackOffice projects={filteredProjects}/>
            </div>
        </>
    );
}
export default ListProjectsBackOffice;