import {useEffect, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import {InvoiceData} from "../domain/InvoiceData";

interface Response {
    invoice: InvoiceData | null,
    loading: boolean
}

const useInvoiceData = (
    invoiceId: string | undefined,
): Response => {
    const [loading, setLoading] = useState(true)
    const client = useMemo(() => new ApiClient(), [])
    const [invoiceData, setInvoiceData] = useState<InvoiceData | null>(null)

    useEffect(() => {
        setLoading(true);
        const invId = invoiceId ?? ''
        client
            .getInvoice(invId)
            .then((response) => {
                const data: InvoiceData = response.data;
                setInvoiceData(data)
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [client, invoiceId, setInvoiceData, setLoading]);

    return {
        invoice: invoiceData,
        loading
    };
}

export default useInvoiceData