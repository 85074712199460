import * as React from "react";
import { useTranslation } from "react-i18next";
import SimpleTooltip from "../../Common/SimpleTooltip";
import useNumbers from "../../../hooks/use-numbers";
import IconGeneric from "../../Common/Icon/IconGeneric";

interface CellDimensionData {
    income: number;
    impressions: number;
    ecpm: number | null;
}

interface Props {
    data: {
        current_stats: CellDimensionData;
        compared_stats: CellDimensionData;
        differential: CellDimensionData;
        differential_percentage: CellDimensionData;
    }
}

const PerformanceEnhancedTableDimensionsCell = ({ data }: Props) => {
    const { i18n, t } = useTranslation();
    const { formatCurrency, formatNumber, formatPercent, roundNumber } = useNumbers();

    const getWarningClass = (value: number) => {
        const roundedValue = roundNumber(value, 2);
        if (roundedValue >= 15) return 'is-positive';
        if (roundedValue <= -15) return 'is-negative';
        return '';
    };

    const renderIncome = () => {
        const incomeCurrent = data.current_stats.income;
        const incomeCompare = data.compared_stats.income;
        const incomeDiffPercentage = data.differential_percentage.income;
        const shouldRenderIncome = incomeCurrent !== 0 || incomeCompare !== 0;
        const incomeColorClass = getWarningClass(incomeDiffPercentage);

        return (
            <p className="mc-data mc-data--income">
                {shouldRenderIncome ? formatCurrency(incomeCurrent, i18n.language) : ''}
                {shouldRenderIncome && (
                    <span className={incomeColorClass}>
                        ({formatPercent(incomeDiffPercentage, i18n.language, 0)})
                    </span>
                )}
            </p>
        );
    };

    const renderImpressions = () => {
        const { impressions: impressionsCurrent } = data.current_stats;
        const impressionsDiffPercentage = data.differential_percentage.impressions;
        const impressionsColorClass = getWarningClass(impressionsDiffPercentage);

        return (
            <p className="mc-data mc-data--views">
                <SimpleTooltip content={t('displays')}>
                    <IconGeneric iconName="view-ico" className="mc-icon mc-icon--admin mc-icon__generic--size-14" />
                    {formatNumber(impressionsCurrent, i18n.language)}
                    <span className={impressionsColorClass}>
                        ({formatPercent(impressionsDiffPercentage, i18n.language, 0)})
                    </span>
                </SimpleTooltip>
            </p>
        );
    };

    const renderEcpm = () => {
        const ecpmCurrent = data.current_stats.ecpm ?? 0;
        const ecpmDiffPercentage = data.differential_percentage.ecpm ?? 0;
        const ecpmColorClass = getWarningClass(ecpmDiffPercentage);

        return (
            <p className="mc-data mc-data--ecpm">
                <SimpleTooltip content={t('ecpm')}>
                    <IconGeneric iconName="revenue-ico" className="mc-icon mc-icon--admin mc-icon__generic--size-12" />
                    {ecpmCurrent !== 0 ? formatCurrency(ecpmCurrent, i18n.language) : ''}
                    <span className={ecpmColorClass}>
                        ({formatPercent(ecpmDiffPercentage, i18n.language, 0)})
                    </span>
                </SimpleTooltip>
            </p>
        );
    };

    return (
        <>
            {renderIncome()}
            {data.current_stats.income > 0 && (
                <>
                    {renderImpressions()}
                    {renderEcpm()}
                </>
            )}
        </>
    );
};

export default PerformanceEnhancedTableDimensionsCell;