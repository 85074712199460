import {HeadCell, TableDataInvoices} from "../../../domain/TableData";
import {useEffect, useMemo} from "react";
import useDates from "../../../hooks/use-dates";
import {useTranslation} from "react-i18next";
import EnhancedTableBackOffice from "../Table/EnhancedTableBackOffice";
import InvoicesEnhancedTableRowBackOffice from "./InvoicesEnhancedTableRowBackOffice";
import useFiltersContext from "../../../hooks/context/use-filters-context";
import {InvoiceData} from "../../../domain/InvoiceData";
import {FilterOption} from "../../../domain/FilterOption";
import usePaginationContext from "../../../hooks/context/use-pagination-context";
import {ErrorComponent} from "../../Common/ErrorComponent";

const headCells: readonly HeadCell<TableDataInvoices>[] = [
    {
        id: 'company_name',
        numeric: false,
        disablePadding: false,
        label: 'company',
        sortable: true,
    },
    {
        id: 'reference',
        numeric: false,
        disablePadding: false,
        label: 'reference',
        sortable: true,
    },
    {
        id: 'creation_date',
        numeric: true,
        disablePadding: false,
        label: 'creation_date',
        sortable: true,
    },
    {
        id: 'start_period',
        numeric: true,
        disablePadding: false,
        label: 'start_period',
        sortable: true,
    },
    {
        id: 'end_period',
        numeric: true,
        disablePadding: false,
        label: 'end_period',
        sortable: true,
    },    
    {
        id: 'state',
        numeric: true,
        disablePadding: false,
        label: 'state',
        sortable: true,
    },
    {
        id: 'income',
        numeric: true,
        disablePadding: false,
        label: 'income',
        sortable: true,
    },
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'actions',
        sortable: false,
    },
]

interface Props {
    data: InvoiceData[]
}

const InvoicesTableDataBackOffice = ({data}: Props) =>{
    const {
        invoiceReference,
        filteredInvoiceStates,
        filterableInvoiceStates,
        setFilterableInvoiceStates
    } =  useFiltersContext();
    const paginationContextData = usePaginationContext();
    const {t, i18n} = useTranslation();
    const {getMonthName, getYear, formatDate} = useDates()

    useEffect(() => {
        const filterableInvoiceStates = data.map((invoice: InvoiceData): FilterOption => {
            return {
                id: invoice.state.id,
                name: t(invoice.state.label)
            }
        }).reduce((accumulator: FilterOption[], currentValue: FilterOption) => {
            const isDuplicate = accumulator.some(obj => obj.id === currentValue.id);
            if (!isDuplicate) {
                accumulator.push(currentValue);
            }
            return accumulator;
        }, []);

        setFilterableInvoiceStates(filterableInvoiceStates);
    }, [t, setFilterableInvoiceStates, data])


    let rows: TableDataInvoices[] = useMemo(() =>  data ? Object.values(data).map((inv) => {
        const date =  new Date(inv.date);

        const historyPayments = Object.values(inv.payments_history).map((payment, index) => {
            return {
                payment_number: index + 1,
                date: payment.date === null ? '--/--/--' : formatDate(new Date(payment.date), i18n.language),                
                state: payment.state,
                revenue: payment.revenue
            }
        });

        const invoiceDetailsCustomerSum = inv.invoice_details.reduce((accumulator, current) => {
            return accumulator + current.revenue;
        }, 0);

        return {
            id: inv.id,
            creation_date: date,
            start_period: new Date(inv.start_period),
            end_period: new Date(inv.end_period),
            month: getMonthName(date, i18n.language),
            year: getYear(date, i18n.language),
            company_name: inv.company_name,
            tax_identifier: inv.tax_identifier,
            state: inv.state,
            income: inv.state.id !== 2 ? inv.revenue : (inv.revenue + invoiceDetailsCustomerSum),
            payments_history: historyPayments,
            reference: inv.reference,
        }
    }
    ) : []
    , [data, getMonthName, getYear, formatDate, i18n.language])

    if (filteredInvoiceStates.length > 0 && filteredInvoiceStates.length < filterableInvoiceStates.length) {
        rows = rows.filter((r) => {
            return filteredInvoiceStates.filter((s: any) => {
                return s.id === r.state.id
            }).length > 0
        })
    }

    const searchFilteredRows = rows.filter((r) => r.reference.toLowerCase().includes(invoiceReference.toLowerCase()));

    if (0 === searchFilteredRows.length) {
        return <ErrorComponent title={t("no-results")} subtitle={t("try to modify the active filters to get results")} />
    }

    return <EnhancedTableBackOffice
        headCells={headCells}
        rows={searchFilteredRows}
        RowComponent={InvoicesEnhancedTableRowBackOffice}
        paginationSettings={
            {
                key: 'data',
                rowsPerPage: paginationContextData.getRowsPerPage('data') ?? 25,
                rowsPerPageOptions: [25, 50, 75, 100, { label: t('all'), value: -1 }]
            }
        }
    />
}

export default InvoicesTableDataBackOffice;