import {useMemo } from "react";
import { StatsContext } from "../../../hooks/context/use-stats-context";
import Filters from "../../../components/Common/Filters/Filters";
import GeneralViewBackOffice from "../../../components/BackOffice/Stats/General/GeneralViewBackOffice";
import GeneralChartBackOffice from "../../../components/BackOffice/Stats/General/GeneralChartBackOffice";
import GeneralTableDataBackOffice from "../../../components/BackOffice/Stats/General/GeneralTableDataBackOffice";
import useFiltersContext from "../../../hooks/context/use-filters-context";
import useBackOfficeGeneralData from "../../../hooks/use-backoffice-general-data";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import { useTranslation } from "react-i18next";
import Heading from "../../../components/Common/Heading";
import TabComponent from "../../../components/Common/Tabs/TabComponent";

const ListGeneralDataBackOffice = () => {
    const { t } = useTranslation();
    const filtersContextData = useFiltersContext();
    const { generalData, loading } = useBackOfficeGeneralData(
        filtersContextData.dateRange,
        filtersContextData.filteredSubsites,
        filtersContextData.filteredPartners,
        filtersContextData.filteredDevices,
        filtersContextData.filteredEnvironments
    );

    const dateRangeLabel = filtersContextData.dateRange.label;
    const shouldShowByDays = ['last_7_days', 'last_30_days', 'current_month', 'last_month', 'custom'].includes(dateRangeLabel);
    const shouldShowByMonths = ['last_3_months', 'current_year', 'last_year'].includes(dateRangeLabel);
    const tabComponents = useMemo(() => {
        const components = [];

        if (shouldShowByDays) {
            components.push({
                index: 0,
                label: 'by-days',
                component: <GeneralTableDataBackOffice data={{
                    stats: generalData?.stats,
                    summary: generalData?.summary
                }} />
            });
        }

        if (shouldShowByMonths) {
            components.push({
                index: 1,
                label: 'by-months',
                component: <GeneralTableDataBackOffice data={{
                    stats: generalData?.stats,
                    summary: generalData?.summary
                }} />
            });
        }

        return components;
    }, [generalData, shouldShowByDays, shouldShowByMonths]);

    if (loading) {
        return <LoadingFull />;
    }

    return (
        <StatsContext.Provider value={{ generalData: generalData }}>
            <Heading title={t('general')} />
            <Filters />
            <GeneralViewBackOffice data={generalData?.summary} />
            <GeneralChartBackOffice data={generalData?.stats} />
            <TabComponent
                key={`tab-${shouldShowByDays ? 0 : 1}`}
                initialIndex={shouldShowByDays ? 0 : 1}
                components={tabComponents}
            />
        </StatsContext.Provider>
    );
};

export default ListGeneralDataBackOffice;