import React, { useContext } from "react";
import { AppContext } from "../../../pages/App/Home";
import {BackOfficeContext} from "../../../pages/BackOffice/HomeBackOffice";
import SubsiteMenuBackOffice from "../../BackOffice/Header/SubsiteMenuBackOffice";
import SubsiteMenuAccounts from "../../App/Common/Header/SubsiteMenuAccounts";


export default function BasicTabs() {
    const { accounts } = useContext(AppContext);
    const { isAdmin } = useContext(BackOfficeContext);

    return(
        <>
            {isAdmin && <SubsiteMenuBackOffice/>}
            {!isAdmin && accounts && <SubsiteMenuAccounts/>}
        </>
    )
}
