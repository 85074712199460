import React from "react";
import useNumbers from "../../../../hooks/use-numbers";
import {useTranslation} from "react-i18next";

interface Props {
    total: number
}

const DimensionsChartTotalTooltipItem = ({total}: Props) => {
    const {i18n, t} = useTranslation();
    const {formatCompactCurrency} = useNumbers();

    const resolvedValue = formatCompactCurrency(total, i18n.language, 2);
    const isValidResolvedValue = '-' !== resolvedValue

    return (
        <div className="cc-chart__tooltip-wrapper is-total">
            <div className="cc-chart__tooltip-item" style={{color: '#000'}}>
                <span className="cc-chart__tooltip-item-name">{t('total')}</span>
                <div className="cc-chart__tooltip-item-bar"></div>

                <div className="cc-chart__tooltip-item-value-wrapper">
                    <div className="cc-chart__tooltip-item-value">
                        <div className="cc-chart__tooltip-item-value--new">{isValidResolvedValue ? resolvedValue : '-'}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DimensionsChartTotalTooltipItem