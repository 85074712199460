import {useContext, useEffect, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import {AppContext} from "../pages/App/Home";
import {InvoiceData} from "../domain/InvoiceData";

interface Response {
    invoices: InvoiceData[],
    loading: boolean
}

const initialData: InvoiceData[] = [];

const useAppInvoicesData = (): Response => {
    const [loading, setLoading] = useState(true)
    const {accounts, selectedAccount} = useContext(AppContext)
    const client = useMemo(() => new ApiClient(), [])
    const [invoicesData, setInvoicesData] = useState<InvoiceData[]>(initialData)

    const pids =  useMemo(() => {
        if(selectedAccount){
            return selectedAccount.project_id
        }else{
            return (accounts.map((account => account.project_id))).join(',');
        }
    }, [accounts, selectedAccount])

    useEffect(() => {
        setInvoicesData(initialData)
    }, [setInvoicesData, selectedAccount, pids])

    useEffect(() => {
        const parameters = {pids: pids};
        setLoading(true);
        client
            .getInvoices(parameters)
            .then((response) => {
                const data: InvoiceData[] = response.data;
                setInvoicesData(data)
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [client, pids, setLoading])

    return {
        invoices: invoicesData,
        loading
    };
}

export default useAppInvoicesData