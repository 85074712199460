import React, {useMemo} from 'react';
import ApiClient from "../../../client/ApiClient";
import {useSnackbar} from "../../../hooks/ui/use-snackbar";
import {useTranslation} from "react-i18next";
import {Alert, Snackbar} from "@mui/material";
import {useNavigate} from "react-router-dom";
import AddProjectForm from "../../../components/BackOffice/Project/AddProjectForm";
import {ProjectFormData} from "../../../components/BackOffice/Project/ProjectForm";

const AddProjectBackOffice = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const client = useMemo(() => new ApiClient(), []);
    const {snackbar, showSnackbar, closeSnackbar} = useSnackbar();

    const handleCreate = async (formData: ProjectFormData) => {
        try {
            const response = await client.createProject(formData);
            navigate('/backoffice/projects')

            return response.data;
        } catch (error) {
            showSnackbar(t('save project error'), 'error');
        }
    }

    const handleCancel = () => {
        navigate('/backoffice/projects')
    }

    return (
        <>
            <AddProjectForm
                onSave={handleCreate}
                onCancel={handleCancel}
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={closeSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={closeSnackbar} />
                </Snackbar>
            )}
        </>
    );
};

export default AddProjectBackOffice;
