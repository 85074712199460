import {
    Bar,
    BarChart,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    CartesianGrid
} from "recharts";
import {DevicesChartData, EnvironmentsChartData, FormatsChartData} from "../../../domain/ChartData";
import * as React from "react";
import useNumbers from "../../../hooks/use-numbers";
import {useTranslation} from "react-i18next";
import CustomizedXAxis from "./CustomizedXAxis";
import DimensionsChartTooltip from "../../App/Common/Chart/DimensionsChartTooltip";
import {chartColors} from "./ChartColors";
import CustomizedLegend from "./CustomizedLegend";
import css from '../../../sass/01.settings/_settings.module.scss';

interface Props {
    chart: FormatsChartData[] | EnvironmentsChartData[] | DevicesChartData[]
}

const BarMainChart = ({chart}: Props) => {
    const {i18n} = useTranslation();
    const {formatCompactCurrency} = useNumbers();

    const isEmpty = chart.length === 0;

    const isFormatsChartData = !isEmpty && 'native' in chart[0];
    const isEnvironmentsChartData = !isEmpty && 'web' in chart[0];
    const isDevicesChartData = !isEmpty && 'desktop' in chart[0];

    return <div className="cc-chart cc-chart__main">
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                width={500}
                height={300}
                data={isEmpty ? [{}] : chart}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis dataKey="date" interval={0} tick={({ x, y, payload })  => <CustomizedXAxis x={x} y={y} payload={payload}/>} tickLine={{ stroke: css.coStatStroke }} />
                <YAxis tickFormatter={(tick) => formatCompactCurrency(tick, i18n.language, 0)} tickCount={8} axisLine={{ stroke: css.coStatStroke }} tickLine={{ stroke: css.coStatStroke }}/>
                <Tooltip cursor={false} content={({ active, payload, label }) => <DimensionsChartTooltip active={active} payload={payload} label={label} chartColors={chartColors}/>} />
                <Legend align="left" iconType="circle" content={({payload}) =>  <CustomizedLegend payload={payload as any} />}/>
                <CartesianGrid vertical={false} horizontal={true} stroke={css.coStatStrokeLight} />
                {isDevicesChartData  &&
                    <>
                        <Bar width={30} barSize={30} dataKey="desktop" stackId="1" stroke={chartColors.devices.desktop} fill={chartColors.devices.desktop}/>
                        <Bar width={30} barSize={30} dataKey="mobile" stackId="1" stroke={chartColors.devices.mobile} fill={chartColors.devices.mobile} />
                        <Bar width={30} barSize={30} dataKey="tablet" stackId="1" stroke={chartColors.devices.tablet} fill={chartColors.devices.tablet} />
                        <Bar width={30} barSize={30} dataKey="other" stackId="1" stroke={chartColors.devices.other} fill={chartColors.devices.other} />
                    </>
                }
                {isEnvironmentsChartData &&
                    <>
                        <Bar barSize={30} dataKey="web" stackId="1" stroke={chartColors.environments.web} fill={chartColors.environments.web}/>
                        <Bar barSize={30} dataKey="amp" stackId="1" stroke={chartColors.environments.amp} fill={chartColors.environments.amp} />
                        <Bar barSize={30} dataKey="other" stackId="1" stroke={chartColors.environments.other} fill={chartColors.environments.other} />
                    </>
                }
                {isFormatsChartData &&
                    <>
                        <Bar barSize={30} dataKey="native" stackId="1" stroke={chartColors.formats.native} fill={chartColors.formats.native}/>
                        <Bar barSize={30} dataKey="display" stackId="1" stroke={chartColors.formats.display} fill={chartColors.formats.display} />
                        <Bar barSize={30} dataKey="video" stackId="1" stroke={chartColors.formats.video} fill={chartColors.formats.video}/>
                        <Bar barSize={30} dataKey="inimage" stackId="1" stroke={chartColors.formats.inimage} fill={chartColors.formats.inimage} />
                        <Bar barSize={30} dataKey="skin" stackId="1" stroke={chartColors.formats.skin} fill={chartColors.formats.skin} />
                        <Bar barSize={30} dataKey="other" stackId="1" stroke={chartColors.formats.other} fill={chartColors.formats.other} />
                    </>
                }
            </BarChart>
        </ResponsiveContainer>
    </div>
}

export default BarMainChart;