import React, {useCallback, useState} from 'react';
import { TextField, Button } from '@mui/material';
import {useTranslation} from "react-i18next";

export interface InvoiceGroupFormData{
    name: string;
    company_name: string;
    tax_identifier: string;
    emails_admin: string;
}

type Props = {
    invoiceGroupId?: number;
    initialValues: InvoiceGroupFormData;
    onSave: (formData: InvoiceGroupFormData) => void;
    btnSaveText: string;
    onCancel: () => void;
    onDelete?: (invoiceGroupId: number) => void;
};

const InvoiceGroupForm = ({ invoiceGroupId, initialValues, onSave, btnSaveText, onCancel, onDelete }: Props) => {
    const {t} = useTranslation()
    const [formData, setFormData] = useState<InvoiceGroupFormData>(initialValues);
    const [touchedFields, setTouchedFields] = useState<Record<string, boolean>>({});

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevState: InvoiceGroupFormData) => ({
            ...prevState,
            [name]: value
        }));
    },[]);

    const handleSave = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        onSave(formData);
    },[onSave, formData]);

    const handleCancel = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        onCancel();
    }, [onCancel]);

    const handleDelete = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (onDelete && invoiceGroupId) {
            onDelete(invoiceGroupId);
        }
    },[onDelete, invoiceGroupId]);

    const handleBlur = useCallback((field: string) => {
        setTouchedFields(prevState => ({
            ...prevState,
            [field]: true,
        }));
    },[]);

    const isTouched = useCallback((field: keyof InvoiceGroupFormData) => {
        return touchedFields[field];
    },[touchedFields]);

    return (
        <form className='cc-form'>
            {invoiceGroupId && 
                <TextField
                    name="id"
                    label={'ID'}
                    value={invoiceGroupId}
                    onChange={handleChange}
                    fullWidth
                    required={!!invoiceGroupId}
                    disabled={invoiceGroupId > 0}
                />
            }
            <TextField
                name="name"
                label={t('name')}
                value={formData.name}
                onChange={handleChange}
                fullWidth
                required
                onBlur={() => handleBlur('name')}
                error={isTouched('name') && formData.name.trim() === ''}
            />
            <TextField
                name="company_name"
                label={t('company-name')}
                value={formData.company_name}
                onChange={handleChange}
                fullWidth
                required
                onBlur={() => handleBlur('company_name')}
                error={isTouched('company_name') && formData.company_name.trim() === ''}
            />
            <TextField
                name="tax_identifier"
                label={t('tax-identifier')}
                value={formData.tax_identifier}
                onChange={handleChange}
                fullWidth
                required
                onBlur={() => handleBlur('tax_identifier')}
                error={isTouched('tax_identifier') && formData.tax_identifier.trim() === ''}
            />
            <TextField
                name="emails_admin"
                label={t('emails-admin')}
                value={formData.emails_admin}
                onChange={handleChange}
                fullWidth
                required
                onBlur={() => handleBlur('emails_admin')}
                error={isTouched('emails_admin') && formData.emails_admin.trim() === ''}
            />
            <div className='oc-group'>
                <Button className='mc-button mc-button--primary' onClick={handleSave} type="submit" variant="contained" color="primary">
                    {btnSaveText}
                </Button>
                <Button className='mc-button mc-button--secondary' onClick={handleCancel} variant="contained" color="primary">
                    {t('cancel')}
                </Button>
                {invoiceGroupId &&
                    <Button className='mc-button mc-button--danger mc-button--to-right' onClick={handleDelete} variant="contained" color="secondary">
                        {t('delete')}
                    </Button>
                }
            </div>
        </form>
    );
};

export default InvoiceGroupForm;