import {TableDataFormats} from "../../../../domain/TableData";
import {useTranslation} from "react-i18next";
import useDates from "../../../../hooks/use-dates";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EnhancedTableDimensionsCell from "../../Common/Table/EnhancedTableDimensionsCell";
import * as React from "react";

const FormatsEnhancedTableRow = (props: { id: string, row: TableDataFormats }) => {
    const { i18n} = useTranslation();
    const {formatDate} = useDates();
    const { row } = props;

    return (
        <TableRow
            hover
            tabIndex={-1}
            key={row.datetime}
        >
            <TableCell
                id={row.datetime.toString()}
                scope="row"
            >
                <div className='mc-date'>{formatDate(new Date(row.datetime), i18n.language)}</div>
                <div className='mc-date mc-date--mini'>{row.dayWeek}</div>
            </TableCell>
            <TableCell><EnhancedTableDimensionsCell data={row.native}/></TableCell>
            <TableCell><EnhancedTableDimensionsCell data={row.display}/></TableCell>
            <TableCell><EnhancedTableDimensionsCell data={row.video}/></TableCell>
            <TableCell><EnhancedTableDimensionsCell data={row.inimage}/></TableCell>
            <TableCell><EnhancedTableDimensionsCell data={row.skin}/></TableCell>
            <TableCell><EnhancedTableDimensionsCell data={row.other}/></TableCell>
            <TableCell><EnhancedTableDimensionsCell data={row.total}/></TableCell>
        </TableRow>
    );
}

export default FormatsEnhancedTableRow;