import React from 'react';
import { Link } from 'react-router-dom';
import IconGeneric from './Icon/IconGeneric';

interface BackButtonProps {
    to: string;
}

function BackButton(props: BackButtonProps) {
    const { to } = props;

    return (
        <Link className="mc-button mc-button--rounded mc-button--back" to={to} relative="path">
            <IconGeneric iconName="arrow-back-ico" className="mc-icon__generic mc-icon__generic--size-16 is-primary" />
        </Link>
    );
}

export default BackButton;
