import * as React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { HeadCell } from "../../../../domain/TableData";
import { OrderCriteria } from "../../../../domain/Sorting";
import {useTranslation} from "react-i18next";

// Define the props interface for the component
interface EnhancedTableHeadProps<T> {
    headCells: readonly HeadCell<T>[],
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
    order: OrderCriteria;
    orderBy: keyof T;
    rowCount: number;
}

function EnhancedTableHead<T>(props: EnhancedTableHeadProps<T>) {
    const { headCells, order, orderBy, onRequestSort } = props;
    const {t} = useTranslation();

    const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id as string}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sortable ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {t(headCell.label)}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            t(headCell.label)
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;
