import {HeadCell, TableDataInvoiceGroups} from "../../../domain/TableData";
import EnhancedTableBackOffice from "../Table/EnhancedTableBackOffice";
import InvoiceGroupsEnhancedTableRowBackOffice from "./InvoiceGroupsEnhancedTableRowBackOffice";
import * as React from "react";
import usePaginationContext from "../../../hooks/context/use-pagination-context";
import {useTranslation} from "react-i18next";
import {ErrorComponent} from "../../Common/ErrorComponent";

const headCells: readonly HeadCell<TableDataInvoiceGroups>[] = [
    {
        id: 'id',
        numeric: true,
        disablePadding: false,
        label: 'id',
        sortable: true,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        sortable: true,
    },
    {
        id: 'company_name',
        numeric: false,
        disablePadding: false,
        label: 'company-name',
        sortable: true,
    },
    {
        id: 'tax_identifier',
        numeric: false,
        disablePadding: false,
        label: 'tax-identifier',
        sortable: true,
    },
    {
        id: 'emails_admin',
        numeric: false,
        disablePadding: false,
        label: 'email',
        sortable: true,
    },
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'actions',
        sortable: false,
    },
];

interface Props {
    invoiceGroups: TableDataInvoiceGroups[];
}

const InvoiceGroupsTableDataBackOffice = ({invoiceGroups}: Props) =>{
    const {t} = useTranslation()
    const paginationContextData = usePaginationContext();

    if (0 === invoiceGroups.length) {
        return <ErrorComponent title={t("no-results")} subtitle={t("try to modify the active filters to get results")} />
    }

    return(
        <>
            <EnhancedTableBackOffice
                headCells={headCells}
                rows={invoiceGroups}
                RowComponent={InvoiceGroupsEnhancedTableRowBackOffice}
                paginationSettings={
                    {
                        key: 'invoice-groups',
                        rowsPerPage: paginationContextData.getRowsPerPage('invoice-groups') ?? 10,
                        rowsPerPageOptions: [10, 20, 30, 40, 50, { label: t('all'), value: -1 }]
                    }
                }
            />
        </>

    )
}

export default InvoiceGroupsTableDataBackOffice;