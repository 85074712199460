import CssBaseline from "@mui/material/CssBaseline";
import SubsiteMenu from "./SubsiteMenu";
import Logo from "../Icon/Logo";
import LangSelector from "./LangSelector";
import IconGeneric from "../Icon/IconGeneric";
import useAppAuthentication from "../../../hooks/use-app-authentication";
import {useContext} from "react";
import {BackOfficeContext} from "../../../pages/BackOffice/HomeBackOffice";

const Header = () => {
    const { isAdmin } = useContext(BackOfficeContext);
    const { logout } = useAppAuthentication();

    return(
        <>
            <CssBaseline/>
            <header className="cc-header">
                <Logo isAdmin={isAdmin}/>
                <SubsiteMenu/>
                <LangSelector/>
                <button className='mc-button mc-button--secondary mc-button--no-border mc-button--logout'
                    onClick={() => {
                        logout()
                    }}
                >
                    <IconGeneric iconName="logout-ico" className="mc-icon__generic--size-16" />
                </button>
            </header>
        </>
    )
}

export default Header;