import {useTranslation} from "react-i18next";
import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {HistoricalDataValues} from "../../../domain/TableData";
import useNumbers from "../../../hooks/use-numbers";

interface Props{
    totals:  HistoricalDataValues
}

const HistoricalTotalsRowBackOffice = ({ totals }: Props) => {
    const { i18n } = useTranslation();
    const { formatCurrency, formatPercent } = useNumbers();

    return (
        <TableRow className="cc-table__totals">
            <TableCell className="cc-table__totals-title">Total</TableCell>
            <TableCell className="cc-table__totals-title"></TableCell>
            <TableCell className="cc-table__totals-data">
                <span className={'mc-data'}>
                    {formatCurrency(totals.income, i18n.language, 2)}
                </span>
            </TableCell>
            <TableCell className="cc-table__totals-data">
                <span className={'mc-data'}>
                    {formatCurrency(totals.income_dashboard, i18n.language, 2)}
                </span>
            </TableCell>
            <TableCell className="cc-table__totals-data">
                <span className={'mc-data'}>
                    {formatCurrency(totals.differential, i18n.language, 2)}
                </span>
            </TableCell>
            <TableCell className="cc-table__totals-data">
                <span className={'mc-data'}>
                    {formatPercent(totals.differential_percentage, i18n.language, 2)}
                </span>
            </TableCell>
        </TableRow>
    );
}

export default HistoricalTotalsRowBackOffice;