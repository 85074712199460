import React, {useCallback, useMemo} from "react";
import {FilterOption} from "../../domain/FilterOption";

const useMultiselectFilter = (
    items: FilterOption[],
    preSelectedItems: FilterOption[],
    setPreSelectedItems:  React.Dispatch<React.SetStateAction<FilterOption[]>>,
    filteredItems: FilterOption[],
    setFilteredItems:  React.Dispatch<React.SetStateAction<FilterOption[]>>
) => {

    const areAllSelected = useMemo( () => {
        return preSelectedItems.length === items.length;
    }, [preSelectedItems, items.length]);

    const handleSelectAll = useCallback(() => {
        if (areAllSelected) {
            setPreSelectedItems([])
        } else {
            setPreSelectedItems(items)
        }
    },[areAllSelected, setPreSelectedItems, items]);


    const handleSelectNone = useCallback(() =>
    {
        setPreSelectedItems([]);
        setFilteredItems([])
    },[setPreSelectedItems, setFilteredItems])

    const handleClickMenuItem = useCallback((item: any) => {
        const itemIndex = preSelectedItems.findIndex(fi => fi.id === item.id);
        if (itemIndex > -1) {
            setPreSelectedItems(prevItems => prevItems.filter(fi => fi.id !== item.id));
        } else {
            setPreSelectedItems(prevItems => [...prevItems, item]);
        }
    },[preSelectedItems, setPreSelectedItems]);

    const removeSingleOption = useCallback((selectedOptions: FilterOption[]) =>{
        setPreSelectedItems(selectedOptions);
        setFilteredItems(selectedOptions)
    },[setPreSelectedItems, setFilteredItems])

    return{
        areAllSelected,
        handleSelectAll,
        handleSelectNone,
        handleClickMenuItem,
        removeSingleOption
    }

}

export default useMultiselectFilter