import DateSelector from "../DateFilter/DateSelector";
import {useContext} from "react";
import {BackOfficeContext} from "../../../pages/BackOffice/HomeBackOffice";
import FiltersBackOffice from "./FiltersBackOffice";

const Filters = () => {
    const { isAdmin } = useContext(BackOfficeContext);

    return (
        <div className="cc-filter">
            <div className='cc-filter__wrapper'>
                <DateSelector/>
                {isAdmin && <FiltersBackOffice />}
            </div>
        </div>
    );
};

export default Filters;
