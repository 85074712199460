import React, {useMemo} from 'react';
import EditInvoiceGroupForm from "../../../components/BackOffice/InvoiceGroup/EditInvoiceGroupForm";
import useInvoiceGroupData from "../../../hooks/use-invoice--group-data";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import {useNavigate, useParams} from "react-router-dom";
import ApiClient from "../../../client/ApiClient";
import {Alert, Snackbar} from "@mui/material";
import {useSnackbar} from "../../../hooks/ui/use-snackbar";
import {useTranslation} from "react-i18next";
import {InvoiceGroupFormData} from "../../../components/BackOffice/InvoiceGroup/InvoiceGroupForm";

const EditInvoiceGroupBackOffice = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const client = useMemo(() => new ApiClient(), []);
    const {snackbar, showSnackbar, closeSnackbar} = useSnackbar();
    const { invoiceGroupId } = useParams<{ invoiceGroupId: string }>();
    const invoiceGroup = useInvoiceGroupData(invoiceGroupId);

    if (!invoiceGroup) {
        return <LoadingFull />
    }

    const handleEdit = async (formData: InvoiceGroupFormData) => {
        try {
            const response = await client.updateInvoiceGroup(invoiceGroup.id, formData);
            navigate('/backoffice/invoice-groups');

            return response.data;
        } catch (error) {
            showSnackbar(t('update invoice-group error'), 'error');
        }
    }

    const handleCancel = () => {
        navigate('/backoffice/invoice-groups')
    }

    const handleDelete = async (id: number) => {
        try {
            await client.deleteInvoiceGroup(id);
            navigate('/backoffice/invoice-groups')
        } catch (error) {
            showSnackbar(t('delete invoice-group error'), 'error');
        }
    }

    return (
        <>
            <EditInvoiceGroupForm
                invoiceGroup={invoiceGroup}
                onSave={handleEdit}
                onCancel={handleCancel}
                onDelete={handleDelete}
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={closeSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={closeSnackbar} />
                </Snackbar>
            )}
        </>
    );
};

export default EditInvoiceGroupBackOffice;
