import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            className="cc-tab__tab"
            role="tabpanel"
            hidden={value !== index}
            id={`advertising-tabpanel-${index}`}
            aria-labelledby={`advertising-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

interface TabComponentProps {
    initialIndex: number;
    components: { index: number; label: string, component: React.ReactNode }[];
    onHandleChange?: (newValue: number) => void;
}

const TabComponent = ({ initialIndex, components, onHandleChange }: TabComponentProps) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(initialIndex);

    const handleChange = (event: React.SyntheticEvent, newIndex: number) => {
        setValue(newIndex);
        if (onHandleChange) {
            onHandleChange(newIndex);
        }
    };

    return (
        <>
            <div className="cc-tab">
                <div className="cc-tab__wrapper cc-tab__wrapper--selector" role="tablist" aria-label="Comitium Advertising Tabs">
                    {components.map((component) => (
                        <button
                            className={`cc-tab__selector ${value === component.index ? 'cc-tab__selector--selected' : ''}`}
                            key={component.index}
                            role="tab"
                            aria-selected={value === component.index}
                            id={`advertising-tab-${component.index}`}
                            aria-controls={`advertising-tabpanel-${component.index}`}
                            onClick={(e) => handleChange(e, component.index)}
                        >
                            {t(component.label)}
                        </button>
                    ))}
                </div>
                {components.map((component) => (
                    <CustomTabPanel key={component.index} value={value} index={component.index}>
                        {component.component}
                    </CustomTabPanel>
                ))}
            </div>
        </>
    );
};

export default TabComponent;