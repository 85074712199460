import {useCallback, useEffect, useState} from "react";
import ApiClient from "../client/ApiClient";
import {useNavigate} from "react-router-dom";

export const useBackofficeAuthentication = () => {
    const [isAdmin, setIsAdmin] = useState(false)
    const navigate = useNavigate()

    const checkIsAdmin = useCallback(async () => {
        const client = new ApiClient();

        try {
            const response = await client.request("get", "/backoffice/admin-check")

            setIsAdmin(response.data.is_admin)
        } catch (exception) {
            navigate("/unauthorized")
        }
    }, [setIsAdmin, navigate])

    useEffect(() => {
        checkIsAdmin()
    }, [checkIsAdmin])

    return {
        isAdmin,
    }
}