import {useCallback, useEffect, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import {InvoiceGroup} from "../domain/InvoiceGroup";

const useInvoiceGroupData = (
    invoiceGroupId: string | undefined,
): InvoiceGroup | null => {
    const client = useMemo(() => new ApiClient(), [])
    const [invoiceGroupData, setInvoiceGroupData] = useState<InvoiceGroup | null>(null)

    useEffect(() => {
        setInvoiceGroupData(null)
    }, [setInvoiceGroupData])

    const resolveResetData = useCallback(() => {
        setInvoiceGroupData(null)
    }, [setInvoiceGroupData])

    // Show loading when date filters change
    useEffect(() => {
        resolveResetData();
    }, [resolveResetData])

    useEffect(() => {
        const fetchData = () => {
            client.abort()
            const invGroupId = invoiceGroupId ?? ''
            client.getInvoiceGroup(invGroupId).then((response) => {
                const data: InvoiceGroup = response.data;

                setInvoiceGroupData(data)
            }).catch((error) => {console.error(error)})
        }

        fetchData();

    }, [client, invoiceGroupId])

    return invoiceGroupData;
}

export default useInvoiceGroupData