import React from "react";
import {useTranslation} from "react-i18next";
import useDates from "../../../../hooks/use-dates";
import DimensionsChartTooltipItem from "./DimensionsChartTooltipItem";
import DimensionsChartTotalTooltipItem from "./DimensionsChartTotalTooltipItem";

interface CustomTooltipProps {
    chartColors: any
    active?: boolean;
    payload?: any[];
    label?: string | number;
}

const DimensionsChartTooltip: React.FC<CustomTooltipProps> = ({active, payload, label }) => {
    const {i18n, t} = useTranslation()
    const date = label ? new Date(label) : null;
    const {formatDate} = useDates();

    if (active && payload && payload.length) {
        let total = 0;
        return (
            <div className="cc-chart__tooltip">
                <div className="cc-chart__tooltip-date">
                    {formatDate(date, i18n.language)}
                </div>


                { payload.map((data, index) => {
                    total= Object.values(data.payload).reduce((accumulator: number, currentValue) => {
                        if (typeof currentValue === 'number') {
                            return accumulator + currentValue;
                        } else {
                            return accumulator;
                        }
                    }, 0);

                    return <DimensionsChartTooltipItem
                        key={index}
                        label={t(data.name)}
                        value={data.value}
                        color={data.stroke}
                        total={total}
                    />
                }) }
                <DimensionsChartTotalTooltipItem total={total} />
            </div>
        );
    }
    return null;
};

export default DimensionsChartTooltip