import {createContext, useContext} from "react";
import {AppGeneralData} from "../use-app-general-data";
import {AppDimensionData} from "../use-app-dimensions-data";

export interface StatsContextInterface {
    generalData?: AppGeneralData,
    dimensionData?: AppDimensionData
}

export const StatsContext = createContext<StatsContextInterface>({} as StatsContextInterface)

const useStatsContext = () => useContext(StatsContext)

export default useStatsContext