import {HeadCell, TableDataProjects} from "../../../domain/TableData";
import EnhancedTableBackOffice from "../Table/EnhancedTableBackOffice";
import * as React from "react";
import ProjectsEnhancedTableRowBackOffice from "./ProjectsEnhancedTableRowBackOffice";
import usePaginationContext from "../../../hooks/context/use-pagination-context";
import {useTranslation} from "react-i18next";
import {ErrorComponent} from "../../Common/ErrorComponent";

const headCells: readonly HeadCell<TableDataProjects>[] = [
    {
        id: 'id',
        numeric: true,
        disablePadding: false,
        label: 'id',
        sortable: true,
    },
    {
        id: 'domain',
        numeric: false,
        disablePadding: false,
        label: 'domain',
        sortable: true,
    },
    {
        id: 'revenue_share',
        numeric: true,
        disablePadding: false,
        label: 'revenue-share',
        sortable: true,
    },
    {
        id: 'invoice_group',
        numeric: false,
        disablePadding: false,
        label: 'invoice-group',
        sortable: true,
    },
    {
        id: 'date_registration',
        numeric: false,
        disablePadding: false,
        label: 'date-registration',
        sortable: true,
    },
    {
        id: 'date_unregistration',
        numeric: false,
        disablePadding: false,
        label: 'date-unregistration',
        sortable: true,
    },
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'actions',
        sortable: false,
    },
];

interface Props {
    projects: TableDataProjects[];
}

const ProjectsTableDataBackOffice = ({projects}: Props) =>{
    const {t} = useTranslation()
    const paginationContextData = usePaginationContext();

    if (0 === projects.length) {
        return <ErrorComponent title={t("no-results")} subtitle={t("try to modify the active filters to get results")} />
    }

    return(
        <>
            <EnhancedTableBackOffice
                headCells={headCells}
                rows={projects}
                RowComponent={ProjectsEnhancedTableRowBackOffice}
                paginationSettings={
                    {
                        key: 'projects',
                        rowsPerPage: paginationContextData.getRowsPerPage('projects') ?? 10,
                        rowsPerPageOptions: [10, 20, 30, 40, 50, { label: t('all'), value: -1 }]
                    }
                }

            />
        </>

    )
}

export default ProjectsTableDataBackOffice;