import {useParams} from "react-router-dom";
import {useEffect} from "react";
import logoDesktop from '../assets/logo-advertising-desktop.svg';
import {useTranslation} from "react-i18next";
import Loading from '../components/Common/Loading/Loading';
import useAppAuthentication from "../hooks/use-app-authentication";

const Authentication = () => {
    const {t} = useTranslation();
    const {token} = useParams()
    const {authenticate} = useAppAuthentication();

    useEffect(() => {
        if (token) authenticate(token)
    }, [token, authenticate])

    return <div className='oc-flex oc-flex--center is-static-page'>
        <div className="cc-card cc-card--static">
            <img className="mc-logo__image--static" src={ logoDesktop } alt={"Logo Comitium Advertising"} />
            <p>{t('verifying your access credentials')}</p>
            <Loading/>
        </div>
    </div>
}

export default Authentication