import {createContext, useCallback, useContext, useState} from "react";

export interface PaginationContextInterface {
    getRowsPerPage: (key: string) => number;
    updateRowsPerPage: (key: string, value: number) => void;
}

export const PaginationContext = createContext<PaginationContextInterface>({} as PaginationContextInterface);

const usePaginationContext = () => useContext(PaginationContext);

export const usePaginationContextData = (): PaginationContextInterface => {
    const [
        rowsPerPageOptions,
        setRowsPerPageConf
    ] = useState<{ [key: string]: number }>(initialConf);

    const getRowsPerPage = useCallback((key: string) => {
        return rowsPerPageOptions[key] ?? null
    },[rowsPerPageOptions]);

    const updateRowsPerPage = useCallback((key: string, value: number) => {
        setRowsPerPageConf(prevConf => ({
            ...prevConf,
            [key]: value // Add or update the specified key with the given value
        }));
    },[setRowsPerPageConf]);

    return {
        getRowsPerPage,
        updateRowsPerPage
    };
};

const initialConf = { default: 25 }

export default usePaginationContext;