import {HeadCell, TableDataEnvironment} from "../../../../domain/TableData";
import {useTranslation} from "react-i18next";
import useDates from "../../../../hooks/use-dates";
import {useMemo} from "react";
import {EnvironmentsData} from "../../../../domain/StatsData";
import EnvironmentsEnhancedTableRow from "./EnvironmentsEnhancedTableRow";
import EnhancedTableDimensions from "../../Common/Table/EnhancedTableDimensions";
import EnvironmentsTotalsRow from "./EnvironmentsTotalsRow";
import usePaginationContext from "../../../../hooks/context/use-pagination-context";
import {ErrorComponent} from "../../../Common/ErrorComponent";
import * as React from "react";

const headCells: readonly HeadCell<TableDataEnvironment>[] = [
    {
        id: 'datetime',
        numeric: true,
        disablePadding: false,
        label: 'day',
        sortable: true,
    },
    {
        id: 'web',
        numeric: true,
        disablePadding: false,
        label: 'Web',
        sortable: true,
    },
    {
        id: 'amp',
        numeric: true,
        disablePadding: false,
        label: 'AMP',
        sortable: true,
    },
    {
        id: 'other',
        numeric: true,
        disablePadding: false,
        label: 'other',
        sortable: true,
    },
    {
        id: 'total',
        numeric: true,
        disablePadding: false,
        label: 'Total',
        sortable: true,
    },
];

interface Props {
    data: {
        days: EnvironmentsData[]
        summary: EnvironmentsData
    }
}

const EnvironmentsTableData = ({data} : Props) =>{
    const {t, i18n} = useTranslation();
    const {getDayName} = useDates()
    const paginationContextData = usePaginationContext();
    const days = data?.days;
    const totals = data?.summary;

    const rows: TableDataEnvironment[] = useMemo(() =>  days ? Object.entries(days).map(([dateStr, day]) => {
        const date =  new Date(dateStr);
        const stat : EnvironmentsData = day;

        return {
            datetime: date.getTime(),
            dayWeek: getDayName(date, i18n.language),
            web: stat.web,
            amp: stat.amp,
            other: stat.other,
            total: stat.total
        }
    }
    ) : []
    , [days, getDayName, i18n.language])

    if (0 === rows.length) {
        return <ErrorComponent title={t("no-results")} subtitle={t("try to modify the active filters to get results")} />
    }

    return(
        <EnhancedTableDimensions
            headCells={headCells}
            rows={rows}
            totals={totals}
            RowComponent={EnvironmentsEnhancedTableRow}
            TotalsComponent={EnvironmentsTotalsRow}
            paginationSettings={
                {
                    key: 'stats',
                    rowsPerPage: paginationContextData.getRowsPerPage('stats') ?? 25,
                    rowsPerPageOptions: [25, 50, 75, 100, { label: t('all'), value: -1 }]
                }
            }
        />
    )
}

export default EnvironmentsTableData;