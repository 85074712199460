import {useEffect, useMemo, useState} from "react";
import {InvoiceDetailsData} from "../domain/InvoiceData";
import ApiClient from "../client/ApiClient";

interface Response {
    invoicesDetails: InvoiceDetailsPendingPayment[]
    loading: boolean
}


interface InvoiceDetailsPendingPayment extends InvoiceDetailsData {
    invoice: {
        id: number
        company_name: string
        start_period: string
        end_period: string
    }
}

const useInvoiceDetailsPendingPayment = () :Response => {
    const [loading, setLoading] = useState(true)
    const client = useMemo(() => new ApiClient(), []);
    const [invoiceDetails, setinvoiceDetails] = useState<InvoiceDetailsPendingPayment[]>([]);

    useEffect(() => {
        setLoading(true)
        client
            .getInvoiceDetailsPendingPayment()
            .then((response) => {
                const data: InvoiceDetailsPendingPayment[] = response.data;
                setinvoiceDetails(data.map((d) => ({
                    ...d,
                    invoice_id: d.invoice.id,
                    invoice_company_name: d.invoice.company_name,
                    invoice_start_period: new Date(d.invoice.start_period),
                    invoice_end_period: new Date(d.invoice.end_period),
                })))
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });

    }, [client, setLoading])

    return {
        invoicesDetails: invoiceDetails,
        loading
    };
}

export default useInvoiceDetailsPendingPayment;