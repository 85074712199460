import {useTranslation} from "react-i18next";
import useFiltersContext from "../../../../hooks/context/use-filters-context";
import {FormatsChartData} from "../../../../domain/ChartData";
import {useMemo} from "react";
import {DevicesData, EnvironmentsData, FormatsData} from "../../../../domain/StatsData";
import Heading from "../../../Common/Heading";
import BarMainChart from "../../../Common/Chart/BarMainChart";

interface Props {
    data: FormatsData[]|EnvironmentsData[]|DevicesData[]
}

const FormatsChart = ({data}: Props) =>{
    const {t } = useTranslation();
    const {dateRange} = useFiltersContext();

    const chartData: FormatsChartData[] = useMemo(() =>  data ? Object.entries(data).map(([dateStr, day]) => {
        const stat : FormatsData = day;
        
        return {
            date: dateStr,
            native: stat.native.income_dashboard,
            display: stat.display.income_dashboard,
            video: stat.video.income_dashboard,
            inimage: stat.inimage.income_dashboard,
            skin: stat.skin.income_dashboard,
            other: stat.other.income_dashboard
        }
    }
    ).reverse() : []
    , [data])

    return (
        <>
            {
                chartData.length > 0 &&
                <div className="cc-card cc-chart cc-chart__main">
                    <Heading title={t('formats')} text={t(dateRange.label)}/>
                    <BarMainChart chart={chartData}/>
                </div>
            }
        </>
    );

}

export default FormatsChart;