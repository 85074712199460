import {
    utGetFirstDayOfMonth,
    utGetLastDayOfMonth,
    utFormatDate,
    utFormatDateCalendarPicker,
    utFormatDatePeriod,
    utGetDayName,
    utGetMonthName,
    utGetYear,
    utIsValidDate
} from "../utils/date";
import {useMemo} from "react";

const useDates = () => {

    const getFirstDayOfMonth = (date: Date|null) => {
        return utGetFirstDayOfMonth(date);
    };

    const getLastDayOfMonth = (date: Date|null) => {
        return utGetLastDayOfMonth(date);
    };

    const getFirstDayTime = (date: Date) => {
        return new Date(date.setHours(0, 0, 0, 0));
    }

    const getLastDayTime = (date: Date) => {
        return new Date(date.setHours(23, 59, 59, 999));
    }

    function getDayName(date: Date|null, locale = 'es-ES'): string
    {
        return utGetDayName(date, locale);
    }

    function getMonthName(date: Date|null, locale = 'es-ES'): string
    {
        return utGetMonthName(date, locale);
    }

    function getYear(date: Date|null, locale = 'es-ES'): string
    {
        return utGetYear(date, locale);
    }

    const isValidDate = (text: string) : boolean => {
        return utIsValidDate(text);
    }

    const formatDate = (date: Date|null, locale = 'es-ES') : string => {
        return utFormatDate(date, locale);
    }

    const formatDatePeriod = (date: Date|null, locale = 'es-ES') : string => {
        return utFormatDatePeriod(date, locale);
    }

    const formatDateCalendarPicker = useMemo(() => (date: Date|null) : string => {
        return utFormatDateCalendarPicker(date);
    },[]);

    const formatChartDate = (date: Date|null, locale = 'es-ES') : string => {
        return utFormatDate(date, locale,{
            day: 'numeric',
            month: 'numeric'
        });
    }

    const convertStringToDate = (text: string) => {
        if (utIsValidDate(text)) {
            const [day, month, year] = text.split('/');

            return new Date(Number(year), Number(month) - 1, Number(day));
        }

        return null
    }


    return {
        getFirstDayOfMonth,
        getLastDayOfMonth,
        convertStringToDate,
        getFirstDayTime,
        getLastDayTime,
        formatDate,
        formatDateCalendarPicker,
        formatDatePeriod,
        formatChartDate,
        getDayName,
        getYear,
        getMonthName,
        isValidDate
    }
}

export default useDates;