import {
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    CartesianGrid
} from "recharts";
import {GeneralChartData} from "../../../domain/ChartData";
import Loading from "../Loading/Loading";
import * as React from "react";
import {useTranslation} from "react-i18next";
import useNumbers from "../../../hooks/use-numbers";
import Heading from "../Heading";
import CustomizedXAxis from "./CustomizedXAxis";
import useFiltersContext from "../../../hooks/context/use-filters-context";
import CustomizedLegend from "./CustomizedLegend";
import GeneralChartTooltip from "./GeneralChartTooltip";

import { chartColors } from "./ChartColors";
import css from '../../../sass/01.settings/_settings.module.scss';

interface Props {
    chart: GeneralChartData[]|[]
}

const LineMainChart = ({chart}: Props) => {
    const {i18n, t} = useTranslation();
    const {formatCompactNumber} = useNumbers();
    const {dateRange} = useFiltersContext();

    if (chart.length === 0) {
        return <div className="cc-chart cc-chart__main">
            <Loading />
        </div>
    }

    return <div className="cc-card cc-chart cc-chart__main">
        <Heading title={t('evolution')} text={t(dateRange.label)} />
        <ResponsiveContainer width="100%" height={300}>
            <LineChart
                width={500}
                height={300}
                data={chart}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis dataKey="date" interval={0} tick={({ x, y, payload })  => <CustomizedXAxis x={x} y={y} payload={payload}/>} axisLine={{ stroke: css.coStatStroke }} tickLine={{ stroke: css.coStatStroke }}  />
                <YAxis tickFormatter={(tick) => formatCompactNumber(tick, i18n.language)} orientation="left" yAxisId="1" tickCount={8} axisLine={{ stroke: css.coStatStroke }} tickLine={{ stroke: css.coStatStroke }} />
                <YAxis width={0} height={0} type="number" yAxisId="2" />
                <YAxis width={0} height={0} type="number" yAxisId="3" />
                <YAxis width={0} height={0} type="number" yAxisId="4" />
                <Tooltip content={({ active, payload, label }) => <GeneralChartTooltip active={active} payload={payload} label={label} chartColors={chartColors.general}/>} />
                <Legend align="left" iconType="circle" content={({payload}) =>  <CustomizedLegend payload={payload as any} />}/>
                <Line yAxisId="1" type="monotone" dataKey="impressions" stroke={chartColors.general.impressions} strokeWidth={2} />
                <Line yAxisId="2" type="monotone" dataKey="income" stroke={chartColors.general.income} strokeWidth={2} />
                <Line yAxisId="3" type="monotone" dataKey="ecpm" stroke={chartColors.general.ecpm} strokeWidth={2} />
                <Line yAxisId="4" type="monotone" dataKey="viewability" stroke={chartColors.general.viewability} strokeWidth={2} />
                <CartesianGrid vertical={false} horizontal={true} stroke={css.coStatStrokeLight} />
            </LineChart>
        </ResponsiveContainer>
    </div>
}

export default LineMainChart;