import {
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowId, GridRowModel,
    GridRowModes,
    GridRowModesModel
} from "@mui/x-data-grid";
import {useCallback, useEffect, useState} from "react";
import ApiClient from "../../client/ApiClient";
import {useSnackbar} from "./use-snackbar";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {useTranslation} from "react-i18next";
import useDates from "../use-dates";
import {RevenueShareData} from "../../domain/RevenueShareData";
import {Subsite} from "../../domain/Subsite";
import {format} from "date-fns";
import {Partner} from "../../domain/Partner";

interface RowGridData extends RevenueShareData{
    isNew: boolean;
}

export const useRevenueShareGrid = (
    subsites: Subsite[],
    partners: Partner[],
    revenueShares: RevenueShareData[],
    allowEditingActions?: boolean
) => {

    const {t, i18n} = useTranslation();
    const {formatDate} = useDates();
    const {snackbar, showSnackbar, closeSnackbar} = useSnackbar();
    const [rows, setRows] = useState<RowGridData[]>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [open, setOpen] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number>(0);

    useEffect(() =>{
        const initialRows: RowGridData[] = revenueShares.map((revenueShare)=> {
            return {
                ...revenueShare,
                isNew: false
            }
        })
        setRows(initialRows)
    },[revenueShares])

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            type: 'number',
            width: 80,
            align: 'left',
            headerAlign: 'left',
            editable: false,
        },
        {
            field: 'project',
            headerName: t('project'),
            type: 'singleSelect',
            width: 240,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            renderCell(params) {
                return params.row.project?.domain;
            },
            valueGetter: ({ value }) => {
                return value?.id ?? 0
            },
            getOptionValue: (value: any) => value.id,
            getOptionLabel: (value: any) => value.domain,
            valueOptions: subsites,
        },
        {
            field: 'partner',
            headerName: t('partner'),
            type: 'singleSelect',
            width: 240,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            renderCell(params) {
                return params.row.partner?.name;
            },
            valueGetter: ({ value }) => {
                return value?.id ?? 0
            },
            getOptionValue: (value: any) => value.id,
            getOptionLabel: (value: any) => value.name,
            valueOptions: partners,
        },
        {
            field: 'start_date',
            headerName: t('start-date'),
            type: 'date',
            width: 120,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            valueFormatter: ({ value }) => formatDate(value, i18n.language),
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: 'end_date',
            headerName: t('end-date'),
            type: 'date',
            width: 120,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            valueFormatter: ({ value }) => formatDate(value, i18n.language),
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: 'revenue_share',
            headerName: t('revenue-share'),
            type: 'number',
            width: 80,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            valueGetter: ({ value }) => value && parseFloat(value),
        },
    ];

    if (allowEditingActions) {
        columns.push(
            {
                field: 'actions',
                type: 'actions',
                headerName: t('actions'),
                width: 100,
                cellClassName: 'actions',
                getActions: ({ id }) => {
                    const isInEditMode = (rowModesModel[id]?.mode === GridRowModes.Edit);

                    if (isInEditMode) {
                        return allowEditingActions ? [
                            <GridActionsCellItem
                                key={`save-${id}`}
                                icon={<SaveIcon />}
                                label="Save"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={handleSaveClick(id)}
                            />,
                            <GridActionsCellItem
                                key={`cancel-${id}`}
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id)}
                                color="inherit"
                            />,
                        ] : [];
                    }

                    return allowEditingActions ? [
                        <GridActionsCellItem
                            key={`edit-${id}`}
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={handleEditClick(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            key={`delete-${id}`}
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => setDeleteId(Number(id))}
                            color="inherit"
                        />,
                    ] : [];
                },
            }
        )
    }

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find(row => row.id === id);
        if (editedRow && editedRow.isNew) {
            setRows(rows.filter(row => row.id !== id));
        }
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleProcessRowUpdateError = useCallback((error: Error) => {
        showSnackbar(error.message, 'error');
    }, [showSnackbar]);


    const deleteRow = useCallback (async (id: number) =>
    {
        const client = new ApiClient();
        try{
            const response = await client.deleteRevenueShare(id);
            return response.data;
        } catch (error: any) {
            showSnackbar(error.response.data.message, 'error');
        }
    },[showSnackbar]);

    const handleDelete = useCallback(
        async () => {
            const response = await deleteRow(deleteId);

            if (response) {
                setRows(rows.filter(row => row.id !== deleteId));
                showSnackbar(t('row successfully deleted'),'success');
            }

            setDeleteId(0);
        },[deleteRow, rows, setRows, showSnackbar, deleteId, t]);

    const onConfirmDelete = useCallback(async (confirm: boolean) => {
        setOpen(false);

        if (confirm){
            await handleDelete();
        }else{
            setDeleteId(0);
        }
    }, [handleDelete, setDeleteId])

    useEffect(() => {
        setOpen(deleteId > 0);
    }, [deleteId]);

    const persistRow = useCallback(async (workingRow: GridRowModel, action: 'save' | 'update') =>
    {
        const client = new ApiClient();
        try {
            const parameters = {
                project_id: workingRow.project,
                partner_id: workingRow.partner,
                start_date: workingRow.start_date ? format(workingRow.start_date, 'yyyy-MM-dd') : '',
                end_date: workingRow.end_date ? format(workingRow.end_date, 'yyyy-MM-dd') : '',
                revenue_share: workingRow.revenue_share,
            };

            if (action === 'save') {
                const response = await client.createRevenueShare({
                    ...parameters,
                });
                return response.data;
            }
            if (action === 'update') {
                const response = await client.updateRevenueShare(workingRow.id, parameters);
                return response.data;
            }
        } catch (error: any) {
            showSnackbar(error.response.data.message, 'error');
        }
    }, [showSnackbar]);

    const processRowUpdate = useCallback(
        async (workingRow: GridRowModel, oldRow: GridRowModel) => {
            if (workingRow.isNew) {
                const response = await persistRow(workingRow, 'save');
                if (response) {
                    const processedRow =
                        {
                            ...workingRow,
                            id: response.id,
                            project: subsites.find((s: any) => s.id === response.project.id),
                            partner: partners.find((p: any) => p.id === response.partner.id),
                            start_date: response.start_date,
                            end_date: response.end_date,
                            revenue_share: response.revenue_share,
                            isNew: false
                        };
                    setRows(rows.map((row: any) => (row.id === workingRow.id ? processedRow : row)));
                    showSnackbar(t('row successfully saved'), 'success');
                    return processedRow;
                }

                return oldRow;
            } else {
                const normalizedOldRow = {
                    ...oldRow,
                    project: subsites.find((s: any) => s.id === oldRow.project.id),
                    partner: partners.find((p: any) => p.id === oldRow.partner_id), // Here it works with 'partner_id'
                    start_date: oldRow.start_date,
                    end_date: oldRow.end_date,
                    revenue: oldRow.revenue,
                }

                if (JSON.stringify(workingRow) === JSON.stringify(normalizedOldRow)) {
                    showSnackbar(t('row remain unchanged'), 'info');

                    return oldRow;
                }

                const response = await persistRow(workingRow, 'update');
                if (response) {
                    const processedRow = {
                        ...workingRow,
                        id: response.id,
                        project: subsites.find((s: any) => s.id === response.project.id),
                        partner: partners.find((p: any) => p.id === response.partner.id),
                        start_date: response.start_date,
                        end_date: response.end_date,
                        revenue: response.revenue,
                        isNew: false
                    };

                    setRows(rows.map((row: any) => (row.id === workingRow.id ? processedRow : row)));
                    showSnackbar(t('row successfully updated'), 'success');
                    return processedRow;
                }

                return oldRow;
            }
        }, [persistRow, rows, setRows, showSnackbar, subsites, partners, t]);

    return{
        handleRowEditStop,
        handleRowModesModelChange,
        handleProcessRowUpdateError,
        processRowUpdate,
        onConfirmDelete,
        rows,
        columns,
        setRows,
        rowModesModel,
        setRowModesModel,
        deleteDialog: open,
        snackbar,
        closeSnackbar,
    }
}