import {useEffect, useMemo, useState} from "react";
import {InvoiceGroup} from "../domain/InvoiceGroup";
import ApiClient from "../client/ApiClient";

interface Response {
    invoiceGroups: InvoiceGroup[],
    loading: boolean
}

const useBackOfficeInvoiceGroupsData = () : Response => {
    const [loading, setLoading] = useState(true)
    const client = useMemo(() => new ApiClient(), [])
    const [invoiceGroups, setInvoiceGroups] = useState<InvoiceGroup[]>([]);

    useEffect(() =>{
        setLoading(true);
        client
            .getAllInvoiceGroupsBackOffice()
            .then((response) => {
                setInvoiceGroups(response.data)
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [client, setInvoiceGroups, setLoading])

    return {
        invoiceGroups,
        loading
    }
}

export default useBackOfficeInvoiceGroupsData;