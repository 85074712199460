import Filters from "../../../components/Common/Filters/Filters";
import useFiltersContext from "../../../hooks/context/use-filters-context";
import { StatsContext } from "../../../hooks/context/use-stats-context";
import useAppDimensionsData from "../../../hooks/use-app-dimensions-data";
import DevicesChart from "../../../components/App/Stats/Devices/DevicesChart";
import DevicesTableData from "../../../components/App/Stats/Devices/DevicesTableData";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import {DevicesData} from "../../../domain/StatsData";

const ListDevicesData = () => {
    const filtersContextData = useFiltersContext();
    const {data: advertingDimensionsStatsData , loading} = useAppDimensionsData(
        filtersContextData.dateRange,
        'devices'
    )

    if (loading) {
        return <LoadingFull/>
    }

    const daysData = advertingDimensionsStatsData?.days || [];
    const summaryData = advertingDimensionsStatsData?.summary || [];

    return (
        <>
            <StatsContext.Provider value={{dimensionData: advertingDimensionsStatsData}}>
                <Filters/>
                <DevicesChart data={daysData}/>
                <DevicesTableData data={
                    {
                        days: daysData as DevicesData[],
                        summary: summaryData as DevicesData
                    }
                }
                />
            </StatsContext.Provider>
        </>
    );
}
export default ListDevicesData