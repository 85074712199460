import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DatePickerYearMonth from "../../Common/DateFilter/DatePickerYearMonth";
import useFiltersContext from "../../../hooks/context/use-filters-context";
import useDates from "../../../hooks/use-dates";

const FilterInvoiceDate = () => {
    const {dateRangeInvoices, setDateRangeInvoices} = useFiltersContext();
    const {getFirstDayOfMonth, getLastDayOfMonth} = useDates()
    const {t} = useTranslation()

    const endOfMonth = useMemo(() => {
        return getLastDayOfMonth(new Date())
    },[getLastDayOfMonth])

    const handleFromDate = useCallback((value: any) => {
        setDateRangeInvoices(prevState => ({
            ...prevState,
            start: value ? getFirstDayOfMonth(value) : null
        }));
    },[setDateRangeInvoices, getFirstDayOfMonth])

    const handleToDate = useCallback((value: any) => {
        setDateRangeInvoices(prevState => ({
            ...prevState,
            end: value ? getLastDayOfMonth(value) : null
        }));
    },[setDateRangeInvoices, getLastDayOfMonth])
    
    
    return (
        <>
            <DatePickerYearMonth onChange={handleFromDate} label={t('from')} defaultValue={dateRangeInvoices?.start} maxDate={new Date()}/>
            <DatePickerYearMonth onChange={handleToDate} label={t('to')} defaultValue={dateRangeInvoices?.end} minDate={dateRangeInvoices?.start} maxDate={endOfMonth}/>
        </>
    );

};

export default FilterInvoiceDate;