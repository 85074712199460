import {useTranslation} from "react-i18next";
import useFiltersContext from "../../../../hooks/context/use-filters-context";
import {useMemo} from "react";
import {EnvironmentsChartData} from "../../../../domain/ChartData";
import {DevicesData, EnvironmentsData, FormatsData} from "../../../../domain/StatsData";
import Heading from "../../../Common/Heading";
import BarMainChart from "../../../Common/Chart/BarMainChart";

interface Props {
    data: FormatsData[]|EnvironmentsData[]|DevicesData[]
}

const EnvironmentsChart = ({data}: Props) =>{
    const {t } = useTranslation();
    const {dateRange} = useFiltersContext();

    const chartData: EnvironmentsChartData[] = useMemo(() =>  data ? Object.entries(data).map(([dateStr, day]) => {
        const stat : EnvironmentsData = day;

        return {
            date: dateStr,
            web: stat.web.income_dashboard,
            amp: stat.amp.income_dashboard,
            other: stat.other.income_dashboard,
        }
    }
    ).reverse() : []
    , [data])

    return (
        <>
            {
                chartData.length > 0 &&
                <div className="cc-card cc-chart cc-chart__main">
                    <Heading title={t('environments')} text={t(dateRange.label)}/>
                    <BarMainChart chart={chartData}/>
                </div>
            }
        </>
    );
}

export default EnvironmentsChart;