import React, { useState } from 'react';

interface SimpleTooltipProps {
  content: string;
  children: React.ReactNode;
}

const SimpleTooltip = ({ content, children }: SimpleTooltipProps) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className="mc-tooltip"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}>
            {children}
            {showTooltip && <div className="mc-tooltip__content">{content}</div>}
        </div>
    );
};

export default SimpleTooltip;
