import {useCallback, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import {InvoiceState} from "../domain/State";
import {InvoiceData} from "../domain/InvoiceData";

const useInvoiceStates = () => {
    const [loading, setLoading] = useState(false);
    const client = useMemo(() => new ApiClient(), [])
    const [reachableInvoiceStates, setReachableInvoiceStates] = useState<InvoiceState[]>([]);

    function getCssClass(state: string): string {
        switch(state) {
        case 'Pending Review':
            return 'cc-fra--in-waiting';
        case 'Pending Accumulation':
            return 'cc-fra--waiting';
        case 'Pending Payment':
            return 'cc-fra--in-progress';
        case 'Pending Reception':
            return 'cc-fra--waiting';
        case 'Partial Paid':
            return 'cc-fra--waiting';
        case 'Paid':
            return 'cc-fra--completed';
        default:
            return state;
        }
    }

    const getReachableInvoiceState = useCallback(async (stateId: number) => {
        try {
            const response = await client.getReachableInvoiceStates(stateId)

            setReachableInvoiceStates(response.data);
        } catch (exception) {
            setReachableInvoiceStates([])
        }
    }, [client, setReachableInvoiceStates])

    const changeInvoiceState = useCallback(async (invoiceId: number, stateId: number, sendEmail: boolean) => {
        setLoading(true)
        const response = await client.changeInvoiceState(invoiceId, stateId, sendEmail)
        setLoading(false)
        return response.data as InvoiceData;
    }, [client])

    return {
        getCssClass,
        getReachableInvoiceState,
        reachableInvoiceStates,
        changeInvoiceState,
        loading
    }
}

export default useInvoiceStates;