import * as React from "react";
import useNumbers from "../../../../hooks/use-numbers";
import {useTranslation} from "react-i18next";
import IconGeneric from "../../../Common/Icon/IconGeneric";
import SimpleTooltip from "../../../Common/SimpleTooltip";

interface CellDimensionData {
    income_dashboard: number;
    impressions_dashboard: number;
    ecpm: number;
}

interface Props {
    data: CellDimensionData
}

const EnhancedTableDimensionsCell = ({data}: Props) =>
{
    const {i18n, t} = useTranslation();
    const {formatCurrency, formatNumber } = useNumbers();

    return (
        <>
            <span className={'mc-data'}>{formatCurrency(data?.income_dashboard, i18n.language)}</span>
            {data && data.income_dashboard > 0 &&
                <>
                    <p className={'mc-data mc-data--views'}>
                        <SimpleTooltip content={t('displays')}>
                            <IconGeneric iconName="view-ico" className="mc-icon mc-icon--admin mc-icon__generic--size-14"/>
                            {formatNumber(data.impressions_dashboard, i18n.language)}
                        </SimpleTooltip>
                    </p>
                    <p className={'mc-data mc-data--ecpm'}>
                        <SimpleTooltip content={t('ecpm')}>
                            <IconGeneric iconName="revenue-ico" className="mc-icon mc-icon--admin mc-icon__generic--size-12"/>
                            {formatCurrency(data.ecpm, i18n.language)}
                        </SimpleTooltip>
                    </p>
                </>
            }
        </>
    )
}

export default EnhancedTableDimensionsCell;