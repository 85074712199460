import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CustomSelectProps {
    locales: string[];
    selectedLanguage: string;
    onChange: (language: string) => void;
}

function CustomSelect({ locales, selectedLanguage, onChange }: CustomSelectProps) {
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (language: string) => {
        setIsOpen(false);
        onChange(language);
    };

    return (
        <div className="mc-button mc-button--secondary mc-button--no-border cc-header__lang">
            <div className={`cc-form__select ${isOpen ? 'is-open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                {selectedLanguage.toUpperCase()}
            </div>
            {isOpen && (
                <ul className="cc-form__select-options">
                    {locales.map((language) => (
                        <li
                            key={language}
                            className={`cc-form__select-item ${language === selectedLanguage ? 'is-active' : ''}`}
                            onClick={() => handleSelect(language)}
                        >
                            {language.toUpperCase()}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

function LanguageSelector() {
    const { i18n } = useTranslation();
    const locales = Object.keys(i18n.options.resources ?? {});

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    return (
        <CustomSelect
            locales={locales}
            selectedLanguage={i18n.language}
            onChange={changeLanguage}
        />
    );
}

export default LanguageSelector;
