import { useTranslation } from "react-i18next";
import Heading from "../../../components/Common/Heading";
import DateSelector from "../../../components/Common/DateFilter/DateSelector";
import useBackOfficeComparedStatsData from "../../../hooks/use-backoffice-compared-stats";
import useFiltersContext from "../../../hooks/context/use-filters-context";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import PerformanceTableDataBackOffice from "../../../components/BackOffice/Stats/Performance/PerformanceTableDataBackOffice";

const PerformanceBackOffice = () => {
    const { t } = useTranslation();
    const filtersContextData = useFiltersContext();
    const { stats, loading } = useBackOfficeComparedStatsData(filtersContextData.dateRange);

    if (loading) {
        return <LoadingFull />;
    }

    return (
        <>
            <Heading title={t("performance")} />
            <DateSelector />
            <PerformanceTableDataBackOffice data={stats} />
        </>
    );
};

export default PerformanceBackOffice;
