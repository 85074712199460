import useBackofficeFilters from "../../../hooks/filters/use-backoffice-filters";
import useFiltersContext from "../../../hooks/context/use-filters-context";
import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";
import {Subsite} from "../../../domain/Subsite";

const FiltersBackOffice = () => {
    const {devices, environments, partners, subsites} = useBackofficeFilters()
    const {
        filteredSubsites, setFilteredSubsites,
        filteredPartners, setFilteredPartners,
        filteredDevices, setFilteredDevices,
        filteredEnvironments, setFilteredEnvironments
    } = useFiltersContext();

    return (
        <>
            <MultiSelectFilter
                name={'subsites'}
                values={subsites.map((ss: Subsite) => {
                    return {
                        id: ss.id,
                        name: ss.domain
                    }
                })}
                filteredValues={filteredSubsites}
                setFilteredValues={setFilteredSubsites}
            />
            <MultiSelectFilter
                name={'partners'}
                values={partners}
                filteredValues={filteredPartners}
                setFilteredValues={setFilteredPartners}
            />
            <MultiSelectFilter
                name={'devices'}
                values={devices}
                filteredValues={filteredDevices}
                setFilteredValues={setFilteredDevices}
            />
            <MultiSelectFilter
                name={'environments'}
                values={environments}
                filteredValues={filteredEnvironments}
                setFilteredValues={setFilteredEnvironments}
            />
        </>
    );
};

export default FiltersBackOffice;
