import {useCallback, useState} from "react";

import usePaginationContext from "../context/use-pagination-context";
import {GridPaginationModel} from "@mui/x-data-grid";

export const usePaginationGrid = (
    paginationSettings?:{key: string, rowsPerPage: number}
) => {
    const {updateRowsPerPage} = usePaginationContext();
    const [rowsPerPage, setRowsPerPage] = useState(paginationSettings?.rowsPerPage);

    const handlePageSizeChange = useCallback((params: GridPaginationModel) => {
        if(paginationSettings){
            const updatedValue = params.pageSize
            setRowsPerPage(updatedValue);
            updateRowsPerPage(paginationSettings.key, updatedValue);
        }
    },[updateRowsPerPage, paginationSettings]);

    return {
        rowsPerPage,
        handlePageSizeChange
    }
}