interface Props {
    title: string;
    value: string;
}

const GeneralViewCard = ({title, value}: Props) => {
    return(
        <div className="cc-card">
            <span className="cc-card__title">{title}</span>
            <div className="oc-group oc-group--no-gap">
                <span className="cc-card__value">{value}</span>
            </div>
        </div>
    )
}

export default GeneralViewCard