import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { useTranslation } from "react-i18next";
import {useMemo} from "react";
import {HeadCell, HistoricalDataValues, HistoricalDataLine} from "../../../domain/TableData";
import { TableFooter } from "@mui/material";
import HistoricalEnhancedTableRowBackOffice from "./HistoricalEnhancedTableRowBackOffice";
import HistoricalTotalsRowBackOffice from "./HistoricalTotalsRowBackOffice";
import HistoricalEnhancedTableHeadBackOffice from "./HistoricalEnhancedTableHeadBackOffice";
import usePaginationEnhancedTable from "../../../hooks/ui/use-pagination-enhanced-table";
import {ErrorComponent} from "../../Common/ErrorComponent";

interface EnhancedTableProps {
    headCells: readonly HeadCell<HistoricalDataLine>[],
    rows: HistoricalDataLine[]
    totals: HistoricalDataValues
    paginationSettings?: { key: string, rowsPerPage: number, rowsPerPageOptions: any }

}

export default function HistoricalEnhancedTableBackOffice(props: EnhancedTableProps) {
    const { headCells, rows, totals, paginationSettings } = props;
    const { t } = useTranslation();
    const {
        defaultLabelDisplayedRows,
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        rowsPerPage,
    } = usePaginationEnhancedTable(paginationSettings);

    const visibleRows = useMemo(
        () => {
            if (rowsPerPage === -1) {
                return rows;
            } else {
                return rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                );
            }
        },
        [rows, page, rowsPerPage],
    );

    if (0 === rows.length) {
        return <ErrorComponent title={t("no-results")} subtitle={t("try to modify the active filters to get results")} />
    }

    return (
        <>
            <div className='cc-table cc-card'>
                <TableContainer>
                    <Table aria-labelledby="tableTitle" sx={{ minWidth: 750 }}>
                        {rows.length > 0 && <HistoricalEnhancedTableHeadBackOffice
                            headCells={headCells}
                            rowCount={rows.length}
                        />
                        }
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const labelId = `enhanced-table-row-${index}`;
                                return (
                                    <HistoricalEnhancedTableRowBackOffice id={labelId} key={labelId} row={row} />
                                );
                            })}
                            <HistoricalTotalsRowBackOffice totals={totals} />
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {visibleRows.length > 0 && <TableFooter>
                <TablePagination className='cc-table__pagination'
                    rowsPerPageOptions={paginationSettings?.rowsPerPageOptions ?? [12, 24, 36, { label: t('all'), value: -1 }]}
                    colSpan={3}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={defaultLabelDisplayedRows}
                    labelRowsPerPage={t('rows-per-page')}
                />
            </TableFooter>
            }
        </>
    );
}
