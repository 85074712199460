import {createContext, Dispatch, SetStateAction,  useContext, useState} from "react";
import {DateRange} from "../../domain/DateRange";
import {FilterOption} from "../../domain/FilterOption";
import useDates from "../use-dates";

export interface FiltersRevenueShareContextInterface {
    dateRange: DateRange
    setDateRange: Dispatch<SetStateAction<DateRange>>,
    filteredSubsites: FilterOption[]
    setFilteredSubsites: Dispatch<SetStateAction<FilterOption[]>>
}

export const FiltersRevenueShareContext = createContext<FiltersRevenueShareContextInterface>({} as FiltersRevenueShareContextInterface)

const useFiltersRevenueShareContext = () => useContext(FiltersRevenueShareContext)

export const useFiltersRevenueShareContextData = (): FiltersRevenueShareContextInterface => {
    const {getFirstDayOfMonth, getLastDayOfMonth} = useDates()
    const [dateRange, setDateRange] = useState<DateRange>({
        label: 'custom',
        start: getFirstDayOfMonth(new Date()),
        end: getLastDayOfMonth((new Date()))
    })

    const [filteredSubsites, setFilteredSubsites] = useState<FilterOption[]>([]);

    return {
        dateRange,
        setDateRange,
        filteredSubsites,
        setFilteredSubsites
    }
}

export default useFiltersRevenueShareContext