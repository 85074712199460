import {useTranslation} from "react-i18next";
import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import useNumbers from "../../../../hooks/use-numbers";
import {SummaryDataBackOffice} from "../../../../domain/StatsData";

interface Props{
    totals:  SummaryDataBackOffice
}

const GeneralTotalsRowBackOffice = ({totals}: Props) => {
    const { i18n} = useTranslation();
    const {formatCurrency, formatNumber, formatPercent } = useNumbers();

    return (
        <>
            <TableRow className="cc-table__totals">
                <TableCell className="cc-table__totals-title">Total</TableCell>
                <TableCell className="cc-table__totals-data">
                    <span className={'mc-data'}>
                        {formatNumber(totals?.impressions, i18n.language, 0)}
                    </span>
                </TableCell>
                <TableCell className="cc-table__totals-data">
                    <span className={'mc-data'}>
                        {formatCurrency(totals?.income, i18n.language, 2)}
                    </span>
                </TableCell>
                <TableCell className="cc-table__totals-data">
                    <span className={'mc-data'}>
                        {formatCurrency(totals?.ecpm, i18n.language, 2)}
                    </span>
                </TableCell>
                <TableCell className="cc-table__totals-data">
                    <span className={'mc-data'}>
                        {formatPercent(totals?.viewability, i18n.language, 0)}
                    </span>
                </TableCell>
            </TableRow>
        </>
    );


}

export default GeneralTotalsRowBackOffice;