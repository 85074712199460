import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import es from "./translations/es.json";
import ca from "./translations/ca.json";
import eu from "./translations/eu.json";
import en from "./translations/en.json";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

const resources = {
    "es": {
        "translation": es
    },
    "ca": {
        "translation": ca
    },
    "en": {
        "translation": en
    },
    "eu": {
        "translation": eu
    }
};

i18n
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // lng: lang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        fallbackLng: "es",
        detection: {
            order: ['querystring', 'cookie', 'localStorage'],
            lookupQuerystring: 'lang',
        }
    });

export default i18n;