import { InvoiceLogsData } from '../../../domain/InvoiceData';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
interface Props{
    log: InvoiceLogsData;
}
const InvoiceLogs = ({log}: Props) => {

    return (
        <div className="cc-log__item">
            <span className="cc-log__icon">
                {log.email_sent 
                    ? <MarkEmailReadOutlinedIcon fontSize='small' />
                    : ''
                }
            </span>
            <span className="cc-log__date">{log.date}</span>
            <span className="cc-log__text">{log.comment}</span>
        </div>
    );
};
export default InvoiceLogs