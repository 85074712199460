import {useEffect, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import {RevenueShareData} from "../domain/RevenueShareData";
import {FilterOption} from "../domain/FilterOption";
import useDates from "./use-dates";

interface Response {
    revenueShares: RevenueShareData[]
    loading: boolean
}

interface GetDataParameters {
    start_date: string
    end_date: string
    pids?: string
}

const useBackofficeRevenueShareData = (
    start_date: Date|null,
    end_date: Date|null,
    filteredSubsites: FilterOption[],
): Response => {
    const [loading, setLoading] = useState(true)
    const {formatDateCalendarPicker} = useDates();
    const client = useMemo(() => new ApiClient(), [])
    const [revenueSharesData, setRevenueSharesData] = useState<RevenueShareData[]>(initialData)

    const parameters: GetDataParameters = useMemo(() => {
        const params = {
            start_date: formatDateCalendarPicker(start_date),
            end_date: formatDateCalendarPicker(end_date),
        }

        if (filteredSubsites.length > 0) {
            Object.assign(params, {pids: filteredSubsites.map((subsite) => subsite.id).join(',')})
        }

        return params
    }, [start_date, end_date, filteredSubsites, formatDateCalendarPicker])

    useEffect(() => {
        setLoading(true);
        client
            .getRevenueShares(parameters)
            .then((response) => {
                const data: RevenueShareData[] = response.data;
                setRevenueSharesData(data)
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [client, parameters, setLoading])

    return {
        revenueShares: revenueSharesData,
        loading
    };
}

const initialData: RevenueShareData[] = [];

export default useBackofficeRevenueShareData