import { Button } from "@mui/material";
import { GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import AddIcon from '@mui/icons-material/Add';

interface Props {
    rows: GridRowsProp;
    setRows: React.Dispatch<React.SetStateAction<GridRowsProp>>;
    setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
    allowEditingActionsToolbar?: boolean;
}

const AddItemToolbar = (props: Props) =>  {
    const {t} = useTranslation();
    const { rows, setRows, setRowModesModel, allowEditingActionsToolbar } = props;

    const getMaxId = (rows: GridRowsProp) => {
        if (rows.length === 0) {
            return 0;
        }
        
        return rows.reduce((maxId, row) => {
            return row.id > maxId ? row.id : maxId;
        }, rows[0].id);
    };
  
    const handleClick = () => {
        const id = getMaxId(rows) + 1;
        const position = rows.length + 1;
        const newRow = { id, concept: '', payment_date: null, position: position, revenue: 0, state:'Pending Payment', type: 'BackOffice', isNew: true }
        setRows((oldRows) => [
            ...oldRows,
            newRow
        ]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        }));
    };

    return (
        <>
            {allowEditingActionsToolbar && <GridToolbarContainer>
                <Button className="mc-button mc-button--primary is-small" startIcon={<AddIcon />} onClick={handleClick}>
                    {t('add-record')}
                </Button>
            </GridToolbarContainer>
            }
        </>
    );
}

export default AddItemToolbar;