import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {EnvironmentsData} from "../../../../domain/StatsData";
import EnhancedTableDimensionsCell from "../../Common/Table/EnhancedTableDimensionsCell";

interface Props{
    totals:  EnvironmentsData
}

const EnvironmentsTotalsRow = ({totals}: Props) => {

    return (
        <>
            <TableRow className="cc-table__totals">
                <TableCell className="cc-table__totals-title">Total</TableCell>
                <TableCell className="cc-table__totals-data"><EnhancedTableDimensionsCell data={totals?.web}/></TableCell>
                <TableCell className="cc-table__totals-data"><EnhancedTableDimensionsCell data={totals?.amp}/></TableCell>
                <TableCell className="cc-table__totals-data"><EnhancedTableDimensionsCell data={totals?.other}/></TableCell>
                <TableCell className="cc-table__totals-data"><EnhancedTableDimensionsCell data={totals?.total}/></TableCell>
            </TableRow>
        </>
    );


}

export default EnvironmentsTotalsRow;