import HeaderItem from "../../Common/Header/HeaderItem";
import {useTranslation} from "react-i18next";
import IconGeneric from "../../Common/Icon/IconGeneric";

const SubsiteMenuBackOffice =() => {

    const { t } = useTranslation();
    
    return <>
        <div className={"cc-header__sites"}>
            <HeaderItem>
                <span className="mc-button mc-button--secondary mc-button--no-border mc-button--header mc-button--admin">
                    <IconGeneric iconName="admin-ico" className="mc-icon mc-icon--admin mc-icon__generic--size-28"/>
                    {t("administrator mode")}
                </span>
            </HeaderItem>
        </div>
    </>
}

export default SubsiteMenuBackOffice;