import React, {useState, useRef, useCallback} from "react";
import IconGeneric from "../../Icon/IconGeneric";
import { useTranslation } from "react-i18next";
import useOutsideClick from "../../../../hooks/ui/use-outside-click";
import ClearSelectedGroupFilters from "./ClearSelectedGroupFilters";
import AppliedFilters from "./AppliedFilters";
import {FilterOption} from "../../../../domain/FilterOption";
import useMultiselectFilter from "../../../../hooks/filters/use-multiselect-filter";

interface Props {
    name: string
    values: FilterOption[]
    filteredValues: FilterOption[],
    setFilteredValues: React.Dispatch<React.SetStateAction<FilterOption[]>>
}

const MultiSelectFilter = ({name, values, filteredValues, setFilteredValues}: Props) => {
    const { t } = useTranslation();
    const [preSelectedOptions, setPreSelectedOptions ] = useState<FilterOption[]>(filteredValues);
    const {
        areAllSelected,
        handleSelectAll,
        handleSelectNone,
        handleClickMenuItem,
        removeSingleOption
    } = useMultiselectFilter(values, preSelectedOptions, setPreSelectedOptions, filteredValues, setFilteredValues)
    const [filterDropdown, setFilterDropdown] = useState(false);
    const [search, setSearch] = useState<string>("")
    const ref = useOutsideClick(() => {
        setFilterDropdown(false);
    });
    const buttonRef = useRef<HTMLDivElement | null>(null)
    const hasSelectedItems = filteredValues.length > 0;
    const itemsFilteredBySearch = values.filter((filter) => filter.name.toLowerCase().includes(search.toLowerCase()));
    const isButtonOnRight = buttonRef.current && buttonRef.current.getBoundingClientRect().left > window.innerWidth / 2;

    const toggleDropdown = useCallback(() => {
        setSearch("");
        setFilterDropdown(!filterDropdown);
    },[setSearch, setFilterDropdown, filterDropdown]);


    const apply = useCallback(() => {
        setFilteredValues(preSelectedOptions)
    },[setFilteredValues, preSelectedOptions])

    const closeDropdown = useCallback(() => {
        setSearch("");
        setFilterDropdown(false);
    },[setSearch, setFilterDropdown]);

    return (
        <>
            <div className="cc-filter__container" ref={ref}>
                <div className="cc-filter__button--wrapper">
                    <div
                        ref={buttonRef}
                        className={
                            "cc-filter__button cc-filter__button--parameter " +
                        (filterDropdown ? "is-open " : "") +
                        (hasSelectedItems ? "has-selected-items" : "") +
                        (isButtonOnRight ? "is-on-right" : "")
                        }
                        onClick={toggleDropdown}
                    >
                        <IconGeneric iconName="filters-ico" className="mc-icon__generic--size-16"/>
                        {t(name)}
                        <AppliedFilters values={filteredValues} setValues={removeSingleOption}/>
                    </div>
                    <ClearSelectedGroupFilters
                        list={filteredValues}
                        onClearAll={() => {
                            handleSelectNone();
                            closeDropdown();
                        }}
                    />
                </div>
                {filterDropdown && (
                    <>
                        <div className={"cc-filter__dropdown has-fade-in " + (isButtonOnRight ? "is-on-right" : "")}>
                            <input
                                autoFocus={true}
                                className='cc-form__input cc-form__input--search'
                                type="search"
                                value={search}
                                onChange={(event) => setSearch(event.target.value)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' && itemsFilteredBySearch.length === 1) {
                                        handleClickMenuItem(itemsFilteredBySearch.at(0));
                                    }

                                    if (event.key === 'Escape') {
                                        closeDropdown();
                                    }
                                }}
                            />

                            <div key="selectAll" className="cc-filter__group-item">
                                <input
                                    className="cc-form__checkbox"
                                    type="checkbox"
                                    id="selectAllCheckbox"
                                    checked={areAllSelected}
                                    onChange={handleSelectAll}
                                />
                                <label htmlFor="selectAllCheckbox"
                                    className="cc-form__checkbox-label cc-form__checkbox-label--toggle">
                                    <span className="cc-form__checkbox-icon"></span>
                                    {t("select all")}
                                </label>
                            </div>
                            {itemsFilteredBySearch.map((value) => (
                                <div key={value.id} className="cc-filter__group-item">
                                    <input
                                        className="cc-form__checkbox"
                                        type="checkbox"
                                        id={value.id.toString()}
                                        value={value.name}
                                        checked={preSelectedOptions.findIndex((item) => item.id === value.id) > -1}
                                        onChange={() => handleClickMenuItem(value)}
                                    />
                                    <label htmlFor={value.id.toString()} className="cc-form__checkbox-label">
                                        <span className="cc-form__checkbox-icon"></span>
                                        {value.name}
                                    </label>
                                </div>
                            ))}
                            <div className='oc-group oc-group--center'>
                                <button className='mc-button mc-button--secondary mc-button--small' onClick={() => {
                                    closeDropdown()
                                }}>
                                    {t("cancel")}
                                </button>
                                <button className='mc-button mc-button--primary mc-button--small' onClick={() => {
                                    apply()
                                    closeDropdown()
                                }}>
                                    {t("apply")}
                                </button>
                            </div>
                        </div>
                    </>
                )
                }
            </div>
        </>
    )
    ;
};

export default MultiSelectFilter;
