import Heading from "../../../Common/Heading";
import * as React from "react";
import BarMainChart from "../../../Common/Chart/BarMainChart";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import useFiltersContext from "../../../../hooks/context/use-filters-context";
import {DevicesChartData} from "../../../../domain/ChartData";
import {DevicesData, EnvironmentsData, FormatsData} from "../../../../domain/StatsData";

interface Props {
    data: FormatsData[]|EnvironmentsData[]|DevicesData[]
}

const DevicesChart = ({data} : Props) =>{
    const {t } = useTranslation();
    const {dateRange} = useFiltersContext();

    const chartData: DevicesChartData[] = useMemo(() =>  data ? Object.entries(data).map(([dateStr, day]) => {
        const stat : DevicesData = day;

        return {
            date: dateStr,
            desktop: stat.desktop.income_dashboard,
            mobile: stat.mobile.income_dashboard,
            tablet: stat.tablet.income_dashboard,
            other: stat.other.income_dashboard
        }
    }
    ).reverse() : [],  [data])

    return (
        <>
            {
                chartData.length > 0 &&
                <div className="cc-card cc-chart cc-chart__main">
                    <Heading title={t('devices')} text={t(dateRange.label)}/>
                    <BarMainChart chart={chartData}/>
                </div>
            }
        </>
    );

}

export default DevicesChart;