import {useTranslation} from "react-i18next";
import useDates from "../../../hooks/use-dates";
import * as React from "react";

interface CustomizedAxisTickProps {
    x: number;
    y: number;
    payload: {
        value: string;
    };
}

const CustomizedXAxis = ({ x, y, payload }: CustomizedAxisTickProps) => {
    const {i18n} = useTranslation();
    const {formatDate} = useDates();
    const date = new Date(payload.value);

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                {formatDate(date, i18n.language)}
            </text>
        </g>
    );
}

export default CustomizedXAxis;