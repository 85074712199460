import {useTranslation} from "react-i18next";
import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {TableDataGeneral} from "../../../../domain/TableData";
import useNumbers from "../../../../hooks/use-numbers";
import useDates from "../../../../hooks/use-dates";

const GeneralEnhancedTableRow = (props: { id: string, row: TableDataGeneral }) => {
    const { i18n} = useTranslation();
    const {formatCurrency, formatNumber, formatPercent } = useNumbers();
    const {formatDate} = useDates();
    const { row } = props;

    return (
        <>
            <TableRow
                id={row.datetime.toString()}
                hover
                tabIndex={-1}
                key={row.datetime}
            >
                <TableCell
                    scope="row"
                >
                    <div
                        className='mc-date'>{formatDate(new Date(row.datetime), i18n.language)}</div>
                    <div className='mc-date mc-date--mini'>{row.dayWeek}</div>
                </TableCell>
                <TableCell><span className={'mc-data'}>{formatNumber(row.impressions, i18n.language, 0)}</span></TableCell>
                <TableCell><span className={'mc-data'}>{formatCurrency(row.income, i18n.language)}</span></TableCell>
                <TableCell><span className={'mc-data'}>{formatCurrency(row.ecpm,  i18n.language)}</span></TableCell>
                <TableCell><span className={'mc-data'}>{formatPercent(row.viewability,  i18n.language, 0)}</span></TableCell>
            </TableRow>
        </>
    );
}

export default GeneralEnhancedTableRow;