import React, {useCallback, useState} from 'react';
import { useTranslation } from 'react-i18next';
import ProjectForm, {ProjectFormData} from "./ProjectForm";
import {Project} from "../../../domain/Subsite";
import ConfirmDialog from "../../Common/Modal/ConfirmDialog";
import BackButton from '../../Common/BackButton';

interface Props{
    project: Project;
    onSave: (formData: ProjectFormData) => void;
    onCancel: () => void;
    onDelete: (projectId: number) => void;
}

const EditProjectForm = ({ project, onSave, onCancel, onDelete }: Props) => {
    const { t } = useTranslation();
    const [isDialogOpen, setIsDialogOpen ] = useState(false);

    const projectFormData: ProjectFormData = {
        public_key: project.public_key,
        domain: project.domain,
        ad_unit: project.ad_unit,
        revenue_share: project.revenue_share,
        adunit_names: project.adunit_names,
        invoice_group_id: project.invoice_group?.id ?? null,
        date_registration: project.date_registration ?? null,
        date_unregistration: project.date_unregistration ?? null,
    };

    const handleSave = (formData: ProjectFormData) => {
        onSave(formData);
    };

    const onConfirmDelete = useCallback(async (confirm: boolean) => {
        const handleDelete = (projectId: number) => {
            onDelete(projectId);
        };

        setIsDialogOpen(false);

        if (confirm) handleDelete(project.id);
    }, [onDelete, project.id])

    return (
        <>
            <BackButton to="../.." />
            <div className='cc-card cc-card--invoice'>
                <ProjectForm
                    projectId={project.id}
                    initialValues={projectFormData}
                    onSave={handleSave}
                    btnSaveText={t('save')}
                    onCancel={onCancel}
                    onDelete={() => setIsDialogOpen(true)}
                />
            </div>
            <ConfirmDialog
                open={isDialogOpen}
                title={t('delete item')}
                message={t('delete item message')}
                onConfirm={onConfirmDelete}
            />
        </>
    );
};

export default EditProjectForm;