import {useState} from "react";
import * as React from "react";
import {LabelDisplayedRows} from "../../domain/EnhancedTablePagination";
import {useTranslation} from "react-i18next";
import usePaginationContext from "../context/use-pagination-context";

const usePaginationEnhancedTable = (paginationSettings?:{key: string, rowsPerPage: number}) =>{
    const {updateRowsPerPage} = usePaginationContext();
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(paginationSettings?.rowsPerPage ?? 25);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const updatedValue = parseInt(event.target.value, 10)
        setRowsPerPage(updatedValue);
        setPage(0);
        if(paginationSettings){
            updateRowsPerPage(paginationSettings.key, updatedValue);
        }
    };

    const defaultLabelDisplayedRows = ({ from, to, count }: LabelDisplayedRows) => {
        return `${from}–${to} ${t('of')} ${count !== -1 ? count : `more than ${to}`}`;
    }

    return {
        defaultLabelDisplayedRows,
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        setPage,
        rowsPerPage
    }
}

export default usePaginationEnhancedTable