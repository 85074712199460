import IconGeneric from "../../../Common/Icon/IconGeneric";
import {useEffect, useState} from "react";
import {ADVISE_MESSAGE_CLOSED } from "../../../../domain/Constants";
import {useTranslation} from "react-i18next";

const Advise = () => {
    const {t} = useTranslation();
    const [isClosed, setIsClosed] = useState<boolean>(false);
    const hasbeenClosed = localStorage.getItem(ADVISE_MESSAGE_CLOSED) === "1";
    
    useEffect(() => {
        setIsClosed(hasbeenClosed);
    }, [hasbeenClosed]);

    const toggleClose = () => {
        const closeFlag = !isClosed;
        localStorage.setItem(ADVISE_MESSAGE_CLOSED, closeFlag ? "1" : "0");
        setIsClosed(closeFlag);
    };

    return(
        <div className={"cc-main__advise" + (!hasbeenClosed ? "" : " is-closed")}>
            <div className={"cc-main__advise-info-ico" + (!hasbeenClosed ? "" : " is-on")} onClick={toggleClose}>i</div>
            <span className="cc-main__advise-info-text">
                {t('data may change')}
            </span>
            <IconGeneric iconName="close-ico"
                className="mc-icon mc-icon__generic--size-16 mc-icon--button cc-main__advise-close"
                onClick={toggleClose}/>
        </div>
    )
}

export default Advise;
