import {HeadCell, TableDataFormats} from "../../../../domain/TableData";
import {useTranslation} from "react-i18next";
import useDates from "../../../../hooks/use-dates";
import {useMemo} from "react";
import {FormatsData} from "../../../../domain/StatsData";
import FormatsEnhancedTableRow from "./FormatsEnhancedTableRow";
import EnhancedTableDimensions from "../../Common/Table/EnhancedTableDimensions";
import FormatsTotalsRow from "./FormatsTotalsRow";
import usePaginationContext from "../../../../hooks/context/use-pagination-context";
import {ErrorComponent} from "../../../Common/ErrorComponent";
import * as React from "react";

const headCells: readonly HeadCell<TableDataFormats>[] = [
    {
        id: 'datetime',
        numeric: true,
        disablePadding: false,
        label: 'day',
        sortable: true,
    },
    {
        id: 'native',
        numeric: true,
        disablePadding: false,
        label: 'Native',
        sortable: true,
    },
    {
        id: 'display',
        numeric: true,
        disablePadding: false,
        label: 'Display',
        sortable: true,
    },
    {
        id: 'video',
        numeric: true,
        disablePadding: false,
        label: 'Vídeo',
        sortable: true,
    },
    {
        id: 'inimage',
        numeric: true,
        disablePadding: false,
        label: 'inImage',
        sortable: true,
    },
    {
        id: 'skin',
        numeric: true,
        disablePadding: false,
        label: 'Skin',
        sortable: true,
    },
    {
        id: 'other',
        numeric: true,
        disablePadding: false,
        label: 'other',
        sortable: true,
    },
    {
        id: 'total',
        numeric: true,
        disablePadding: false,
        label: 'Total',
        sortable: true,
    },
];

interface Props {
    data: {
        days: FormatsData[]
        summary: FormatsData
    }
}

const FormatsTableData = ({data}: Props) =>{
    const {t, i18n} = useTranslation();
    const {getDayName} = useDates()
    const paginationContextData = usePaginationContext();
    const days = data?.days;
    const totals = data?.summary;

    const rows: TableDataFormats[] = useMemo(() =>  days ? Object.entries(days).map(([dateStr, day]) => {
        const date =  new Date(dateStr);
        const stat : FormatsData = day;

        return {
            datetime: date.getTime(),
            dayWeek: getDayName(date, i18n.language),
            native:  stat.native,
            display: stat.display,
            video: stat.video,
            inimage: stat.inimage,
            skin: stat.skin,
            other: stat.other,
            total: stat.total
        }
    }
    ) : []
    , [days, getDayName, i18n.language])

    if (0 === rows.length) {
        return <ErrorComponent title={t("no-results")} subtitle={t("try to modify the active filters to get results")} />
    }

    return(
        <EnhancedTableDimensions
            headCells={headCells}
            rows={rows}
            totals={totals}
            RowComponent={FormatsEnhancedTableRow}
            TotalsComponent={FormatsTotalsRow}
            paginationSettings={
                {
                    key: 'stats',
                    rowsPerPage: paginationContextData.getRowsPerPage('stats') ?? 25,
                    rowsPerPageOptions: [25, 50, 75, 100, { label: t('all'), value: -1 }]
                }
            }
        />
    )
}

export default FormatsTableData;