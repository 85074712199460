import React, {HTMLAttributes} from "react";
import iconGeneric from '../../../assets/icon-generic.svg';

interface IconGenericProps extends HTMLAttributes<SVGElement> {
  iconName: string;
}

function IconGeneric({ iconName, className = "", ...props }: IconGenericProps) {
    return (
        <svg className={`mc-icon__generic ${className}`} {...props}>
            <use href={`${iconGeneric}#${iconName}`} />
        </svg>
    );
}

export default IconGeneric;
