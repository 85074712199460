import InvoiceGroupsTableDataBackOffice
    from "../../../components/BackOffice/InvoiceGroup/InvoiceGroupsTableDataBackOffice";
import {useMemo, useState} from "react";
import {TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import Heading from "../../../components/Common/Heading";
import * as React from "react";
import {Link} from "react-router-dom";
import IconGeneric from "../../../components/Common/Icon/IconGeneric";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import useBackOfficeInvoiceGroupsData from "../../../hooks/use-backoffice-invoice-groups.data";

const ListInvoiceGroupsBackOffice = () => {
    const {t} = useTranslation();
    const [filterText, setFilterText] = useState("");
    const {invoiceGroups, loading} = useBackOfficeInvoiceGroupsData();

    const filteredInvoiceGroups = useMemo(() => {
        return invoiceGroups.filter(group =>
            group.name.toLowerCase().includes(filterText.toLowerCase()) ||
            group.company_name.toLowerCase().includes(filterText.toLowerCase()) ||
            group.tax_identifier.toLowerCase().includes(filterText.toLowerCase()) ||
            group.emails_admin.toLowerCase().includes(filterText.toLowerCase())
        );
    }, [invoiceGroups, filterText]);

    if (loading) {
        return <LoadingFull/>
    }

    return (
        <>
            <Heading title={t('invoice-groups')}/>
            <div className="cc-card cc-card--invoice">
                <div className="oc-group oc-group--justify-between">
                    <TextField
                        className="cc-form__input--search cc-form__mui--search"
                        label={t('search')}
                        value={filterText}
                        size="small"
                        onChange={(e) => setFilterText(e.target.value)}
                    />
                    <Link className="mc-button mc-button--primary mc-button--fit is-right" to={'create'}>
                        <IconGeneric iconName="add-ico" className="mc-icon__generic mc-icon__generic--size-12 is-white is-white--stroke" />
                        <span>{t('add')}</span>
                    </Link>
                </div>
                <InvoiceGroupsTableDataBackOffice invoiceGroups={filteredInvoiceGroups}/>
            </div>
        </>
    );
}
export default ListInvoiceGroupsBackOffice;