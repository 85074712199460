import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import GeneralEnhancedTableRow from "./GeneralEnhancedTableRow";
import GeneralTotalsRow from "./GeneralTotalsRow";
import {HeadCell, TableDataGeneral} from "../../../../domain/TableData";
import useDates from "../../../../hooks/use-dates";
import {GeneralData} from "../../../../domain/StatsData";
import EnhancedTableGeneral from "../../Common/Table/EnhancedTableGeneral";
import usePaginationContext from "../../../../hooks/context/use-pagination-context";
import {ErrorComponent} from "../../../Common/ErrorComponent";
import * as React from "react";

const headCells: readonly HeadCell<TableDataGeneral>[] = [
    {
        id: 'datetime',
        numeric: true,
        disablePadding: false,
        label: 'day',
        sortable: true,
    },
    {
        id: 'impressions',
        numeric: true,
        disablePadding: false,
        label: 'displays',
        sortable: true,
    },
    {
        id: 'income',
        numeric: true,
        disablePadding: false,
        label: 'income',
        sortable: true,
    },
    {
        id: 'ecpm',
        numeric: true,
        disablePadding: false,
        label: 'eCPM',
        sortable: true,
    },
    {
        id: 'viewability',
        numeric: true,
        disablePadding: false,
        label: 'viewability',
        sortable: true,
    },
];

interface Props {
    data: {
        days: GeneralData[] | undefined,
        summary: GeneralData | undefined
    }
}

const GeneralTableData = ({data}: Props) => {
    const {t, i18n} = useTranslation();
    const {getDayName} = useDates()
    const paginationContextData = usePaginationContext();
    const days = data?.days;
    const totals = data?.summary;

    const rows: TableDataGeneral[] = useMemo(() =>  days ? Object.entries(days).map(([dateStr, day]) => {
        const date =  new Date(dateStr);
        const stat : GeneralData = day;

        return {
            datetime: date.getTime(),
            dayWeek: getDayName(date, i18n.language),
            impressions: stat.impressions_dashboard,
            income: stat.income_dashboard,
            ecpm: stat.ecpm,
            viewability: stat.viewability,
        }
    }
    ) : []
    , [days, getDayName, i18n.language])

    if (0 === rows.length) {
        return <ErrorComponent title={t("no-results")} subtitle={t("try to modify the active filters to get results")} />
    }

    return(
        <EnhancedTableGeneral
            headCells={headCells}
            rows={rows}
            totals={totals}
            RowComponent={GeneralEnhancedTableRow}
            TotalsComponent={GeneralTotalsRow}
            paginationSettings={
                {
                    key: 'stats',
                    rowsPerPage: paginationContextData.getRowsPerPage('stats') ?? 25,
                    rowsPerPageOptions: [25, 50, 75, 100, { label: t('all'), value: -1 }]
                }
            }
        />
    )
}

export default GeneralTableData;