import HeaderItem from "../../../Common/Header/HeaderItem";
import {useContext} from "react";
import {AppContext} from "../../../../pages/App/Home";

interface Props {
    onClick: () => void
}

const HeaderAccounts = ({onClick}: Props) => {
    const { accounts, selectedAccount, setSelectedAccount } = useContext(AppContext);

    if (accounts.length === 1) {
        const account = accounts[0];

        return <HeaderItem
            key={account.id}
            className={"is-active"}
            onClick={onClick}
        >
            <button className="mc-button mc-button--secondary mc-button--no-border mc-button--header">
                <img
                    className='mc-icon__generic--size-16'
                    src={"https://www.google.com/s2/favicons?domain="+account.url+"&sz=32"}
                    alt={account.name}
                />
                {account.name}
            </button>
        </HeaderItem>
    }

    return <>
        { accounts.map((account) => {
            return <HeaderItem
                key={account.id}
                className={ (selectedAccount?.id === account.id ? "is-active" : "")}
                onClick={() => {
                    setSelectedAccount(account);
                    onClick();
                }}
            >
                <button className="mc-button mc-button--secondary mc-button--no-border mc-button--header">
                    <img className='mc-icon__generic--size-16' src={"https://www.google.com/s2/favicons?domain="+account.url+"&sz=32"} alt={account.name}/>
                    {account.name}
                </button>

            </HeaderItem>
        }) }
    </>
}

export default HeaderAccounts