import Heading from "../Heading";

interface Props{
    title: string;
    text: string;
    children: React.ReactNode;
}

const GeneralViewComponent = ({title, text, children}: Props) =>{

    return (
        <div className="cc-summary">
            <div className="oc-group cc-summary__real-time">
                <Heading title={title} text={text} />
                <div className="oc-group oc-group--nowrap oc-group--summary">
                    {children}
                </div>
            </div>
        </div>

    )

}

export default GeneralViewComponent


