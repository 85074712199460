import {useEffect, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import {InvoiceData} from "../domain/InvoiceData";
import {DateRange, DateRangeOptions} from "../domain/DateRange";
import {FilterOption} from "../domain/FilterOption";
import useDates from "./use-dates";

interface Response {
    invoices: InvoiceData[],
    loading: boolean
}

interface GetDataParameters {
    label: DateRangeOptions
    from?: string
    to?: string
    ig_ids?: string
}

const initialData: InvoiceData[] = [];

const useBackOfficeInvoicesData = (
    dateRange: DateRange,
    filteredInvoiceGroups: FilterOption[],
): Response => {
    const [loading, setLoading] = useState(true)
    const {formatDateCalendarPicker} = useDates();
    const client = useMemo(() => new ApiClient(), [])
    const [invoicesData, setInvoicesData] = useState<InvoiceData[]>(initialData)

    const parameters: GetDataParameters = useMemo(() => {
        const params = {
            label: dateRange.label,
        }

        if (dateRange.start) {
            Object.assign(params, {from: formatDateCalendarPicker(dateRange.start)})
        }

        if (dateRange.end) {
            Object.assign(params, {to: formatDateCalendarPicker(dateRange.end)})
        }

        if (filteredInvoiceGroups.length > 0) {
            Object.assign(params, {ig_ids: filteredInvoiceGroups.map((ig) => ig.id).join(',')})
        }

        return params
    }, [dateRange, filteredInvoiceGroups, formatDateCalendarPicker])

    useEffect(() => {
        setLoading(true);
        client
            .getInvoices(parameters)
            .then((response) => {
                const data: InvoiceData[] = response.data;
                setInvoicesData(data)
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [client, parameters, setLoading])

    return {
        invoices: invoicesData,
        loading
    };
}


export default useBackOfficeInvoicesData