import Filters from "../../../components/Common/Filters/Filters";
import useFiltersContext from "../../../hooks/context/use-filters-context";
import { StatsContext } from "../../../hooks/context/use-stats-context";
import useAppDimensionsData from "../../../hooks/use-app-dimensions-data";
import FormatsChart from "../../../components/App/Stats/Formats/FormatsChart";
import FormatsTableData from "../../../components/App/Stats/Formats/FormatsTableData";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import {FormatsData} from "../../../domain/StatsData";

const ListFormatsData = () => {
    const filtersContextData = useFiltersContext();
    const {data: advertingDimensionsStatsData , loading} = useAppDimensionsData(
        filtersContextData.dateRange,
        'formats'
    )

    if (loading) {
        return <LoadingFull/>
    }

    const daysData = advertingDimensionsStatsData?.days || [];
    const summaryData = advertingDimensionsStatsData?.summary || [];

    return (
        <>
            <StatsContext.Provider value={{dimensionData: advertingDimensionsStatsData}}>
                <Filters/>
                <FormatsChart data={daysData as FormatsData[]}/>
                <FormatsTableData
                    data={
                        {
                            days: daysData as FormatsData[],
                            summary: summaryData as FormatsData
                        }
                    }
                />
            </StatsContext.Provider>
        </>
    );
}
export default ListFormatsData