import {PaymentHistoryRow, TableDataInvoices} from "../../../domain/TableData";
import {useTranslation} from "react-i18next";
import useNumbers from "../../../hooks/use-numbers";
import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconGeneric from "../../Common/Icon/IconGeneric";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import useInvoiceStates from "../../../hooks/use-invoice-states";
import { Link } from "react-router-dom";
import useDates from "../../../hooks/use-dates";
import useInvoiceDownloadPDF from "../../../hooks/use-invoice-download-pdf";
import CircularProgress from '@mui/material/CircularProgress';

const InvoicesEnhancedTableRowBackOffice = (props: { id: string, row: TableDataInvoices }) => {
    const { i18n, t} = useTranslation();
    const {formatDatePeriod} = useDates()
    const {formatCurrency} = useNumbers()
    const { row: invoice } = props;
    const [open, setOpen] = React.useState(false);
    const {getCssClass} = useInvoiceStates();

    const invoiceStateCss = getCssClass(invoice.state.label);
    const {download: downloadPDF, loading} = useInvoiceDownloadPDF(invoice.id);
    
    const downloadIcon = () => {
        return loading
            ? <CircularProgress size="12px" />
            : <><IconGeneric iconName="download-ico" className="mc-icon__generic mc-icon__generic--size-12" /><span className="mc-button--label">{t('download')}</span></>
    }

    return (
        <>
            <TableRow className={`cc-fra ${invoiceStateCss}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell scope="row">
                    <div className="oc-group oc-group--half-gap oc-group--nowrap oc-group--center">
                        <IconGeneric iconName={open ? "arrow-up-ico" : "arrow-down-ico"} className="mc-button mc-button--circle mc-button--auto mc-icon__generic--size-16" onClick={() => setOpen(!open)} />
                        <span className='cc-fra__company_name'><Link to={`${invoice.id}`}>{invoice.company_name}</Link></span>
                    </div>
                </TableCell>
                <TableCell><span className='cc-fra__reference'>{invoice.reference}</span></TableCell>
                <TableCell><span className='cc-fra__period'>{formatDatePeriod(invoice.creation_date, i18n.language)}</span></TableCell>
                <TableCell><span className='cc-fra__period'>{formatDatePeriod(invoice.start_period, i18n.language)}</span></TableCell>
                <TableCell><span className='cc-fra__period'>{formatDatePeriod(invoice.end_period, i18n.language)}</span></TableCell>
                <TableCell><span className='cc-fra__state'>{t(invoice.state.label)}</span></TableCell>
                <TableCell><span className={'mc-data'}>{formatCurrency(invoice.income, i18n.language)}</span></TableCell>
                <TableCell>
                    <div className="oc-group oc-group--half-gap" style={{minWidth: '80px'}}>
                        <a onClick={downloadPDF} className="mc-button mc-button--secondary is-small">
                            { downloadIcon() }
                        </a>
                        <Link className='mc-button mc-button--secondary is-small' to={`${invoice.id}`}>
                            <IconGeneric iconName="invoice-ico" className="mc-icon__generic mc-icon__generic--size-12" />
                            <span className="mc-button--label">{t('show')}</span>
                        </Link>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow className='no-border'>
                <TableCell style={{ padding: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table className='cc-table cc-table--redux cc-card' size="small" aria-label="purchases">
                                <TableBody>
                                    {invoice.payments_history.map((historyRow: PaymentHistoryRow) => {
                                        const { payment_number, date, state, revenue } = historyRow;
                                        const cssClass = getCssClass(historyRow.state.label);
                                        const paymentValue = invoice.state.id === 2 ? invoice.income : revenue;

                                        return (
                                            <TableRow className={cssClass} key={date}>
                                                <TableCell width='25%'>
                                                    <span className='cc-fra__payment'>{payment_number}{') '}</span>
                                                    <span className='mc-date mc-date--mini'>{date}</span>
                                                </TableCell>
                                                <TableCell width='25%'>
                                                    <span className='cc-fra__state'>{t(state.label)}</span>
                                                </TableCell>
                                                <TableCell width='50%'>
                                                    <span className='mc-data mc-data--redux'>{formatCurrency(paymentValue, i18n.language)}</span>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default InvoicesEnhancedTableRowBackOffice;