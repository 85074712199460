import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {DevicesData} from "../../../../domain/StatsData";
import EnhancedTableDimensionsCell from "../../Common/Table/EnhancedTableDimensionsCell";

interface Props{
    totals:  DevicesData
}

const DeviceTotalsRow = ({totals}: Props) => {
    return (
        <>
            <TableRow className="cc-table__totals">
                <TableCell className="cc-table__totals-title">Total</TableCell>
                <TableCell className="cc-table__totals-data"><EnhancedTableDimensionsCell data={totals?.desktop}/></TableCell>
                <TableCell className="cc-table__totals-data"><EnhancedTableDimensionsCell data={totals?.mobile}/></TableCell>
                <TableCell className="cc-table__totals-data"><EnhancedTableDimensionsCell data={totals?.tablet}/></TableCell>
                <TableCell className="cc-table__totals-data"><EnhancedTableDimensionsCell data={totals?.other}/></TableCell>
                <TableCell className="cc-table__totals-data"><EnhancedTableDimensionsCell data={totals?.total}/></TableCell>
            </TableRow>
        </>
    );


}

export default DeviceTotalsRow;