import {useCallback, useEffect, useState} from "react";
import ApiClient from "../../client/ApiClient";
import {Device} from "../../domain/Device";
import {Environment} from "../../domain/Environment";
import {Partner} from "../../domain/Partner";
import {Subsite} from "../../domain/Subsite";

const useBackOfficeFilters = () => {
    const [devices, setDevices] = useState<Device[]>([]);
    const [environments, setEnvironments] = useState<Environment[]>([]);
    const [partners, setPartners] = useState<Partner[]>([]);
    const [subsites, setSubsites] = useState<Subsite[]>([]);

    const getDevices = useCallback(async () => {
        const client = new ApiClient();

        try {
            const response = await client.getAllDevicesBackOffice()

            setDevices(response.data)
        } catch (exception) {
            setDevices([])
        }
    }, [setDevices])

    const getEnvironments = useCallback(async () => {
        const client = new ApiClient();

        try {
            const response = await client.getAllEnvironmentsBackOffice()

            setEnvironments(response.data)
        } catch (exception) {
            setEnvironments([])
        }
    }, [setEnvironments])

    const getPartners = useCallback(async () => {
        const client = new ApiClient();

        try {
            const response = await client.getAllPartnersBackOffice(true)

            setPartners(response.data)
        } catch (exception) {
            setPartners([])
        }
    }, [setPartners])

    const getSubsites = useCallback(async () => {
        const client = new ApiClient();

        try {
            const response = await client.getAllSubsitesBackOffice(true)

            setSubsites(response.data)
        } catch (exception) {
            setSubsites([])
        }
    }, [setSubsites])

    useEffect(() =>{
        Promise.all([getDevices(), getEnvironments(), getPartners(), getSubsites()]).then()
    }, [getDevices, getEnvironments, getPartners, getSubsites])

    return {
        devices,
        environments,
        partners,
        subsites
    }
}

export default useBackOfficeFilters;