import { AlertProps } from "@mui/material";
import { useState } from "react";

export const useSnackbar = () => {

    const [snackbar, setSnackbar] = useState<Pick<AlertProps,'children' | 'severity'> | null>(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    return {
        snackbar,
        showSnackbar: (message: string, severity: 'success'|'info'|'warning'|'error') => setSnackbar({ children: message, severity: severity}),
        closeSnackbar: handleCloseSnackbar
    }
}