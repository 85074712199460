import {createContext, Dispatch, SetStateAction,  useContext, useState} from "react";
import {FilterOption} from "../../domain/FilterOption";

export interface FiltersHistoricalContextInterface {
    filteredSubsites: FilterOption[]
    setFilteredSubsites: Dispatch<SetStateAction<FilterOption[]>>
}

export const FiltersHistoricalContext = createContext<FiltersHistoricalContextInterface>({} as FiltersHistoricalContextInterface)

const useFiltersHistoricalContext = () => useContext(FiltersHistoricalContext)

export const useFiltersHistoricalContextData = (): FiltersHistoricalContextInterface => {
    const [filteredSubsites, setFilteredSubsites] = useState<FilterOption[]>([]);

    return {
        filteredSubsites,
        setFilteredSubsites
    }
}

export default useFiltersHistoricalContext