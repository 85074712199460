import useFiltersContext from "../../../hooks/context/use-filters-context";
import useAppDimensionsData from "../../../hooks/use-app-dimensions-data";
import { StatsContext } from "../../../hooks/context/use-stats-context";
import Filters from "../../../components/Common/Filters/Filters";
import EnvironmentsChart from "../../../components/App/Stats/Environments/EnvironmentsChart";
import EnvironmentsTableData from "../../../components/App/Stats/Environments/EnvironmentsTableData";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import {EnvironmentsData} from "../../../domain/StatsData";

const ListEnvironmentsData = () => {
    const filtersContextData = useFiltersContext();
    const {data: advertingDimensionsStatsData , loading} = useAppDimensionsData(
        filtersContextData.dateRange,
        'environments'
    )

    if (loading) {
        return <LoadingFull/>
    }

    const daysData = advertingDimensionsStatsData?.days || [];
    const summaryData = advertingDimensionsStatsData?.summary || [];

    return (
        <>
            <StatsContext.Provider value={{dimensionData: advertingDimensionsStatsData}}>
                <Filters/>
                <EnvironmentsChart data={daysData} />
                <EnvironmentsTableData
                    data={
                        {
                            days: daysData as EnvironmentsData[],
                            summary: summaryData as EnvironmentsData
                        }
                    }
                />
            </StatsContext.Provider>
        </>
    );
}
export default ListEnvironmentsData