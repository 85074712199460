import Invoice from "../../../components/BackOffice/Invoices/Invoice";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import useInvoiceData from "../../../hooks/use-invoice-data";
import {useParams} from "react-router-dom";

const SingleInvoiceBackOffice = () => {
    const { invoiceId } = useParams();
    const {invoice, loading} = useInvoiceData(invoiceId)

    if(loading || !invoice || Object.keys(invoice).length === 0){
        return <LoadingFull/>
    }

    return (
        <>
            <Invoice invoiceData={invoice}/>
        </>
    )
}
export default SingleInvoiceBackOffice