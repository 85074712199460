import {
    GridColDef,
    GridRowModesModel
} from "@mui/x-data-grid";
import { useState} from "react";
import {useTranslation} from "react-i18next";
import useDates from "../use-dates";
import {OrphanMetricData} from "../../domain/OrphanMetricData";
import {Partner} from "../../domain/Partner";

export const useOrphanMetricsGrid = (
    partners: Partner[],
    metrics: OrphanMetricData[],
) => {

    const {t, i18n} = useTranslation();
    const {formatDate} = useDates();
    const [rows, setRows] = useState<OrphanMetricData[]>(metrics);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            type: 'number',
            width: 100,
            align: 'left',
            headerAlign: 'left',
            editable: false,
            renderCell(params) {
                return params.row.id;
            },
        },
        {
            field: 'partner',
            headerName: t('partner'),
            type: 'singleSelect',
            width: 100,
            align: 'left',
            headerAlign: 'left',
            editable: false,
            renderCell(params) {
                return params.row.partner.name;
            },
            valueGetter: ({ value }) => {
                return value.name
            },
            getOptionValue: (value: any) => value.id,
            getOptionLabel: (value: any) => value.name,
            valueOptions: partners,
        },
        {
            field: 'date',
            headerName: t('date'),
            type: 'date',
            width: 140,
            align: 'left',
            headerAlign: 'left',
            editable: false,
            valueFormatter: ({ value }) => formatDate(value, i18n.language),
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: 'project_match',
            headerName: t('project-match'),
            width: 500,
            align: 'left',
            headerAlign: 'left',
            editable: false,
        },
    ];

    return{
        rows,
        columns,
        setRows,
        rowModesModel,
        setRowModesModel
    }
}