import { createTheme } from '@mui/material';
import shadows, { Shadows } from '@mui/material/styles/shadows';
import css from './sass/01.settings/_settings.module.scss';

export const theme = createTheme({
    palette: {
        primary: {
            main: css.coPrimary
        },
        secondary: {
            main: '#dddddd'
        }
    },
    shadows: shadows.map(() => 'none') as Shadows,
    typography: {
        fontFamily: [
            'Open Sans',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
        fontSize: 12,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderColor: css.coBorder,
                    color: css.coBlack,
                    textTransform: 'none',
                },
            },
        },
    },
});