import {useTranslation} from "react-i18next";
import {Link, useLocation} from 'react-router-dom';
import IconGeneric from "../../../Common/Icon/IconGeneric";
import {SidebarItem} from "../../../../domain/SidebarItem";

const sidebarItems: SidebarItem[] = [
    {
        pathname: '/',
        to: '/',
        label: 'general',
        icon: 'home-ico',
    },
    {
        pathname: '/formats',
        to: '/formats',
        label: 'formats',
        icon: 'formats-ico',
    },
    {
        pathname: '/environments',
        to: '/environments',
        label: 'environments',
        icon: 'environment-ico',
    },
    {
        pathname: '/devices',
        to: '/devices',
        label: 'devices',
        icon: 'devices-ico',
    },
    {
        pathname: '/invoices',
        to: '/invoices',
        label: 'invoices',
        icon: 'invoice-ico',
    },
];

const SidebarItemsList = () => {
    const {t} = useTranslation()
    const {pathname} = useLocation();

    return (
        <>
            {sidebarItems.map((item: SidebarItem) => (
                <Link key={item.pathname} className={`cc-sidebar__item ${item.pathname === pathname ? 'is-active' : ''}`} to={item.to}>
                    <IconGeneric iconName={item.icon} className="mc-icon__generic--size-16" />{t(item.label)}
                </Link>
            ))}
        </>
    );
}

export default SidebarItemsList