import React from "react";
import {useTranslation} from "react-i18next";
import GeneralChartTooltipItem from "./GeneralChartTooltipItem";
import useDates from "../../../hooks/use-dates";

interface CustomTooltipProps {
    chartColors: any
    active?: boolean;
    payload?: any[];
    label?: string | number;
}

const GeneralChartTooltip: React.FC<CustomTooltipProps> = ({active, payload, label }) => {
    const {i18n} = useTranslation()
    const date = label ? new Date(label) : null;
    const {formatDate} = useDates();
    if (active && payload && payload.length) {

        return (
            <div className="cc-chart__tooltip">
                <div className="cc-chart__tooltip-date">
                    {formatDate(date, i18n.language)}
                </div>

                { payload.map((data, index) => {
                    return <GeneralChartTooltipItem
                        key={index}
                        label={data.name}
                        value={data.value}
                        color={data.stroke}
                    />
                }) }
            </div>
        );
    }
    return null;
};

export default GeneralChartTooltip