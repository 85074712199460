import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";

interface Props{
    label: string;
    defaultValue: null|Date;
    onChange: (date: Date | null) => void;
}
export default function DatePickerMUI({label, defaultValue, onChange}: Props) {
    const {i18n} = useTranslation()

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n?.language}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    label={label}
                    defaultValue={defaultValue ? dayjs(defaultValue) : null}
                    onChange={(value) => onChange(value ? value.toDate() : null)}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}
