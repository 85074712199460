import * as React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import {CompareStats} from "../../../../domain/CompareStatsData";
import PerformanceEnhancedTableHeadBackOffice from "./PerformanceEnhancedTableHeadBackOffice";
import PerformanceEnhancedTableBodyBackOffice from "./PerformanceEnhancedTableBodyBackOffice";

interface Props {
    data: CompareStats | undefined;
}

const PerformanceTableDataBackOffice = ({ data }: Props) => {
    const stats = data;
    const firstKey = stats ? Object.keys(stats)[0] : null;
    const partnerNames = firstKey && stats ? Object.keys(stats[firstKey]) : [];
    const projectData = stats ? Object.entries(stats) : [];

    return (
        <>
            <div className='cc-table cc-table--performance cc-card'>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    >
                        {<PerformanceEnhancedTableHeadBackOffice partnerNames={partnerNames}/>}
                        {<PerformanceEnhancedTableBodyBackOffice partnerNames={partnerNames} subsitesData={projectData}/>}
                    </Table>
                </TableContainer>
            </div>
        </>
    );
};

export default PerformanceTableDataBackOffice;