import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {TextField, Button, FormHelperText} from '@mui/material';
import { useTranslation } from "react-i18next";
import DatePickerMUI from "../../Common/DatePicker/DatePickerMUI";
import InvoiceGroupSelector from "../../Common/InvoiceGroup/InvoiceGroupSelector";
import useBackofficeInvoiceGroups from "../../../hooks/filters/use-backoffice-invoice-groups";
import useDates from "../../../hooks/use-dates";
import {FilterOption} from "../../../domain/FilterOption";

export interface ProjectFormData{
    public_key: string;
    domain: string;
    ad_unit: string;
    revenue_share: number;
    adunit_names: string;
    invoice_group_id: number | null;
    date_registration: string | null;
    date_unregistration: string | null;
}

interface Props {
    projectId?: number;
    initialValues: ProjectFormData;
    onSave: (formData: ProjectFormData) => void;
    btnSaveText: string;
    onCancel: () => void;
    onDelete?: (invoiceGroupId: number) => void;
}

const ProjectForm: React.FC<Props> = ({ projectId, initialValues, onSave, btnSaveText, onCancel, onDelete }) => {
    const { t } = useTranslation();
    const { formatDateCalendarPicker } = useDates();
    const [formData, setFormData] = useState<ProjectFormData>(initialValues);
    const invoiceGroupsData = useBackofficeInvoiceGroups();
    const [touchedFields, setTouchedFields] = useState<Record<string, boolean>>({});

    const isNew = !projectId;
    const defaultEmptyOption = { id: -1, name: '' };

    const [filterOption, setFilterOption] = useState<FilterOption | null>(
        isNew ? defaultEmptyOption : null
    );

    const invoiceGroups = invoiceGroupsData.invoiceGroups;

    useEffect(() => {
        if (formData.invoice_group_id !== null && invoiceGroups.length > 0) {
            const invoiceGroup = invoiceGroups.find(ig => ig.id === formData.invoice_group_id);
            if (invoiceGroup) {
                setFilterOption({ id: invoiceGroup.id, name: invoiceGroup.name });
            }
        }
    }, [invoiceGroups, formData.invoice_group_id]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }, []);

    const handleDateChange = useCallback((key: string, date: Date | null) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: date ? formatDateCalendarPicker(date) : null,
        }));
    }, [formatDateCalendarPicker]);

    const handleInvoiceGroupChange = useCallback((invoiceGroupId: number) => {
        setFormData(prevState => ({
            ...prevState,
            invoice_group_id: invoiceGroupId,
        }));
    }, []);

    const handleSave = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        onSave(formData);
    }, [formData, onSave]);

    const handleCancel = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        onCancel();
    }, [onCancel]);

    const handleDelete = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (onDelete && projectId) {
            onDelete(projectId);
        }
    }, [onDelete, projectId]);

    const handleBlur = useCallback((field: string) => {
        setTouchedFields(prevState => ({
            ...prevState,
            [field]: true,
        }));
    }, []);

    const isTouched = useCallback((field: keyof ProjectFormData) => {
        return touchedFields[field];
    }, [touchedFields]);

    const invoiceGroupOptions = useMemo(() => (
        invoiceGroups.map(group => ({ id: group.id, name: group.name }))
    ), [invoiceGroups]);

    return (
        <form className='cc-form'>
            {projectId &&
                <TextField
                    name="id"
                    label={'ID'}
                    value={projectId}
                    onChange={handleChange}
                    fullWidth
                    required={!!projectId}
                    disabled={projectId > 0}
                />
            }
            <TextField
                name="public_key"
                label={t('public-key')}
                value={formData.public_key}
                onChange={handleChange}
                fullWidth
                required
                onBlur={() => handleBlur('public_key')}
                error={isTouched('public_key') && formData.public_key.trim() === ''}
            />
            <TextField
                name="domain"
                label={t('domain')}
                value={formData.domain}
                onChange={handleChange}
                fullWidth
                required
                onBlur={() => handleBlur('domain')}
                error={isTouched('domain') && formData.domain.trim() === ''}
            />
            <TextField
                name="ad_unit"
                label={t('adunit')}
                value={formData.ad_unit}
                onChange={handleChange}
                fullWidth
                required
                onBlur={() => handleBlur('ad_unit')}
                error={isTouched('ad_unit') && formData.ad_unit.trim() === ''}
            />
            <TextField
                name="revenue_share"
                label={t('revenue-share')}
                helperText={t('revenue share must be between 0 and 1')}
                value={formData.revenue_share}
                onChange={handleChange}
                type="number"
                fullWidth
                required
                onBlur={() => handleBlur('revenue_share')}
                error={isTouched('revenue_share') && !(formData.revenue_share >= 0 && formData.revenue_share <= 1)}
            />
            <TextField
                name="adunit_names"
                label={t('adunit-names')}
                value={formData.adunit_names}
                onChange={handleChange}
                fullWidth
            />
            <div className='cc-form__select-dropdown-group'>
                <span className='cc-form__select-dropdown-label'>{t('invoice-groups')}</span>
                {filterOption && invoiceGroupOptions.length > 0 &&
                    <>
                        <InvoiceGroupSelector
                            defaultOption={filterOption}
                            options={invoiceGroupOptions}
                            handleSelect={handleInvoiceGroupChange}
                        />
                        <FormHelperText sx={{ color: '#ff7500' }}>
                            {t('invoice group modification warning')}
                        </FormHelperText>
                    </>
                }
            </div>
            <DatePickerMUI
                label={t('date-registration')}
                defaultValue={formData.date_registration ? new Date(formData.date_registration) : new Date()}
                onChange={(date) => handleDateChange('date_registration', date)}
            />
            <DatePickerMUI
                label={t('date-unregistration')}
                defaultValue={formData.date_unregistration ? new Date(formData.date_unregistration) : null}
                onChange={(date) => handleDateChange('date_unregistration', date)}
            />
            <div className='oc-group'>
                <Button className='mc-button mc-button--primary' onClick={handleSave} type="submit" variant="contained" color="primary">
                    {btnSaveText}
                </Button>
                <Button className='mc-button mc-button--secondary' onClick={handleCancel} variant="contained" color="primary">
                    {t('cancel')}
                </Button>
                {projectId &&
                    <Button className='mc-button mc-button--danger mc-button--to-right' onClick={handleDelete} variant="contained" color="secondary">
                        {t('delete')}
                    </Button>
                }
            </div>
        </form>
    );
};

export default ProjectForm;
