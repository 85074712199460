import {HeadCell, TableDataInvoices} from "../../../domain/TableData";
import {useMemo} from "react";
import useDates from "../../../hooks/use-dates";
import {useTranslation} from "react-i18next";
import EnhancedTable from "../Common/Table/EnhancedTable";
import InvoicesEnhancedTableRow from "./InvoicesEnhancedTableRow";
import usePaginationContext from "../../../hooks/context/use-pagination-context";
import {ErrorComponent} from "../../Common/ErrorComponent";
import {InvoiceData} from "../../../domain/InvoiceData";

const headCells: readonly HeadCell<TableDataInvoices>[] = [
    {
        id: 'company_name',
        numeric: false,
        disablePadding: false,
        label: 'company',
        sortable: true,
    },
    {
        id: 'reference',
        numeric: false,
        disablePadding: false,
        label: 'reference',
        sortable: true,
    },
    {
        id: 'creation_date',
        numeric: true,
        disablePadding: false,
        label: 'creation_date',
        sortable: true,
    },
    {
        id: 'start_period',
        numeric: true,
        disablePadding: false,
        label: 'start_period',
        sortable: true,
    },
    {
        id: 'end_period',
        numeric: true,
        disablePadding: false,
        label: 'end_period',
        sortable: true,
    },  
    {
        id: 'state',
        numeric: true,
        disablePadding: false,
        label: 'state',
        sortable: true,
    },
    {
        id: 'income',
        numeric: true,
        disablePadding: false,
        label: 'income',
        sortable: true,
    },
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'actions',
        sortable: false,
    },
]

interface Props {
    data: InvoiceData[]
}

const InvoicesTableData = ({data}: Props) =>{
    const {t, i18n} = useTranslation();
    const {getMonthName, getYear, formatDate} = useDates()
    const paginationContextData = usePaginationContext();

    const rows: TableDataInvoices[] = useMemo(() =>  data ? Object.values(data).map((inv) => {
        const date =  new Date(inv.date);
        const historyPayments = Object.values(inv.payments_history).map((payment, index) => {
            return{
                payment_number: index + 1,
                date: payment.date === null ? '--/--/--' : formatDate(new Date(payment.date), i18n.language),
                state: payment.state,
                revenue: payment.revenue
            }
        });

        return {
            id: inv.id,
            creation_date: date,
            start_period: new Date(inv.start_period),
            end_period: new Date(inv.end_period),
            month: getMonthName(date, i18n.language),
            year: getYear(date, i18n.language),
            company_name: inv.company_name,
            tax_identifier: inv.tax_identifier,
            state: inv.state,
            income: inv.revenue,
            payments_history: historyPayments,
            reference: inv.reference,
        }
    }
    ) : []
    , [data, getMonthName, getYear, formatDate, i18n.language])

    if (0 === rows.length) {
        return <ErrorComponent title={t("no-results")} subtitle={t("try to modify the active filters to get results")} />
    }

    return(
        <EnhancedTable
            headCells={headCells}
            rows={rows}
            RowComponent={InvoicesEnhancedTableRow}
            paginationSettings={
                {
                    key: 'invoices',
                    rowsPerPage: paginationContextData.getRowsPerPage('invoices') ?? 12,
                    rowsPerPageOptions: [12, 24, 36, 48, { label: t('all'), value: -1 }]
                }
            }
        />
    )
}

export default InvoicesTableData;