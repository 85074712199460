import FilterInvoiceDate from "./FilterInvoiceDate";
import ReferenceSearchFilter from "../../Common/Filters/ReferenceSearchFilter";
import MultiSelectFilter from "../../Common/Filters/MultiSelectFilter/MultiSelectFilter";
import useFiltersContext from "../../../hooks/context/use-filters-context";
import useBackOfficeInvoiceGroups from "../../../hooks/filters/use-backoffice-invoice-groups";

const FiltersInvoices = () => {
    const {
        filteredInvoiceGroups, setFilteredInvoiceGroups,
        filterableInvoiceStates,
        filteredInvoiceStates, setFilteredInvoiceStates
    } = useFiltersContext();
    const {invoiceGroups} = useBackOfficeInvoiceGroups();

    return <div className="cc-filter">
        <div className='cc-filter__wrapper'>
            <ReferenceSearchFilter/>
            <FilterInvoiceDate/>
            <div className="cc-filter__wrapper--pull-right">
                <MultiSelectFilter
                    name={'group'}
                    values={invoiceGroups}
                    filteredValues={filteredInvoiceGroups}
                    setFilteredValues={setFilteredInvoiceGroups}
                />
                <MultiSelectFilter
                    name={'state'}
                    values={filterableInvoiceStates}
                    filteredValues={filteredInvoiceStates}
                    setFilteredValues={setFilteredInvoiceStates}
                />
            </div>
        </div>
    </div>
};

export default FiltersInvoices;
