import {useCallback, useEffect, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import {ExternalAppsAccess} from "../domain/Token";
import {TOKEN_KEY} from "../domain/Constants";

const useAuthenticationExternalApps = () => {
    const client = useMemo(() => new ApiClient(), [])
    const [externalAppsAccess, setExternalAppsAccess] = useState<ExternalAppsAccess>({ analytics: false, advertising: false, ai: false})

    const getExternalAppsData = useCallback(async () => {
        const token = localStorage.getItem(TOKEN_KEY)
        if (!token){
            return {
                analytics: false,
                advertising: false,
                ai: false,
            }
        }

        const response = await client.getExternalAppsAccess(token);
        setExternalAppsAccess({
            analytics: response?.analytics || false,
            advertising: response?.advertising || false,
            ai: response?.ai || false
        });

    }, [setExternalAppsAccess, client])

    useEffect(() => {
        getExternalAppsData()
    }, [getExternalAppsData])

    return {
        externalAppsAccess
    }
}

export default useAuthenticationExternalApps