import Filters from "../../../components/Common/Filters/Filters";
import useAppGeneralData from "../../../hooks/use-app-general-data";
import {StatsContext} from "../../../hooks/context/use-stats-context";
import useFiltersContext from "../../../hooks/context/use-filters-context";
import GeneralView from "../../../components/App/Stats/General/GeneralView";
import GeneralChart from "../../../components/App/Stats/General/GeneralChart";
import GeneralTableData from "../../../components/App/Stats/General/GeneralTableData";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";

const ListGeneralData = () => {
    const filtersContextData = useFiltersContext();
    const {data: advertingGeneralStatsData , loading} = useAppGeneralData(filtersContextData.dateRange)

    if (loading) {
        return <LoadingFull/>
    }

    return (
        <StatsContext.Provider value={{generalData: advertingGeneralStatsData}}>
            <Filters/>
            <GeneralView data={advertingGeneralStatsData.summary}/>
            <GeneralChart data={advertingGeneralStatsData.stats}/>
            <GeneralTableData
                data={
                    {
                        days: advertingGeneralStatsData.stats,
                        summary: advertingGeneralStatsData.summary
                    }
                }
            />
        </StatsContext.Provider>
    )

}
export default ListGeneralData