import {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApiClient from "../client/ApiClient";
import {Account} from "../domain/Account";

export const useAccounts = () => {
    const [accounts, setAccounts] = useState<Account[]>([])
    const navigate = useNavigate()

    const getAccounts = useCallback(async () => {
        const client = new ApiClient();

        try {
            const response = await client.request("get", "/accounts")

            setAccounts(response.data.accounts)
        } catch (exception) {
            navigate("/unauthorized")
        }
    }, [setAccounts, navigate])

    useEffect(() => {
        getAccounts()
    }, [getAccounts])

    return {
        accounts,
    }
}