import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { useTranslation } from "react-i18next";
import {useCallback, useMemo} from "react";
import Heading from "../../../Common/Heading";
import { HeadCell } from "../../../../domain/TableData";
import EnhancedTableHead from "./EnhancedTableHead";
import { TableFooter } from "@mui/material";
import { StableSort } from "../../../../domain/EnhancedTableSorting";
import usePaginationEnhancedTable from "../../../../hooks/ui/use-pagination-enhanced-table";
import useComparatorSortingEnhancedTable from "../../../../hooks/ui/use-comparator-sorting-enhanced-table";
import useFiltersContext from "../../../../hooks/context/use-filters-context";
import {SummaryData} from "../../../../domain/StatsData";

interface EnhancedTableRowProps<T> {
    id: string;
    row: T;
}

interface TableRowData {
    [key: string]: any;
}

interface EnhancedTableProps<T extends TableRowData> {
    headCells: readonly HeadCell<T>[],
    rows: T[],
    totals?: any,
    RowComponent: React.ComponentType<EnhancedTableRowProps<T>>,
    TotalsComponent?: React.ComponentType<{ totals: SummaryData }>
    paginationSettings?: { key: string, rowsPerPage: number, rowsPerPageOptions: any };
}

export default function EnhancedTableGeneral<T extends TableRowData>(props: EnhancedTableProps<T>) {
    const { headCells, rows, RowComponent, totals, TotalsComponent, paginationSettings } = props;
    const { t } = useTranslation();
    const { dateRange } = useFiltersContext();
    const {
        getComparator,
        order,
        setOrder,
        orderBy,
        setOrderBy } = useComparatorSortingEnhancedTable()
    const {
        defaultLabelDisplayedRows,
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        setPage,
        rowsPerPage
    } = usePaginationEnhancedTable(paginationSettings);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof T,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property as string);
        setPage(0);
    };

    const stableSort: StableSort<T> = useCallback((array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }, []);

    const visibleRows = useMemo(
        () => {
            if (rowsPerPage === -1) {
                return stableSort(rows, getComparator(order, orderBy as string));
            } else {
                return stableSort(rows, getComparator(order, orderBy as string)).slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                );
            }
        },
        [stableSort, getComparator, rows, order, orderBy, page, rowsPerPage],
    );

    return (
        <>
            <div className='cc-table cc-card'>
                <Heading title={t('by-days')} text={t(dateRange.label)} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    >
                        {rows.length > 0 && <EnhancedTableHead
                            headCells={headCells}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        }
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const labelId = `enhanced-table-row-${index}`;
                                return (
                                    <RowComponent id={labelId} key={labelId} row={row} />
                                );
                            })}
                            {TotalsComponent && totals && <TotalsComponent totals={totals} />}
                        </TableBody>
                    </Table>

                </TableContainer>
            </div>
            {visibleRows.length > 0 && <TableFooter>
                <TablePagination className='cc-table__pagination'
                    rowsPerPageOptions={paginationSettings?.rowsPerPageOptions ?? [25, 50, 75, 100, { label: t('all'), value: -1 }]}
                    colSpan={3}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={defaultLabelDisplayedRows}
                    labelRowsPerPage={t('rows-per-page')}
                />
            </TableFooter>
            }
        </>
    );
}
