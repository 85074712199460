import {useContext, useEffect, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import {AppContext} from "../pages/App/Home";
import {DateRange, DateRangeOptions} from "../domain/DateRange";
import {DevicesData, EnvironmentsData, FormatsData} from "../domain/StatsData";
import useDates from "./use-dates";

type Dimension = 'formats' | 'devices' | 'environments'

interface Response {
    data: AppDimensionData
    loading: boolean
}

interface GetDataParameters {
    label: DateRangeOptions
    from?: string
    to?: string
    account?: number
}

export interface AppDimensionData {
    days: FormatsData[]|EnvironmentsData[]|DevicesData[]|[]
    summary: FormatsData|EnvironmentsData|DevicesData|[]
}

const initialData: AppDimensionData = {
    days: [],
    summary: []
}

const useAppDimensionsData = (
    dateRange: DateRange,
    dimension: Dimension
): Response => {
    const [loading, setLoading] = useState(true)
    const {formatDateCalendarPicker} = useDates();
    const {accounts, selectedAccount} = useContext(AppContext)
    const [dimensionData, setDimensionData] = useState<AppDimensionData>(initialData)
    const client = useMemo(() => new ApiClient(), [])

    const pids =  useMemo(() => {
        if(selectedAccount){
            return selectedAccount.project_id
        }else{
            return (accounts.map((account => account.project_id))).join(',');
        }
    }, [accounts, selectedAccount])


    const parameters: GetDataParameters = useMemo(() => {
        const params = {
            label: dateRange.label,
            pids: pids
        }

        if (dateRange.start) {
            Object.assign(params, {from: formatDateCalendarPicker(dateRange.start)})
        }

        if (dateRange.end) {
            Object.assign(params, {to: formatDateCalendarPicker(dateRange.end)})
        }

        return params
    }, [dateRange, pids, formatDateCalendarPicker])


    useEffect(() => {
        setLoading(true);
        client.getMetricsStats(dimension, parameters).then((response) => {
            const dimensionData: AppDimensionData = response.data;

            setDimensionData(dimensionData)
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }, [client, parameters, dimension, setLoading])

    return {
        data: dimensionData,
        loading
    }
}


export default useAppDimensionsData