import {
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowId, GridRowModel,
    GridRowModes,
    GridRowModesModel
} from "@mui/x-data-grid";
import {useCallback, useEffect, useMemo, useState} from "react";
import {InvoiceData, InvoiceDetailsData, ProjectData} from "../../domain/InvoiceData";
import ApiClient from "../../client/ApiClient";
import {useSnackbar} from "./use-snackbar";
import CancelIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import useNumbers from "../use-numbers";
import useDates from "../use-dates";
import {format} from "date-fns";
import IconGeneric from "../../components/Common/Icon/IconGeneric";

interface RowGridData extends InvoiceDetailsData{
    isNew: boolean;
}

export const useInvoiceDetailsGrid = (
    invoice: InvoiceData|null,
    invoiceDetails: InvoiceDetailsData[],
    allowEditingActions?: boolean,
    customTypes?: {id: number, label: string }[]
) => {

    const {t, i18n} = useTranslation();
    const {formatCurrency } = useNumbers();
    const {formatDate, formatDatePeriod} = useDates();
    const {snackbar, showSnackbar, closeSnackbar} = useSnackbar();
    const [rows, setRows] = useState<RowGridData[]>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [open, setOpen] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number>(0);

    useEffect(() =>{
        const initialRows: RowGridData[] = invoiceDetails.map((invoiceDetail)=> {
            return {
                ...invoiceDetail,
                isNew: false
            }
        })
        setRows(initialRows)
    },[invoiceDetails])

    const states = useMemo(() =>
        [
            { id: 1, label: 'Pending Payment' },
            { id: 2, label: 'Paid' }
        ],
    []);

    const types = useMemo(() =>
        customTypes ? customTypes :
            [
                { id: 1, label: 'BackOffice' },
                { id: 2, label: 'Customer' }
            ],[customTypes]);

    const projects = useMemo(() => {
        return (invoice && invoice.projects.length > 0) ? invoice.projects.map((project: ProjectData) => {
            return {
                id: project.id,
                label: project.domain
            }
        }): [];
    }, [invoice])

    const columns: GridColDef[] = [];

    if (invoiceDetails.filter((data) => data.invoice_company_name).length > 0) {
        columns.push({
            field: 'invoice_company_name',
            headerName: t('company-name'),
            type: 'string',
            width: 260,
            align: 'left',
            headerAlign: 'left',
            editable: false,
        });
    }

    if (invoiceDetails.filter((data) => data.invoice_start_period).length > 0) {
        columns.push({
            field: 'invoice_start_period',
            headerName: t('start_period'),
            type: 'string',
            width: 120,
            align: 'left',
            headerAlign: 'left',
            editable: false,
            valueFormatter: (value) => formatDatePeriod(value.value, i18n.language)
        });
    }

    if (invoiceDetails.filter((data) => data.invoice_end_period).length > 0) {
        columns.push({
            field: 'invoice_end_period',
            headerName: t('end_period'),
            type: 'string',
            width: 120,
            align: 'left',
            headerAlign: 'left',
            editable: false,
            valueFormatter: (value) => formatDatePeriod(value.value, i18n.language)
        });
    }


    if (projects.length > 0) {
        columns.push({
            field: 'project',
            headerName: t('project'),
            type: 'singleSelect',
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            headerClassName: "cc-fra__table--project",
            cellClassName: "cc-fra__table--project",
            renderCell(params) {
                return params.row.project?.label;
            },
            valueGetter: ({value}) => {
                return value?.id
            },
            getOptionValue: (value: any) => value?.id,
            getOptionLabel: (value: any) => value?.label,
            valueOptions: projects
        });
    }

    columns.push(
        {
            field: 'concept',
            headerName: t('concept'),
            type: 'string',
            flex: 3,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            headerClassName: "cc-fra__table--concept",
            cellClassName: "cc-fra__table--concept",
        },
        {
            field: 'revenue',
            headerName: t('income'),
            type: 'number',
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            headerClassName: "cc-fra__table--revenue",
            cellClassName: "cc-fra__table--revenue",
            valueFormatter: ({ value }) => formatCurrency(value, i18n.language),
            valueGetter: ({ value }) => parseFloat(value),
        },
        {
            field: 'state',
            headerName: t('state'),
            type: 'singleSelect',
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            headerClassName: "cc-fra__table--state",
            cellClassName: "cc-fra__table--state",
            renderCell(params) {
                return t(params.row.state.label);
            },
            valueGetter: ({ value }) => {
                return value.id
            },
            getOptionValue: (value: any) => value.id,
            getOptionLabel: (value: any) => t(value.label),
            valueOptions: states
        },
        {
            field: 'payment_days',
            headerName: t('payment-days'),
            type: 'number',
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            headerClassName: "cc-fra__table--payment-days",
            cellClassName: "cc-fra__table--payment-days",
        },
        {
            field: 'payment_date',
            headerName: t('payment-date'),
            type: 'date',
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            headerClassName: "cc-fra__table--payment-date",
            cellClassName: "cc-fra__table--payment-date",
            valueFormatter: ({ value }) => formatDate(value, i18n.language),
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: 'type',
            headerName: t('type'),
            type: 'singleSelect',
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            headerClassName: "cc-fra__table--type",
            cellClassName: "cc-fra__table--type",
            renderCell(params) {
                return t(params.row.type.label);
            },
            valueGetter: ({ value }) => {
                return value.id
            },
            getOptionValue: (value: any) => value.id,
            getOptionLabel: (value: any) => t(value.label),
            valueOptions: types,
        },
    );

    if (allowEditingActions) {
        columns.push(
            {
                field: 'actions',
                type: 'actions',
                headerName: t('actions'),
                flex: 0,
                headerClassName: "cc-fra__table--actions",
                cellClassName: "cc-fra__table--actions",
                getActions: ({ id }) => {
                    const isInEditMode = (rowModesModel[id]?.mode === GridRowModes.Edit);

                    if (isInEditMode) {
                        return allowEditingActions ? [
                            <GridActionsCellItem
                                key={`save-${id}`}
                                icon={<IconGeneric iconName="save-ico" className="mc-icon__generic--size-20" />}
                                label="Save"
                                onClick={handleSaveClick(id)}
                            />,
                            <GridActionsCellItem
                                key={`cancel-${id}`}
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id)}
                                color="inherit"
                            />,
                        ] : [];
                    }

                    return allowEditingActions ? [
                        <GridActionsCellItem
                            key={`edit-${id}`}
                            icon={<IconGeneric iconName="edit-ico" className="mc-icon__generic--size-20" />}
                            label="Edit"
                            className="textPrimary"
                            onClick={handleEditClick(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            key={`delete-${id}`}
                            icon={<IconGeneric iconName="delete-ico" className="mc-icon__generic--size-20" />}
                            label="Delete"
                            onClick={() => setDeleteId(Number(id))}
                            color="inherit"
                        />,
                    ] : [];
                },
            }
        )
    }

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find(row => row.id === id);
        if (editedRow && editedRow.isNew) {
            setRows(rows.filter(row => row.id !== id));
        }
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const getTypeRowClassName = (params: any) => {
        if (params && params.row && params.row.type && typeof params.row.type.id === 'number') {
            return params.row.type.id === 1 ? 'backoffice' : 'customer';
        }

        return 'backoffice';
    };

    const handleProcessRowUpdateError = useCallback((error: Error) => {
        showSnackbar(error.message, 'error');
    }, [showSnackbar]);


    const deleteRow = useCallback (async (id: number) =>
    {
        const client = new ApiClient();
        try{
            const response = await client.deleteInvoiceDetail(id);
            return response.data;
        } catch (error: any) {
            showSnackbar(error.response.data.message, 'error');
        }
    },[showSnackbar]);

    const handleDelete = useCallback(
        async () => {
            const response = await deleteRow(deleteId);

            if (response) {
                setRows(rows.filter(row => row.id !== deleteId));
                showSnackbar(t('row successfully deleted'),'success');

            }

            setDeleteId(0);
        },[deleteRow, rows, setRows, showSnackbar, deleteId, t]);

    const onConfirmDelete = useCallback(async (confirm: boolean) => {
        setOpen(false);

        if (confirm){
            await handleDelete();
        }else{
            setDeleteId(0);
        }
    }, [handleDelete, setDeleteId])


    useEffect(() => {
        setOpen(deleteId > 0);
    }, [deleteId]);

    const persistRow = useCallback(async (workingRow: GridRowModel, action: 'save' | 'update') =>
    {
        const client = new ApiClient();

        try {
            const parameters = {
                'payment_days': workingRow.payment_days,
                'concept': workingRow.concept.trim(),
                'revenue': workingRow.revenue,
                'payment_date': workingRow.payment_date ? format(workingRow.payment_date, 'yyyy-MM-dd') : '',
                'position': workingRow.position,
                'state_id': workingRow.state,
                'type_id': workingRow.type,
                'project_id': workingRow.project ?? null
            };

            if (action === 'save' && invoice?.id) {
                const response = await client.createInvoiceDetail({
                    ...parameters,
                    invoice_id: invoice.id
                });

                return response.data;
            }
            if (action === 'update') {
                const response = await client.updateInvoiceDetail(workingRow.id, parameters);

                return response.data;
            }
        } catch (error: any) {
            showSnackbar(error.response.data.message, 'error');
        }
    }, [invoice?.id, showSnackbar]);

    const processRowUpdate = useCallback(
        async (workingRow: GridRowModel, oldRow: GridRowModel) => {
            if (workingRow.isNew) {
                const response = await persistRow(workingRow, 'save');
                if (response) {
                    const processedRow =
                        {
                            ...workingRow,
                            id: response.id,
                            state: states.find((s: any) => s.id === response.state.id),
                            type: types.find((s: any) => s.id === response.type.id),
                            project: projects.find((s: any) => s.id === response.project?.id),
                            isNew: false
                        };
                    setRows(rows.map((row: any) => (row.id === workingRow.id ? processedRow : row)));
                    showSnackbar(t('row successfully saved'), 'success');
                    return processedRow;
                }

                return oldRow;
            } else {
                const normalizedOldRow = {
                    ...oldRow,
                    payment_date: oldRow.payment_date ? new Date(oldRow.payment_date) : null,
                    state: oldRow.state.id,
                    type: oldRow.type.id,
                    project: oldRow.project?.id
                }

                if (JSON.stringify(workingRow) === JSON.stringify(normalizedOldRow)) {
                    showSnackbar(t('row remain unchanged'), 'info');

                    return oldRow;
                }

                const response = await persistRow(workingRow, 'update');
                if (response) {
                    const processedRow = {
                        ...workingRow,
                        id: response.id,
                        state: states.find((s: any) => s.id === response.state.id),
                        type: types.find((s: any) => s.id === response.type.id),
                        project: projects.find((s: any) => s.id === response.project?.id),
                        isNew: false
                    };
                    setRows(rows.map((row: any) => (row.id === workingRow.id ? processedRow : row)));
                    showSnackbar(t('row successfully updated'), 'success');
                    return processedRow;
                }

                return oldRow;
            }
        }, [persistRow, rows, setRows, showSnackbar, states, types, projects, t]);

    return {
        getTypeRowClassName,
        handleRowEditStop,
        handleRowModesModelChange,
        handleProcessRowUpdateError,
        processRowUpdate,
        onConfirmDelete,
        rows,
        columns,
        setRows,
        rowModesModel,
        setRowModesModel,
        deleteDialog: open,
        snackbar,
        closeSnackbar,
    }
}