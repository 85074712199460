import React, {useState} from "react";
import {FilterOption} from "../../../domain/FilterOption";

interface Props{
    defaultOption: FilterOption
    options: FilterOption[]
    handleSelect: (id: number) => void
}
const InvoiceGroupSelector = ({defaultOption, options, handleSelect}: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<FilterOption>(defaultOption);

    const handleChange = (option: FilterOption) => {
        setIsOpen(false);
        setSelectedOption(option);
        handleSelect(option.id);
    }

    return (
        <>
            <div className={`cc-form__select cc-form__select-dropdown ${isOpen ? 'is-open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                {selectedOption.name}
                {isOpen && (
                    <ul className="cc-form__select-options">
                        {options.map((option: FilterOption) => (
                            <li
                                key={option.id}
                                className={`cc-form__select-item`}
                                onClick={() => {handleChange(option)}}
                            >
                                {option.name}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    );
}

export default InvoiceGroupSelector;