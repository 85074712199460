import { useContext, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../pages/App/Home";
import HeaderItem from "../../../Common/Header/HeaderItem";
import HeaderAccounts from "./HeaderAccounts";
import Loading from "../../../Common/Loading/Loading";

const SubsiteMenuAccounts = () => {
    const { t } = useTranslation();
    const { accounts, selectedAccount, setSelectedAccount } = useContext(AppContext);
    const [isCollapse, setIsCollapse] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const toggleCollapse = () => {
        setIsCollapse(!isCollapse);
    };

    useEffect(() => {
        const handleOverflowChange = () => {
            const container = containerRef.current;
            if (!container) return;

            const isOverflowing = container.scrollWidth > container.clientWidth;
            setIsOverflowing(isOverflowing);
        };

        handleOverflowChange();

        const resizeObserver = new ResizeObserver(handleOverflowChange);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        if (isCollapse) {
            const handleOutsideClickWrapper = (e: MouseEvent) => {
                if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
                    setIsCollapse(false);
                }
            };

            document.addEventListener("mousedown", handleOutsideClickWrapper);

            return () => {
                document.removeEventListener("mousedown", handleOutsideClickWrapper);
            };
        }
    }, [isCollapse]);

    return (
        <>
            <div ref={containerRef} className={"cc-header__sites" + (!isCollapse ? "" : " is-open") + (isOverflowing ? " is-overflow" : "")} >
                {accounts.length > 1 && (
                    <HeaderItem className={!selectedAccount ? "is-active" : ""}>
                        <button className="mc-button mc-button--secondary mc-button--no-border mc-button--header"
                            onClick={() => {
                                setSelectedAccount(null);
                                setIsCollapse(false);
                            }}
                        >
                            {t("all sites")}
                        </button>
                    </HeaderItem>
                )}
                <HeaderAccounts onClick={() => setIsCollapse(false)} />
                {accounts.length === 0 && <Loading />}
                {accounts.length > 1 && (
                    <div className={"cc-header__dropdown-arrow cc-header__dropdown-arrow--overflowed" + (!isCollapse ? "" : " is-open")}
                        onClick={toggleCollapse}
                    >
                        <span>···</span>
                    </div>
                )}
            </div>
        </>
    );
};

export default SubsiteMenuAccounts;