import React, {ReactNode, useCallback} from "react";

interface Props {
    children: ReactNode
    onChange?: () => void
    checked?: boolean
}

const Option = ({children, onChange, checked}: Props) => {
    const handleClick = useCallback(() => {
        if (!checked && onChange) onChange()
    }, [checked, onChange])

    return <li
        className={`cc-filter__group-item cc-filter__group-item--preset${checked ? ' is-active' : ''}`}
        onClick={handleClick}
    >{children}</li>
}

export default Option