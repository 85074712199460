import {ComparatorFactory} from "../../domain/EnhancedTableSorting";
import * as React from "react";
import {OrderCriteria} from "../../domain/Sorting";

interface TableRowData {
    [key: string]: any;
}

const useComparatorSortingEnhancedTable = <T extends TableRowData>() =>{
    const [order, setOrder] = React.useState<OrderCriteria>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof T>('datetime');
    const getComparator: ComparatorFactory<keyof T, T> = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {

        if (a[orderBy] === null || a[orderBy] === undefined) {
            return (b[orderBy] === null || b[orderBy] === undefined) ? 0 : 1;
        }

        if (b[orderBy] === null || b[orderBy] === undefined) {
            return -1;
        }

        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }

        return 0;
    }

    return {
        getComparator,
        order,
        setOrder,
        orderBy,
        setOrderBy
    }
}

export default useComparatorSortingEnhancedTable