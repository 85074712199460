import {useEffect, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import useDates from "./use-dates";
import {OrphanMetricData} from "../domain/OrphanMetricData";
import {DateRange} from "../domain/DateRange";
import {Partner} from "../domain/Partner";

interface Response {
    metrics: OrphanMetricData[]
    loading: boolean
}

interface GetDataParameters {
    from?: string
    to?: string
    partner_ids?: string
    project_match?: string
    page?: number
}

const useBackofficeOrphanMetricsData = (
    dateRange: DateRange,
    filteredPartners: Partner[],
    projectMatch: string,
    page: number
): Response => {
    const [loading, setLoading] = useState(true)
    const {formatDateCalendarPicker} = useDates();
    const client = useMemo(() => new ApiClient(), [])
    const [metricsData, setMetricsData] = useState<OrphanMetricData[]>(initialData)

    const parameters: GetDataParameters = useMemo(() => {
        const params = {};

        if (dateRange.start) {
            Object.assign(params, {from: formatDateCalendarPicker(dateRange.start)})
        }

        if (dateRange.end) {
            Object.assign(params, {to: formatDateCalendarPicker(dateRange.end)})
        }

        if (filteredPartners.length > 0) {
            Object.assign(params, {partner_ids: filteredPartners.map((p) => p.id).join(',')})
        }

        if (projectMatch) {
            Object.assign(params, {project_match: projectMatch})
        }

        if (page) {
            Object.assign(params, {page: page})
        }

        return params
    }, [dateRange, filteredPartners, projectMatch, page, formatDateCalendarPicker])

    useEffect(() => {
        setLoading(true);
        client
            .getOrphanMetrics(parameters)
            .then((response) => {
                const data: OrphanMetricData[] = response.data;
                setMetricsData(data)
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [client, parameters, setLoading])

    return {
        metrics: metricsData,
        loading
    };
}

const initialData: OrphanMetricData[] = [];

export default useBackofficeOrphanMetricsData