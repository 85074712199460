import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from "@mui/material/TableBody";
import PerformanceEnhancedTableDimensionsCell from "../../Table/PerformanceEnhancedTableDimensionsCell";
import { PartnerStats } from "../../../../domain/CompareStatsData";

type SubsiteDataEntry = [subsiteName: string, subsiteData: PartnerStats];

interface Props {
    partnerNames: string[];
    subsitesData: SubsiteDataEntry[];
}

function PerformanceEnhancedTableBodyBackOffice({ partnerNames, subsitesData }: Props) {
    return (
        <TableBody>
            {subsitesData.map(([subsite, pData], index: number) => (
                <TableRow key={`row-${index}`}>
                    <TableCell style={{ fontWeight: "bold" }}>{subsite}</TableCell>
                    {partnerNames.map((partnerName) => {
                        const rowData = pData[partnerName];
                        return (
                            <TableCell key={partnerName}>
                                <PerformanceEnhancedTableDimensionsCell data={rowData} />
                            </TableCell>
                        );
                    })}
                </TableRow>
            ))}
        </TableBody>
    );
}

export default PerformanceEnhancedTableBodyBackOffice;
