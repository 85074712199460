import {HeadCell, TableDataDevices} from "../../../../domain/TableData";
import {useTranslation} from "react-i18next";
import useDates from "../../../../hooks/use-dates";
import {useMemo} from "react";
import {DevicesData} from "../../../../domain/StatsData";
import DevicesEnhancedTableRow from "./DevicesEnhancedTableRow";
import EnhancedTableDimensions from "../../Common/Table/EnhancedTableDimensions";
import DeviceTotalsRow from "./DevicesTotalsRow";
import usePaginationContext from "../../../../hooks/context/use-pagination-context";
import {ErrorComponent} from "../../../Common/ErrorComponent";
import * as React from "react";


const headCells: readonly HeadCell<TableDataDevices>[] = [
    {
        id: 'datetime',
        numeric: true,
        disablePadding: false,
        label: 'day',
        sortable: true,
    },
    {
        id: 'desktop',
        numeric: true,
        disablePadding: false,
        label: 'Desktop',
        sortable: true,
    },
    {
        id: 'mobile',
        numeric: true,
        disablePadding: false,
        label: 'Mobile',
        sortable: true,
    },
    {
        id: 'tablet',
        numeric: true,
        disablePadding: false,
        label: 'Tablet',
        sortable: true,
    },
    {
        id: 'other',
        numeric: true,
        disablePadding: false,
        label: 'other',
        sortable: true,
    },
    {
        id: 'total',
        numeric: true,
        disablePadding: false,
        label: 'Total',
        sortable: true,
    },
];

interface Props {
    data: {
        days: DevicesData[]
        summary: DevicesData
    }
}

const DevicesTableData = ({data}: Props) =>{
    const {t, i18n} = useTranslation();
    const {getDayName} = useDates()
    const paginationContextData = usePaginationContext();
    const days = data?.days;
    const totals = data?.summary;

    const rows: TableDataDevices[] = useMemo(() =>  days ? Object.entries(days).map(([dateStr, day]) => {
        const date =  new Date(dateStr);
        const stat : DevicesData = day;

        return {
            datetime: date.getTime(),
            dayWeek: getDayName(date, i18n.language),
            desktop: stat.desktop,
            mobile: stat.mobile,
            tablet: stat.tablet,
            other: stat.other,
            total: stat.total
        }
    }
    ) : []
    , [days, getDayName, i18n.language])

    if (0 === rows.length) {
        return <ErrorComponent title={t("no-results")} subtitle={t("try to modify the active filters to get results")} />
    }

    return(
        <EnhancedTableDimensions
            headCells={headCells}
            rows={rows}
            totals={totals}
            RowComponent={DevicesEnhancedTableRow}
            TotalsComponent={DeviceTotalsRow}
            paginationSettings={
                {
                    key: 'stats',
                    rowsPerPage: paginationContextData.getRowsPerPage('stats') ?? 25,
                    rowsPerPageOptions: [25, 50, 75, 100, { label: t('all'), value: -1 }]
                }
            }
        />
    )
}

export default DevicesTableData;