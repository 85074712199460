import React, {useMemo} from 'react';
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import {useNavigate, useParams} from "react-router-dom";
import ApiClient from "../../../client/ApiClient";
import {Alert, Snackbar} from "@mui/material";
import {useSnackbar} from "../../../hooks/ui/use-snackbar";
import {useTranslation} from "react-i18next";
import EditProjectForm from "../../../components/BackOffice/Project/EditProjectForm";
import useProjectData from "../../../hooks/use-project-data";
import {ProjectFormData} from "../../../components/BackOffice/Project/ProjectForm";

const EditProjectBackOffice = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const client = useMemo(() => new ApiClient(), []);
    const {snackbar, showSnackbar, closeSnackbar} = useSnackbar();
    const { projectId } = useParams<{ projectId: string }>();
    const {project} = useProjectData(projectId);

    if (!project) {
        return <LoadingFull />
    }

    const handleEdit = async (formData: ProjectFormData) => {
        try {
            const response = await client.updateProject(project.id, formData);
            navigate('/backoffice/projects')

            return response.data;
        } catch (error) {
            showSnackbar(t('update project error'), 'error');
        }
    }

    const handleCancel = () => {
        navigate('/backoffice/projects')
    }

    const handleDelete = async (id: number) => {
        try {
            await client.deleteProject(id);
            navigate('/backoffice/projects')
        } catch (error) {
            showSnackbar(t('delete project error'), 'error');
        }
    }

    return (
        <>
            <EditProjectForm
                project={project}
                onSave={handleEdit}
                onCancel={handleCancel}
                onDelete={handleDelete} />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={closeSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={closeSnackbar} />
                </Snackbar>
            )}
        </>
    );
};

export default EditProjectBackOffice;
