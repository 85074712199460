import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { HeadCell } from "../../../domain/TableData";
import {useTranslation} from "react-i18next";

interface EnhancedTableHeadProps<T> {
    headCells: readonly HeadCell<T>[],
    rowCount: number;
}

function HistoricalEnhancedTableHeadBackOffice<T>(props: EnhancedTableHeadProps<T>) {
    const { headCells} = props;
    const {t} = useTranslation();

    const getRandomKey = () => {
        return Math.random().toString(36).slice(2, 9); // Generates a random alphanumeric string
    }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={getRandomKey() as string}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {t(headCell.label)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default HistoricalEnhancedTableHeadBackOffice;
