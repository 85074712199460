import React, {useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceGroupForm, {InvoiceGroupFormData} from './InvoiceGroupForm';
import BackButton from "../../Common/BackButton";

interface Props{
    onSave: (formData: InvoiceGroupFormData) => void;
    onCancel: () => void;
}

const AddInvoiceGroupForm = ({ onSave, onCancel }: Props) => {
    const { t } = useTranslation();
    const invoiceGroupFormData: InvoiceGroupFormData = {
        name: '',
        company_name: '',
        tax_identifier: '',
        emails_admin: ''
    };

    const handleSave = useCallback((formData: InvoiceGroupFormData) => {
        onSave(formData);
    },[onSave]);

    return (
        <>
            <BackButton to=".." />
            <div className='cc-card cc-card--invoice'>
                <InvoiceGroupForm
                    initialValues={invoiceGroupFormData}
                    onSave={handleSave}
                    onCancel={onCancel}
                    btnSaveText={t('add')}
                />
            </div>
        </>
    );
};

export default AddInvoiceGroupForm;