import {createBrowserRouter, RouterProvider} from "react-router-dom";
import './App.scss';
import Home from "./pages/App/Home";
import Error from "./pages/Common/Error";
import ListGeneralData from "./pages/App/General/ListGeneralData";
import ListFormatsData from "./pages/App/Formats/ListFormatsData";
import ListEnvironmentsData from "./pages/App/Environments/ListEnvironmentsData";
import ListDevicesData from "./pages/App/Devices/ListDevicesData";
import ListInvoices from "./pages/App/Invoices/ListInvoices";
import Login from "./pages/Common/Login";
import Unauthorized from "./pages/Common/Unauthorized";
import HomeBackOffice from "./pages/BackOffice/HomeBackOffice";
import ListGeneralDataBackOffice from "./pages/BackOffice/General/ListGeneralDataBackOffice";
import ListInvoicesBackOffice from "./pages/BackOffice/Invoices/ListInvoicesBackOffice";
import SingleInvoiceBackOffice from "./pages/BackOffice/Invoices/SingleInvoiceBackOffice";
import RevenueShareBackOffice from "./pages/BackOffice/RevenueShare/RevenueShareBackOffice";
import ListPendingInvoicesBackOffice from "./pages/BackOffice/PendingInvoices/ListPendingInvoicesBackOffice";
import ListHistoricalDataBackOffice from "./pages/BackOffice/Historical/ListHistoricalDataBackOffice";
import ListInvoiceGroupsBackOffice from "./pages/BackOffice/InvoiceGroup/ListInvoiceGroupsBackOffice";
import AddInvoiceGroupBackOffice from "./pages/BackOffice/InvoiceGroup/AddInvoiceGroupBackOffice";
import EditInvoiceGroupBackOffice from "./pages/BackOffice/InvoiceGroup/EditInvoiceGroupBackOffice";
import ListProjectsBackOffice from "./pages/BackOffice/Project/ListProjectsBackOffice";
import EditProjectBackOffice from "./pages/BackOffice/Project/EditProjectBackOffice";
import AddProjectBackOffice from "./pages/BackOffice/Project/AddProjectBackOffice";
import Authentication from "./pages/Authentication";
import OrphanMetricsBackOffice from "./pages/BackOffice/OrphanMetrics/OrphanMetricsBackOffice";
import PerformanceBackOffice from "./pages/BackOffice/Performance/PerformanceBackOffice";

const App = () => {
    const router = createBrowserRouter([
        {
            path: "/auth/:token",
            element: <Authentication />
        },
        {
            path: "/unauthorized",
            element: <Unauthorized />
        },
        {
            path: "/login",
            element: <Login />
        },
        {
            path: "/",
            element: <Home />,
            errorElement: <Error/>,
            children: [
                {
                    path: "/",
                    element: <ListGeneralData/>,
                },
                {
                    path: "/formats/",
                    element: <ListFormatsData/>,
                },
                {
                    path: "/environments/",
                    element: <ListEnvironmentsData/>,
                },
                {
                    path: "/devices/",
                    element: <ListDevicesData/>,
                },
                {
                    path: "/invoices/",
                    element: <ListInvoices/>,
                },
            ]
        },
        {
            path: "/backoffice",
            element: <HomeBackOffice />,
            errorElement: <Error/>,
            children: [
                {
                    path: "/backoffice",
                    element: <ListGeneralDataBackOffice/>,
                },
                {
                    path: "/backoffice/invoices",
                    element: <ListInvoicesBackOffice/>,
                },
                {
                    path: "/backoffice/invoices/pending",
                    element: <ListPendingInvoicesBackOffice/>,
                },
                {
                    path: "/backoffice/invoices/:invoiceId",
                    element: <SingleInvoiceBackOffice/>,
                },
                {
                    path: "/backoffice/revenue-share",
                    element: <RevenueShareBackOffice/>,
                },
                {
                    path: "/backoffice/invoice-groups",
                    element: <ListInvoiceGroupsBackOffice/>,
                },
                {
                    path: "/backoffice/invoice-groups/create",
                    element: <AddInvoiceGroupBackOffice/>,
                },
                {
                    path: "/backoffice/invoice-groups/:invoiceGroupId/edit",
                    element: <EditInvoiceGroupBackOffice/>,
                },
                {
                    path: "/backoffice/projects",
                    element: <ListProjectsBackOffice/>,
                },
                {
                    path: "/backoffice/projects/create",
                    element: <AddProjectBackOffice/>,
                },
                {
                    path: "/backoffice/projects/:projectId/edit",
                    element: <EditProjectBackOffice/>,
                },
                {
                    path: "/backoffice/historical",
                    element: <ListHistoricalDataBackOffice/>,
                },
                {
                    path: "/backoffice/orphan-metrics",
                    element: <OrphanMetricsBackOffice/>,
                },
                {
                    path: "/backoffice/performance",
                    element: <PerformanceBackOffice/>,
                },
            ]
        },
    ]);

    return <RouterProvider router={router} />
}

export default App
