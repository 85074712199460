import {useTranslation} from "react-i18next";
import React from "react";
import Heading from "../../../components/Common/Heading";
import useBackofficeOrphanMetricsData from "../../../hooks/use-backoffice-orphan-metrics-data";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import OrphanMetricsGrid from "../../../components/BackOffice/OrphanMetrics/OrphanMetricsGrid";
import useBackofficeFilters from "../../../hooks/filters/use-backoffice-filters";
import useFiltersOrphanMetricsContext from "../../../hooks/context/use-filters-orphan-metrics-context";
import FiltersOrphanMetrics from "../../../components/BackOffice/Filters/OrphanMetrics/FiltersOrphanMetrics";

const OrphanMetricsBackOffice = () => {
    const {t} = useTranslation();
    const {dateRange, filteredPartners, projectMatch, page} = useFiltersOrphanMetricsContext();
    const { partners} = useBackofficeFilters()
    const { metrics, loading } = useBackofficeOrphanMetricsData(
        dateRange,
        filteredPartners,
        projectMatch,
        page
    )

    if (loading) {
        return <LoadingFull/>
    }

    return(
        <>
            <Heading title={t('orphan-metrics')} />
            <FiltersOrphanMetrics dateRange={dateRange} partners={partners} projectMatch={projectMatch}></FiltersOrphanMetrics>
            <OrphanMetricsGrid
                partners={partners}
                metrics={metrics}
            />
        </>
    )
}

export default OrphanMetricsBackOffice