import {HTMLAttributes} from "react";

type Props = HTMLAttributes<HTMLDivElement>

const HeaderItem = ({children, className = "", ...props}: Props) => {
    return (
        <div className={"cc-header__item" + (className ? " " + className : "")} {...props}>
            {children}
        </div>
    )
}

export default HeaderItem;