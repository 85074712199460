import HistoricalTableDataBackOffice from "../../../components/BackOffice/Historical/HistoricalTableDataBackOffice";
import FiltersHistorical from "../../../components/BackOffice/Filters/Historical/FiltersHistorical";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import useBackOfficeHistoricalData from "../../../hooks/use-backoffice-historical-data";
import useFiltersHistoricalContext from "../../../hooks/context/use-filters-historical-context";
import {useTranslation} from "react-i18next";
import Heading from "../../../components/Common/Heading";

const ListHistoricalDataBackOffice = () => {
    const {t} = useTranslation()
    const {historicalData, loading} = useBackOfficeHistoricalData();
    const { filteredSubsites } = useFiltersHistoricalContext();

    if (loading) {
        return <LoadingFull/>
    }

    return (
        <>
            <Heading title={t('historical')} />
            <FiltersHistorical/>
            {filteredSubsites.length === 0 && (
                <div className="cc-table cc-card">
                    <p>{t('try to modify the active filters to get results')}</p>
                </div>
            )}
            {filteredSubsites.length > 0 && <HistoricalTableDataBackOffice data={
                {
                    historical: historicalData.historical,
                    totals: historicalData.totals,
                }
            }
            />
            }
        </>
    )

}
export default ListHistoricalDataBackOffice