import { useEffect, useMemo, useState} from "react";
import {Project} from "../domain/Subsite";
import ApiClient from "../client/ApiClient";

interface Response {
    projects: Project[],
    loading: boolean
}

const useBackofficeProjectsData = () : Response => {
    const [loading, setLoading] = useState(true)
    const client = useMemo(() => new ApiClient(), [])
    const [projects, setProjects] = useState<Project[]>([]);

    useEffect(() => {
        setLoading(true);
        client
            .getAllProjects()
            .then((response) => {
                setProjects(response.data)
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [client, setProjects, setLoading]);

    return {
        projects,
        loading
    };
}

export default useBackofficeProjectsData;