import IconGeneric from "../../Icon/IconGeneric";
import {FilterOption} from "../../../../domain/FilterOption";

interface Props{
    list: FilterOption[],
    onClearAll: () => void
}
const ClearSelectedGroupFilters = ({list, onClearAll}: Props) =>{

    return(
        <>
            { list.length > 0 &&
                <button className='mc-button mc-button--small mc-button--delete-filters' onClick={onClearAll}>
                    <IconGeneric iconName="delete-ico" className="mc-icon__generic--size-20 is-primary" />
                </button>
            }
        </>
    )

}

export default ClearSelectedGroupFilters;