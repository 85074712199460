import {CalendarIntervalSelector} from "../../../Common/DateFilter/CalendarIntervalSelector";
import MultiSelectFilter from "../../../Common/Filters/MultiSelectFilter/MultiSelectFilter";
import {Subsite} from "../../../../domain/Subsite";
import React from "react";
import useFiltersRevenueShareContext from "../../../../hooks/context/use-filters-revenue-context";
import useDates from "../../../../hooks/use-dates";
import {DateRange} from "../../../../domain/DateRange";

interface Props{
    dateRange: DateRange,
    subsites: Subsite[]
}
const FiltersRevenueShare = ({dateRange, subsites}: Props) => {
    const { getFirstDayOfMonth, getLastDayOfMonth } = useDates();
    const {
        setDateRange,
        filteredSubsites,
        setFilteredSubsites,
    } = useFiltersRevenueShareContext();

    return (
        <div className="cc-filter">
            <div className="cc-filter__wrapper">
                <CalendarIntervalSelector
                    defaultStartDate={dateRange.start ?? getFirstDayOfMonth(new Date())}
                    defaultEndDate={dateRange.end ?? getLastDayOfMonth(new Date())}
                    setDateRange={setDateRange}
                />
                <MultiSelectFilter
                    name={'subsites'}
                    values={subsites.map((ss: Subsite) => {
                        return {
                            id: ss.id,
                            name: ss.domain
                        }
                    })}
                    filteredValues={filteredSubsites}
                    setFilteredValues={setFilteredSubsites}
                />
            </div>
        </div>
    )
}

export default FiltersRevenueShare