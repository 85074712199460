export type DateRangeOptions = 'custom'|'last_7_days'|'last_30_days'|'current_month'|'last_month'|'last_3_months'|'current_year'|'last_year';

export interface DateRange {
    label: DateRangeOptions
    start: Date|null
    end: Date|null
}

export const dateRangeOptions: DateRangeOptions[] = [
    'last_7_days',
    'last_30_days',
    'current_month',
    'last_month',
    'last_3_months',
    'current_year',
    'last_year',
];
