import {PaymentHistoryRow, TableDataInvoices} from "../../../domain/TableData";
import {useTranslation} from "react-i18next";
import useNumbers from "../../../hooks/use-numbers";
import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconGeneric from "../../Common/Icon/IconGeneric";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import useInvoiceStates from "../../../hooks/use-invoice-states";
import useDates from "../../../hooks/use-dates";
import useInvoiceDownloadPDF from "../../../hooks/use-invoice-download-pdf";
import CircularProgress from '@mui/material/CircularProgress';

const InvoicesEnhancedTableRow = (props: { id: string, row: TableDataInvoices }) => {
    const { i18n, t} = useTranslation();
    const {formatDatePeriod} = useDates()
    const {formatCurrency} = useNumbers()
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const {getCssClass} = useInvoiceStates();

    const invoiceStateCss = getCssClass(row.state.label);
    const {download: downloadPDF, loading} = useInvoiceDownloadPDF(row.id);

    const downloadIcon = () => {
        return loading
            ? <CircularProgress size="12px" />
            : <><IconGeneric iconName="download-ico" className="mc-icon__generic mc-icon__generic--size-12" /><span className="mc-button--label">{t('download')}</span></>
    }

    return (
        <>
            <TableRow className={`cc-fra ${invoiceStateCss}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell scope="row">
                    <div className="oc-group oc-group--half-gap oc-group--nowrap oc-group--center">
                        <IconGeneric iconName={open ? "arrow-up-ico" : "arrow-down-ico"} className="mc-button mc-button--circle mc-button--auto mc-icon__generic--size-16" onClick={() => setOpen(!open)} />
                        <span className='cc-fra__company_name'>{row.company_name}</span>
                    </div>
                </TableCell>
                <TableCell><span className='cc-fra__reference'>{row.reference}</span></TableCell>
                <TableCell><span className='cc-fra__period'>{formatDatePeriod(row.creation_date, i18n.language)}</span></TableCell>
                <TableCell><span className='cc-fra__period'>{formatDatePeriod(row.start_period, i18n.language)}</span></TableCell>
                <TableCell><span className='cc-fra__period'>{formatDatePeriod(row.end_period, i18n.language)}</span></TableCell>
                <TableCell><span className='cc-fra__state'>{t(row.state.label)}</span></TableCell>
                <TableCell><span className={'mc-data'}>{formatCurrency(row.income, i18n.language)}</span></TableCell>
                <TableCell>
                    <div className="oc-group oc-group--half-gap" style={{minWidth: '80px'}}>
                        <a onClick={downloadPDF} className='mc-button mc-button--secondary is-small'>
                            { downloadIcon() }
                        </a>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow className='no-border'>
                <TableCell style={{ padding: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table className='cc-table cc-table--redux cc-card' size="small" aria-label="purchases">
                                <TableBody>
                                    {row.payments_history.map((historyRow: PaymentHistoryRow) => {
                                        const invoiceDetailStateCss = getCssClass(historyRow.state.label);
                                        return (
                                            <TableRow  className={`cc-fra cc-fra--redux ${invoiceDetailStateCss}`} key={historyRow.date}>
                                                <TableCell width={'25%'}><span className='cc-fra__payment'>{historyRow.payment_number}</span> <span className='mc-date mc-date--mini'>{historyRow.date}</span></TableCell>
                                                <TableCell width={'25%'}><span className='cc-fra__state'>{t(historyRow.state.label)}</span></TableCell>
                                                <TableCell width={'50%'}><span className='mc-data mc-data--redux'>{formatCurrency(historyRow.revenue, i18n.language)}</span></TableCell>
                                            </TableRow>
                                        )}
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default InvoicesEnhancedTableRow;