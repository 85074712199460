import PropTypes from 'prop-types';

const Heading = ( { title, text}: { title: string, text: string } ) => {
    return (
        <div className="mc-heading">
            <div className="mc-heading__title">
                { title }
            </div>
            {(() => {
                if (text) {
                    return <div className="mc-heading__text">{ text }</div>;
                }
            })()}
        </div>
    );
};

Heading.propTypes = {
    title: PropTypes.string.isRequired,
}

Heading.defaultProps = {
    text: '',
}

export default Heading;
