import React, { useMemo } from "react";
import useNumbers from "../../../../hooks/use-numbers";
import { useTranslation } from "react-i18next";

interface Props {
    label: string
    value: string
    color: string
    total: number
}

const DimensionsChartTooltipItem = ({ label, value, color, total }: Props) => {
    const { i18n } = useTranslation();
    const { formatCompactCurrency, formatPercent } = useNumbers();

    const val = useMemo(() => parseFloat(value), [value])
    const resolvedValue = formatCompactCurrency(val, i18n.language, 2);
    const isValidResolvedValue = '-' !== resolvedValue
    const percent = formatPercent(val / total, i18n.language, 2);

    return (
        <div className="cc-chart__tooltip-wrapper">
            <div className="cc-chart__tooltip-item">
                <span className="cc-chart__tooltip-item-name" style={{ color: color }}>{label}</span>
                <div className="cc-chart__tooltip-item-value">

                    {isValidResolvedValue ? (
                        <>
                            <div>{resolvedValue}</div>
                            <div className="cc-chart__tooltip-item-value--old">({percent})</div>
                        </>
                    ) : (
                        <div>-</div>
                    )}

                </div>
            </div>
        </div>
    )
}

export default DimensionsChartTooltipItem