import {useEffect, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import {HistoricalData, HistoricalDataLine, HistoricalDataValues} from "../domain/TableData";
import useFiltersHistoricalContext from "./context/use-filters-historical-context";

interface Response {
    historicalData: {
        historical: HistoricalDataLine[],
        totals: HistoricalDataValues,
    },
    loading: boolean
}

interface GetDataParameters {
    pids: string
}

const initialData: HistoricalData = {
    historical: [],
    totals: {income:0 , income_dashboard: 0, differential: 0, differential_percentage: 0},
}

const useBackOfficeHistoricalData = (): Response => {
    const [loading, setLoading] = useState(true)
    const client = useMemo(() => new ApiClient(), [])
    const {filteredSubsites} = useFiltersHistoricalContext();
    const [historicalData, setHistoricalData] = useState<HistoricalData>(initialData)

    const parameters: GetDataParameters = useMemo(() => {
        return {
            pids: (filteredSubsites.map((subsite => subsite.id))).join(',')
        }
    }, [filteredSubsites])

    useEffect(() => {
        if (filteredSubsites.length === 0) {
            setLoading(false);
            setHistoricalData(initialData);
        } else {
            setLoading(true);
            client
                .getHistoricalData(parameters)
                .then((response) => {
                    const data: HistoricalData = response.data;
                    setHistoricalData(data);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [client, parameters, filteredSubsites, setLoading]);

    return {
        historicalData:{
            historical: historicalData.historical,
            totals: historicalData.totals
        },
        loading: loading
    };
}

export default useBackOfficeHistoricalData