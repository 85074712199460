import {useCallback, useState} from "react";
import ApiClient from "../client/ApiClient";

interface Response{
    download: () => void,
    loading: boolean
}
const useInvoiceDownloadPDF = (id: number): Response => {
    const [loading, setLoading] = useState(false);

    const download = useCallback(() => {
        const client = new ApiClient();
        setLoading(true);
        client.downloadInvoicePdf(id).then((blobData) => {
            const url = window.URL.createObjectURL(new Blob([blobData]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `order-${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
        }).catch((error) => {
            console.error('Error downloading invoice:', error);
        }).finally(() => {
            setLoading(false);
        });
    }, [id]);

    return {
        download,
        loading
    }

}

export default useInvoiceDownloadPDF