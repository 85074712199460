import {useEffect, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import {DateRange, DateRangeOptions} from "../domain/DateRange";
import useDates from "./use-dates";
import {CompareStats, CompareStatsApiResponse } from "../domain/CompareStatsData";

interface Response {
    stats: CompareStats
    loading: boolean
}

interface GetDataParameters {
    label: DateRangeOptions
    from?: string
    to?: string
}

const useBackOfficeComparedStatsData = (
    dateRange: DateRange,
) : Response => {
    const [loading, setLoading] = useState(true)
    const {formatDateCalendarPicker} = useDates()
    const [data, setData] = useState<CompareStatsApiResponse>({stats: {}})
    const client = useMemo(() => new ApiClient(), [])

    const parameters: GetDataParameters = useMemo(() => {
        const params = {
            label: dateRange.label,
        }

        if (dateRange.start) {
            Object.assign(params, {from: formatDateCalendarPicker(dateRange.start)})
        }

        if (dateRange.end) {
            Object.assign(params, {to: formatDateCalendarPicker(dateRange.end)})
        }

        return params
    }, [dateRange, formatDateCalendarPicker])

    useEffect(() => {
        setLoading(true);
        client
            .getComparedStats(parameters)
            .then((response) => {
                const data: CompareStatsApiResponse = response.data;
                setData({stats: data.stats});
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [client, parameters, setLoading]);


    return {
        stats: data.stats,
        loading
    }
}

export default useBackOfficeComparedStatsData