import {HeadCell, HistoricalDataLine, HistoricalDataValues} from "../../../domain/TableData";
import HistoricalEnhancedTableBackOffice from "./HistoricalEnhancedTableBackOffice";
import usePaginationContext from "../../../hooks/context/use-pagination-context";
import {useTranslation} from "react-i18next";
import {ErrorComponent} from "../../Common/ErrorComponent";

const headCells: readonly HeadCell<HistoricalDataLine>[] = [
    {
        id: 'period',
        numeric: false,
        disablePadding: false,
        label: 'period',
        sortable: true,
    },
    {
        id: 'domain',
        numeric: false,
        disablePadding: false,
        label: 'domain',
        sortable: true,
    },
    {
        id: 'income',
        numeric: true,
        disablePadding: false,
        label: 'income',
        sortable: true,
    },
    {
        id: 'income_dashboard',
        numeric: true,
        disablePadding: false,
        label: 'income dashboard',
        sortable: true,
    },
    {
        id: 'differential',
        numeric: true,
        disablePadding: false,
        label: 'differential',
        sortable: true,
    },
    {
        id: 'differential_percentage',
        numeric: true,
        disablePadding: false,
        label: 'differential-percentage',
        sortable: true,
    },
];

interface Props {
    data : {
        historical: HistoricalDataLine[];
        totals: HistoricalDataValues;
    }
}

const HistoricalTableDataBackOffice = ({data}: Props) =>{
    const {t} = useTranslation()
    const paginationContextData = usePaginationContext();

    if (0 === data.historical.length || !data.totals) {
        return <ErrorComponent title={t("no-results")} subtitle={t("try to modify the active filters to get results")} />
    }

    return(
        <>
            <HistoricalEnhancedTableBackOffice
                headCells={headCells}
                rows={data.historical}
                totals={data.totals}
                paginationSettings={
                    {
                        key: 'historical-stats',
                        rowsPerPage: paginationContextData.getRowsPerPage('historical-stats') ?? 12,
                        rowsPerPageOptions: [12, 24, 36, 48, 60, { label: t('all'), value: -1 }]
                    }
                }
            />
        </>
    )
}

export default HistoricalTableDataBackOffice;