import {Outlet} from "react-router-dom";
import {createContext} from "react";
import {FiltersContext, useFiltersContextData} from "../../hooks/context/use-filters-context";
import LoadingFull from "../../components/Common/Loading/LoadingFull";
import {useBackofficeAuthentication} from "../../hooks/use-backoffice-authentication";
import Header from "../../components/Common/Header/Header";
import Sidebar from "../../components/Common/Sidebar";
import {PaginationContext, usePaginationContextData} from "../../hooks/context/use-pagination-context";
import {FiltersHistoricalContext, useFiltersHistoricalContextData} from "../../hooks/context/use-filters-historical-context";
import { FiltersRevenueShareContext,  useFiltersRevenueShareContextData} from "../../hooks/context/use-filters-revenue-context";
import {
    FiltersOrphanMetricsContext, useFiltersOrphanMetricsContextData,
} from "../../hooks/context/use-filters-orphan-metrics-context";

interface BackOfficeContextInterface {
    isAdmin: boolean;
}

export const BackOfficeContext = createContext<BackOfficeContextInterface>({} as BackOfficeContextInterface)

const HomeBackOffice = () => {
    const filtersContextData = useFiltersContextData();
    const filtersHistoricalContextData = useFiltersHistoricalContextData();
    const filtersRevenueShareContextData= useFiltersRevenueShareContextData();
    const filtersOrphanMetricsContextData= useFiltersOrphanMetricsContextData();

    const paginationContextData = usePaginationContextData();
    const {isAdmin} = useBackofficeAuthentication()

    if (!isAdmin) {
        return <LoadingFull/>
    }

    const accountContextData = {
        isAdmin: isAdmin
    }

    return (
        <BackOfficeContext.Provider value={accountContextData}>
            <FiltersContext.Provider value={filtersContextData}>
                <FiltersHistoricalContext.Provider value={filtersHistoricalContextData}>
                    <FiltersRevenueShareContext.Provider value={filtersRevenueShareContextData}>
                        <FiltersOrphanMetricsContext.Provider value={filtersOrphanMetricsContextData}>
                            <PaginationContext.Provider value={paginationContextData}>
                                <div className={"comitium-advertising" + (isAdmin ? " is-admin" : "")} data-testid="home-backoffice">
                                    <Header />
                                    <div className="oc-flex oc-flex--no-wrap cc-main__wrapper">
                                        <Sidebar/>
                                        <div className="cc-main">
                                            <Outlet/>
                                        </div>
                                    </div>
                                </div>
                            </PaginationContext.Provider>
                        </FiltersOrphanMetricsContext.Provider>
                    </FiltersRevenueShareContext.Provider>
                </FiltersHistoricalContext.Provider>
            </FiltersContext.Provider>
        </BackOfficeContext.Provider>
    );
}
export default HomeBackOffice