import {useCallback, useEffect, useState} from "react";
import ApiClient from "../../client/ApiClient";
import {InvoiceGroup} from "../../domain/InvoiceGroup";


const useBackOfficeInvoiceGroups = () => {
    const [invoiceGroups, setInvoiceGroups] = useState<InvoiceGroup[]>([]);

    const getInvoiceGroups = useCallback(async () => {
        const client = new ApiClient();

        try {
            const response = await client.getAllInvoiceGroupsBackOffice()
            setInvoiceGroups(response.data)
        } catch (exception) {
            console.log(exception)
            setInvoiceGroups([])
        }
    }, [setInvoiceGroups])

    useEffect(() =>{
        getInvoiceGroups().then()
    }, [getInvoiceGroups])

    return {
        invoiceGroups
    }
}

export default useBackOfficeInvoiceGroups;