import IconGeneric from "./Icon/IconGeneric";
import React, {useContext, useState} from "react";
import SidebarItemsList from "../App/Common/Sidebar/SidebarItemsList";
import SidebarItemsListBackOffice from "../BackOffice/Sidebar/SidebarItemsListBackOffice";
import {AppContext} from "../../pages/App/Home";
import {BackOfficeContext} from "../../pages/BackOffice/HomeBackOffice";
import {useTranslation} from "react-i18next";

const Sidebar = () => {
    const { accounts } = useContext(AppContext);
    const { isAdmin } = useContext(BackOfficeContext);
    const [isCollapse, setIsCollapse] = useState(false);
    const toggleCollapse = () => {setIsCollapse(!isCollapse);};
    const {t} = useTranslation();

    return <>
        <div className={`cc-sidebar__collapse ${!isCollapse ? '' : 'is-hidden'}`} onClick={toggleCollapse}>
            <span className="cc-sidebar__collapse--button">
                <IconGeneric iconName="arrow-right-ico" className="mc-icon__generic--size-10" />
            </span>
        </div>

        <div className={`cc-sidebar ${!isCollapse ? '' : 'is-hidden'}`}>
            <div className="cc-sidebar__item-list">
                {isAdmin && <SidebarItemsListBackOffice/>}
                {!isAdmin && accounts && <SidebarItemsList/>}
            </div>
            <div className="cc-sidebar__version">
                <IconGeneric iconName="logo-advertising-iso" className="mc-icon mc-icon__generic--size-16"/>
                <span>{t('version')}<strong>&nbsp;Beta</strong></span>
            </div>
        </div>
    </>
}

export default Sidebar