import useFiltersHistoricalContext from "../../../../hooks/context/use-filters-historical-context";
import useBackofficeFilters from "../../../../hooks/filters/use-backoffice-filters";
import MultiSelectFilter from "../../../Common/Filters/MultiSelectFilter/MultiSelectFilter";
import {useMemo} from "react";

const FiltersHistorical = () => {
    const {filteredSubsites, setFilteredSubsites} = useFiltersHistoricalContext();
    const {subsites} = useBackofficeFilters();

    const subsitesOptions = useMemo(() => {
        return subsites.map((subsite) => {
            return {
                id: subsite.id,
                name: subsite.domain
            }
        })
    },[subsites])

    return <div className="cc-filter">
        <div className='cc-filter__wrapper'>
            <div className="cc-filter__wrapper">
                <MultiSelectFilter
                    name={'subsites'}
                    values={subsitesOptions}
                    filteredValues={filteredSubsites}
                    setFilteredValues={setFilteredSubsites}
                />
            </div>
        </div>
    </div>
};

export default FiltersHistorical;
