import IconGeneric from "../../Icon/IconGeneric";
import {FilterOption} from "../../../../domain/FilterOption";

interface Props {
    values: FilterOption[]
    setValues: (values: FilterOption[]) => void
}

const AppliedFilters = ({values, setValues}:Props) =>{
    return (
        <>
            {values.map((val: any) => {
                return (
                    <div
                        key={val.id}
                        className="cc-filter__item"
                        onClick={(event) => {
                            event.stopPropagation();
                            setValues(values.filter((v) => v.id != val.id))
                        }}
                        title={val.name ?? val.domain}
                    >
                        <span className="cc-filter__item-text">{val.name ?? val.domain}</span>
                        <IconGeneric
                            iconName="close-thick-ico"
                            className="mc-icon__generic--size-7"
                        />
                    </div>
                );
            })}
        </>
    );
}

export default AppliedFilters;