import React from "react";
import Option from "./Option";
import {useTranslation} from "react-i18next";
import {DateRangeOptions, dateRangeOptions} from "../../../domain/DateRange";

const OptionList = ({selectedOption, onSelectOption}: {selectedOption: string, onSelectOption: (option: DateRangeOptions) => void}) => {
    const {t} = useTranslation()

    return (
        <div className="cc-filter__list">
            <ul className="cc-filter__group cc-filter__group--preset">
                {dateRangeOptions.map((option: DateRangeOptions) => {
                    return (
                        <Option
                            key={option}
                            onChange={() => {onSelectOption(option)}}
                            checked={selectedOption === option}>
                            {t(option)}
                        </Option>
                    )
                })}
            </ul>
        </div>
    );
};

export default OptionList;
