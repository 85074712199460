import React, {useCallback, useState} from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceGroupForm, {InvoiceGroupFormData} from './InvoiceGroupForm';
import {InvoiceGroup} from "../../../domain/InvoiceGroup";
import ConfirmDialog from "../../Common/Modal/ConfirmDialog";
import BackButton from '../../Common/BackButton';

interface Props{
    invoiceGroup: InvoiceGroup;
    onSave: (formData: InvoiceGroupFormData) => void;
    onCancel: () => void;
    onDelete: (invoiceGroupId: number) => void;
}

const EditInvoiceGroupForm = ({ invoiceGroup, onSave, onCancel, onDelete }: Props) => {
    const { t } = useTranslation();
    const [isDialogOpen, setIsDialogOpen ] = useState(false);

    const invoiceGroupFormData: InvoiceGroupFormData = {
        name: invoiceGroup.name,
        company_name: invoiceGroup.company_name,
        tax_identifier: invoiceGroup.tax_identifier,
        emails_admin: invoiceGroup.emails_admin
    };

    const handleSave = useCallback((formData: InvoiceGroupFormData) => {
        onSave(formData);
    },[onSave]);

    const onConfirmDelete = useCallback(async (confirm: boolean) => {
        const handleDelete = (invoiceGroupId: number) => {
            onDelete(invoiceGroupId);
        };

        setIsDialogOpen(false);

        if (confirm) handleDelete(invoiceGroup.id);
    }, [onDelete, invoiceGroup.id])

    return (
        <>
            <BackButton to="../.." />
            <div className='cc-card cc-card--invoice'>
                <InvoiceGroupForm
                    invoiceGroupId={invoiceGroup.id}
                    initialValues={invoiceGroupFormData}
                    onSave={handleSave}
                    btnSaveText={t('save')}
                    onCancel={onCancel}
                    onDelete={() => setIsDialogOpen(true)}
                />
            </div>
            <ConfirmDialog
                open={isDialogOpen}
                title={t('delete item')}
                message={t('delete item message')}
                onConfirm={onConfirmDelete}
            />
        </>
    );
};

export default EditInvoiceGroupForm;